import React, { useState, useEffect } from 'react';
import {
    deleteAutomation,
    getAutomation,
    saveAutomation,
    getAutomationDetails
} from "./db";

const DotPhraseModal = ({ open, onOpenChange, onClose }) => {
  const [dotPhrases, setDotPhrases] = useState([]);
  const [selectedDotPhrase, setSelectedDotPhrase] = useState({
    id: null,
    title: '',
    content: '',
    oralTrigger: '',
    autoUpdate: false
  });
  const [isLoading, setIsLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState('');

  useEffect(() => {
    if (open) {
      fetchAutomations();
    }
  }, [open]);

  const fetchAutomations = async () => {
    setIsLoading(true);
    try {
      const automations = await getAutomation();
      setDotPhrases(automations);
      if (automations.length > 0) {
        await fetchAutomationDetails(automations[0].id);
      } else {
        setSelectedDotPhrase({
          id: null,
          title: '',
          content: '',
          oralTrigger: '',
          autoUpdate: false
        });
      }
    } catch (error) {
      console.error("Failed to fetch automations:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAutomationDetails = async (automationId) => {
    setIsLoading(true);
    try {
      const details = await getAutomationDetails(automationId);
      setSelectedDotPhrase(details);
    } catch (error) {
      console.error("Failed to fetch automation details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const addNewDotPhrase = () => {
    const newDotPhrase = { 
      id: null,
      title: 'Subjective', 
      content: '',
      oralTrigger: '',
      autoUpdate: false
    };
    setSelectedDotPhrase(newDotPhrase);
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (!selectedDotPhrase.id) {
      setSelectedDotPhrase({
        id: null,
        title: '',
        content: '',
        oralTrigger: '',
        autoUpdate: false
      });
      return;
    }
    setLoadingAction('delete');
    try {
      await deleteAutomation(selectedDotPhrase.id);
      const updatedDotPhrases = dotPhrases.filter(dp => dp.id !== selectedDotPhrase.id);
      setDotPhrases(updatedDotPhrases);
      if (updatedDotPhrases.length > 0) {
        await fetchAutomationDetails(updatedDotPhrases[0].id);
      } else {
        setSelectedDotPhrase({
          id: null,
          title: '',
          content: '',
          oralTrigger: '',
          autoUpdate: false
        });
      }
    } catch (error) {
      console.error("Failed to delete automation:", error);
    } finally {
      setLoadingAction('');
    }
  };

  const updateDotPhrase = (field, value) => {
    setSelectedDotPhrase(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSave = async () => {
    setLoadingAction('save');
    try {
      const automationToSave = {
        ...selectedDotPhrase,
        id: selectedDotPhrase.id || null // Ensure id is null for new automations
      };
      const savedAutomation = await saveAutomation(automationToSave);
      if (selectedDotPhrase.id) {
        setDotPhrases(prevDotPhrases => 
          prevDotPhrases.map(dp => dp.id === savedAutomation.id ? savedAutomation : dp)
        );
      } else {
        setDotPhrases(prevDotPhrases => [...prevDotPhrases, savedAutomation]);
      }
      await fetchAutomationDetails(savedAutomation.id);
    } catch (error) {
      console.error("Failed to save automation:", error);
    } finally {
      setLoadingAction('');
    }
  };

  if (!open) return null;

  return (
    <div className="modal modal-open">
      <div className="modal-box ml-200 max-w-[calc(100vw-250px)] h-[calc(100vh-150px)] p-0 flex">
        {/* Sidebar */}
        <div className="w-1/3 border-r border-base-300">
          <div className="h-[560px] overflow-y-auto p-4">
            <button className="btn btn-accent w-full mb-4" onClick={addNewDotPhrase}>
              + New Automation
            </button>
            {isLoading ? (
              <div className="flex justify-center items-center h-full">
                <span className="loading loading-spinner loading-lg"></span>
              </div>
            ) : dotPhrases.length === 0 ? (
              <div className="text-center p-4">
                <p className="text-base-content/70 mb-2">No automations yet.</p>
                <p className="text-sm">Click "New Automation" to create your first one!</p>
              </div>
            ) : (
              dotPhrases.map((dp) => (
                <div
                  key={dp.id}
                  onClick={() => fetchAutomationDetails(dp.id)}
                  className={`p-2 m-2 cursor-pointer rounded-lg ${selectedDotPhrase.id === dp.id ? 'bg-base-200' : ''}`}
                >
                  <div className="text-md font-bold">{dp.title}</div>
                  <div className="text-sm">{dp.oralTrigger}</div>
                </div>
              ))
            )}
          </div>
        </div>
        
        {/* Main content */}
        <div className="w-2/3 p-6 h-[calc(100vh-150px)] overflow-y-auto">
          <div className="flex flex-col gap-4">
            <div>
              <label className="label">
                <span className="label-text font-bold">Section Name</span>
              </label>
              <input
                type="text"
                placeholder="Where does this automation belong?"
                className="input input-bordered w-full"
                value={selectedDotPhrase.title || ''}
                onChange={(e) => updateDotPhrase('title', e.target.value)}
              />
            </div>

            <div>
              <div className="flex justify-between items-center">
                <label className="label">
                  <span className="label-text font-bold">Key Phrase Trigger</span>
                </label>
              </div>
              <input
                type="text"
                placeholder="Type your oral trigger"
                className="input input-bordered w-full"
                value={selectedDotPhrase.oralTrigger || ''}
                onChange={(e) => updateDotPhrase('oralTrigger', e.target.value)}
              />
              <label className="label">
                <span className="label-text-alt">If you say exactly these words during your visit we will automatically suggest this automation. Try for example "normal physical exam".</span>
              </label>
            </div>

            <p className="text-sm text-base-content/70">
              Whenever this automation is suggested, we will automatically edit the automation content below based on the encounter discussion.
            </p>

            <div>
              <label className="label">
                <span className="label-text font-bold">Content</span>
              </label>
              <textarea
                className="textarea textarea-bordered w-full h-[calc(100vh-630px)]"
                placeholder="Write the text you want to insert in your note when using this automation"
                value={selectedDotPhrase.content || ''}
                onChange={(e) => updateDotPhrase('content', e.target.value)}
              />
            </div>

            <div className="flex justify-between items-center mt-4">
              <button 
                className="btn btn-error btn-outline" 
                onClick={handleDelete}
                disabled={loadingAction === 'delete' || !selectedDotPhrase.id}
              >
                {loadingAction === 'delete' ? <span className="loading loading-spinner"></span> : 'Delete'}
              </button>
              <button 
                className="btn btn-accent" 
                onClick={handleSave}
                disabled={loadingAction === 'save'}
              >
                {loadingAction === 'save' ? <span className="loading loading-spinner"></span> : 'Save'}
              </button>
            </div>
          </div>
        </div>
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2" onClick={onClose}>✕</button>
      </div>
    </div>
  );
};

export default DotPhraseModal;