import React, { useState, useRef, useEffect } from 'react';
import IntakeContent from './IntakeContent'; // Assume this component exists

const DOBEntry = () => {
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');
  const [isDateValid, setIsDateValid] = useState(false);
  const [showIntakeContent, setShowIntakeContent] = useState(false);

  const monthRef = useRef(null);
  const dayRef = useRef(null);
  const yearRef = useRef(null);

  const validateDate = () => {
    if (!month || !day || !year) {
      setIsDateValid(false);
      return;
    }

    const currentYear = new Date().getFullYear();
    const m = parseInt(month, 10);
    const d = parseInt(day, 10);
    const y = parseInt(year, 10);

    // Check year range (allow ages between 0 and 120 years)
    if (y < currentYear - 120 || y > currentYear) {
      setIsDateValid(false);
      return;
    }

    // Check month range
    if (m < 1 || m > 12) {
      setIsDateValid(false);
      return;
    }

    // Check day range
    const daysInMonth = new Date(y, m, 0).getDate();
    if (d < 1 || d > daysInMonth) {
      setIsDateValid(false);
      return;
    }

    setIsDateValid(true);
  };

  const handleMonthChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 2);
    setMonth(value);
    if (value.length === 1 && parseInt(value, 10) >= 2) {
      dayRef.current.focus();
    } else if (value.length === 2) {
      dayRef.current.focus();
    }
  };

  const handleDayChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 2);
    setDay(value);
    if (value.length === 2) {
      yearRef.current.focus();
    }
  };

  useEffect(() => {
    validateDate();
  }, [month, day, year]);

  useEffect(() => {
    // Focus on the month input when the component mounts
    monthRef.current.focus();
  }, []);

  const handleSubmit = () => {
    if (isDateValid) {
      console.log('Date of Birth submitted:', `${month}/${day}/${year}`);
      setShowIntakeContent(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && isDateValid) {
      handleSubmit();
    }
  };

  if (showIntakeContent) {
    return <IntakeContent dateOfBirth={`${month}/${day}/${year}`} />;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="p-6 bg-white rounded-lg shadow-md">
        <h1 className="text-2xl font-bold mb-4">Enter Your Date of Birth</h1>
        <div className="flex gap-2 mb-4">
          <input
            type="text"
            placeholder="MM"
            maxLength={2}
            value={month}
            onChange={handleMonthChange}
            onKeyPress={handleKeyPress}
            className="w-16 text-center p-2 border rounded"
            ref={monthRef}
          />
          <input
            type="text"
            placeholder="DD"
            maxLength={2}
            value={day}
            onChange={handleDayChange}
            onKeyPress={handleKeyPress}
            className="w-16 text-center p-2 border rounded"
            ref={dayRef}
          />
          <input
            type="text"
            placeholder="YYYY"
            maxLength={4}
            value={year}
            onChange={(e) => setYear(e.target.value.replace(/\D/g, '').slice(0, 4))}
            onKeyPress={handleKeyPress}
            className="w-20 text-center p-2 border rounded"
            ref={yearRef}
          />
        </div>
        <button
          onClick={handleSubmit}
          disabled={!isDateValid}
          className={`w-full p-2 rounded ${
            isDateValid
              ? 'bg-blue-500 text-white hover:bg-blue-600'
              : 'bg-gray-300 text-gray-500 cursor-not-allowed'
          }`}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default DOBEntry;