// PromptNotificationModal.jsx
import React, { useEffect } from 'react';
import {
  Analytics,
  StandardAnalyticsService,
} from "./analytics"; // Adjust this import path as necessary

const XIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
  </svg>
);

const PromptNotificationModal = ({ isOpen, onClose, items, onUse }) => {
  const analyticsService = StandardAnalyticsService.shared;

  useEffect(() => {
    if (isOpen) {
        analyticsService.trackScreen(Analytics.Category.TEMPLATE,Analytics.ScreenName.TEMPLATE_PROMPT_NOTIFICATION);  //AR this is a sub component
      }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md">
        <div className="flex justify-between items-start p-4 border-b">
          <div>
            <h2 className="text-xl font-bold">New Templates Available</h2>
            <p className="text-sm text-gray-600 mt-1">from Dr John</p>
          </div>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <XIcon />
          </button>
        </div>
        <div className="p-4 max-h-96 overflow-y-auto">
          {items.map((item, index) => (
            <div key={index} className="mb-4 p-3 border rounded">
              <h3 className="font-bold">{item.title}</h3>
              <p className="text-sm text-gray-600">{item.description}</p>
              <div className="flex justify-between items-center mt-2">
                <p className="text-xs text-gray-500">Created by: {item.createdBy}</p>
                <button 
                  onClick={() => onUse(item)}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-3 rounded text-sm"
                >
                  USE
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PromptNotificationModal;