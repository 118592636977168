import { useEffect } from 'react';
import db from './db';
import { setHubspotAlert } from './db'; // Adjust this import path as necessary

function removeElementsByIdPrefix(prefix) {
  const elements = document.querySelectorAll(`[id^="${prefix}"]`);
  elements.forEach(element => {
    element.parentNode.removeChild(element);
  });
}


function formv3(){
  // Create the new request 
  var xhr = new XMLHttpRequest();
  var url = 'https://api.hsforms.com/submissions/v3/integration/submit/8370107/1e5e8e1a-921d-4c73-94f5-7dc8d5c222c6'
  
  // Example request JSON:
  var data = {
    "submittedAt": "1517927174000",
    "fields": [
      {
    "objectTypeId": "0-1",
        "name": "email",
        "value": "example@example.com"
      },
      {
    "objectTypeId": "0-1",
        "name": "firstname",
        "value": "Jeff"
      }
    ],
    "context": {
      "hutk": ':hutk', // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
      "pageUri": "www.example.com/page",
      "pageName": "Example page"
    },
    "legalConsentOptions":{ // Include this object when GDPR options are enabled
      "consent":{
        "consentToProcess":true,
        "text":"I agree to allow Example Company to store and process my personal data.",
        "communications":[
          {
            "value":true,
            "subscriptionTypeId":999,
            "text":"I agree to receive marketing communications from Example Company."
          }
        ]
      }
    }
  }

  var final_data = JSON.stringify(data)

  xhr.open('POST', url);
  // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
  xhr.setRequestHeader('Content-Type', 'application/json');

  xhr.onreadystatechange = function() {
      if(xhr.readyState == 4 && xhr.status == 200) { 
          alert(xhr.responseText); // Returns a 200 response if the submission is successful.
      } else if (xhr.readyState == 4 && xhr.status == 400){ 
          alert(xhr.responseText); // Returns a 400 error the submission is rejected.          
      } else if (xhr.readyState == 4 && xhr.status == 403){ 
          alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.           
      } else if (xhr.readyState == 4 && xhr.status == 404){ 
          alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found     
      }
     }


  // Sends the request 
  
  xhr.send(final_data)
}


function ModalHubspot({ closeModal }) {
  useEffect(() => {
    console.log('ModalHubspot loaded');
    hbspt.forms.create({
      region: "na1",
      portalId: "8370107",
      formId: "1e5e8e1a-921d-4c73-94f5-7dc8d5c222c6",
      target: '#hbspt-form',
      onFormSubmit: ($form) => {
        console.log('form submitted');
        console.log($form);

        removeElementsByIdPrefix('hbspt');
        closeModal();
        setHubspotAlert(true);
      }
    });
  }, []);

  return (
    <div className="modal modal-open">
      <div className="modal-box">
        <h3 className="font-bold text-lg">Billing Contact Information</h3>
        <div id="hbspt-form"></div>
        <div className="modal-action">
          <button className="btn" onClick={closeModal}>Close</button>
        </div>
      </div>
    </div>
  );
}

export default ModalHubspot;
