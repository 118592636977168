import React, { useEffect } from 'react';
import LogoutPopup from './LogoutPopup';
import ProfileMenu from './ProfileMenu';
import { Card, CardContent, Typography, TextField, Button, Container, useTheme, useMediaQuery, CardHeader, IconButton, Menu, MenuItem } from '@mui/material';



const Header = ({ mode, profile, onClickItem }) => {

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (mode === 'left') {
        return (
            <header className="bg-color-08425E w-[200px] fixed left-0 top-0 flex justify-between items-center h-14 p-4">
                <a href="#" onClick={(e) => { e.preventDefault(); window.location.reload(); }}>
                    <img src="/inline-teal-trans.svg" alt="Logo" className="max-w-50 h-4" />
                </a>           
            </header>
        )
    }
    return (
        <header className={`bg-color-08425E flex ${isMobile ? "w-screen" : "w-inherit"} fixed justify-between items-center h-14 p-4 z-40`}>

            <div className="logout m-2 fixed right-0 z-50">
                {/* <LogoutPopup /> */}
                <ProfileMenu onItemClick={(item) => {
                    if (onClickItem) onClickItem(item);
                }} />
            </div>
        </header>
    );
};

export default Header;
