import React, { useEffect, useState, useRef } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {

    isHealthNoteUser, getCookie, getSharedPrompts, deletePrompt, saveGlobalPrompt,
    saveDefaultGlobalPrompt, promptTemplates, fetchPrompts, getPromptDetails, isAlphaFlag,
} from './db';

import {

    createTemplateFromPrompt,
    createPromptFromTemplate,
    mapCustomPromptObjToTemplates,

    convertHTMLTextToSimpleTextObject,
    convertSimpleTextToHTMLTemplateObject,
    convertStructuredFormatToHTMLTemplateObject,

    isHTMLorSimpleText,
    convertLegacySystemPromptToStructuredFormat,

    buildSystemInstructionFromSection,
    convertHTMLToStructuredFormat,


} from "./prompt.formatting"

import PromptTemplateModal from "./PromptTemplateModal";
import PromptShareModal from './PromptShareModal';
import Select from 'react-select';

import {
    Analytics,
    StandardAnalyticsService,
} from "./analytics"; // Adjust this import path as necessary

const formatOptionLabel = ({ label, subtext }) => (
    <div className="flex flex-col">
        <div className="font-semibold">{label}</div>
        <div className="text-sm text-gray-600">{subtext}</div>
    </div>
);

const createYourOwnSubTextTextEntry = "Create my Own Template";
const createYourOwnTextEntry = "New Template";


const DocumentTemplateBuilder = ({ isOpen, onClose, onSaveAndGenerate, onCreateTemplateWizard }) => {
    const analyticsService = StandardAnalyticsService.shared;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isHNUser = isHealthNoteUser();

    const [selectedTemplate, setSelectedTemplate] = useState('soapSection');
    const [defaultPromptObj, setDefaultPromptObj] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const promptTemplatesRef = useRef(promptTemplates);
    const promptTemplatesKeyRef = useRef('soapSection');

    const [, forceUpdate] = useState({});

    const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
    const [hasAdvanceModeChanges, setHasAdvanceModeChanges] = useState(false)
    const [advancedText, setAdvancedText] = useState('');

    let currentTemplate = promptTemplatesRef.current[selectedTemplate];
    const [isAddNewPromptModal, setIsAddNewPromptModal] = useState(false)
    const [isSharePromptModal, setIsSharePromptModal] = useState(false)

    const [addingSectionInstruction, setAddingSectionInstruction] = useState({});


    const [activeTemplateList, setActiveTemplateList] = useState([])
    const [myCurrentTemplateListValue, setMyCurrentTemplateListValue] = useState(null)
    const [groupedTemplateList, setGroupedTemplateList] = useState([])




    const handleSectionInstructionChange = (sectionId, instruction) => {
        promptTemplatesRef.current[selectedTemplate] = {
            ...promptTemplatesRef.current[selectedTemplate],
            sections: promptTemplatesRef.current[selectedTemplate].sections.map((section) =>
                section.id === sectionId ? { ...section, userInstruction: instruction } : section
            ),
        };
        updateChanges(selectedTemplate);
    };

    const onCreateNewTemplate = async () => {
        if (onCreateTemplateWizard) {
            onCreateTemplateWizard()
        }
    };
    const handleOnAddedNewTemplate = async (title) => {
        setIsAddNewPromptModal(false);
        let newTemplate = data.ds_id;
        promptTemplatesKeyRef.current = newTemplate
        mapCustomPromptObjToTemplates(data);
        setIsAdvancedOpen(true);
        setAdvancedText(promptTemplatesRef.current[newTemplate].prompt);
        setActiveTemplateList(prevList => [
            ...prevList,
            { value: data.ds_id, label: data.title }
        ]);
        setSelectedTemplate(newTemplate);
        setDefaultPromptObj(data);
        setMyCurrentTemplateListValue({ "value": data.ds_id, "label": data.title })
        updateChanges(newTemplate);
    };


    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = Array.from(currentTemplate.sections);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        promptTemplatesRef.current[selectedTemplate] = {
            ...promptTemplatesRef.current[selectedTemplate],
            sections: items,
        };
        updateChanges(selectedTemplate);
    };

    const handleAddTemplate = async () => {
        setIsAddNewPromptModal(true)
    }
    const updateChanges = async (templateKey) => {

        try {
            forceUpdate({});
        } catch (error) {
            console.error('Error updating changes:', error);
        } finally {
        }
    };

    const onDelete = async () => {
        setIsLoading(true);
        if (promptTemplatesRef.current[selectedTemplate].ds_id) {
            await deletePrompt(promptTemplatesRef.current[selectedTemplate].ds_id)
        }
        onClose()
        window.location.href = window.location.href
    }

    const onSave = async () => {
        setIsLoading(true);

        let htmlTemplateObject = {
            prefix: currentTemplate.prefix || "",
            content: currentTemplate.content || "",
            suffix: currentTemplate.suffix || ""
        }

        if ((currentTemplate.prefix || "").length == 0) {
            let legacyPrompt = createPromptFromTemplate(selectedTemplate)
            let legacyTextObject = convertHTMLTextToSimpleTextObject(legacyPrompt) // hidden sections will be removed
            promptTemplates[selectedTemplate].prefix = legacyTextObject.prefix
            promptTemplates[selectedTemplate].suffix = legacyTextObject.suffix
            htmlTemplateObject = convertStructuredFormatToHTMLTemplateObject(promptTemplates[selectedTemplate])
        }
        else {
            htmlTemplateObject = convertStructuredFormatToHTMLTemplateObject(currentTemplate)
        }

        if (isAdvancedOpen) {
            prompt = advancedText
        }
        analyticsService.trackEvent(Analytics.Category.TEMPLATE_WIZARD_JOSH, Analytics.Action.SAVED, "", promptTemplatesKeyRef.current, Analytics.ScreenName.TEMPLATE_WIZARD_JOSH);


        const savedPromptObject = await saveDefaultGlobalPrompt(promptTemplatesKeyRef.current, { ...defaultPromptObj, "prompt": `${htmlTemplateObject.prefix}${htmlTemplateObject.content}${htmlTemplateObject.suffix}`, "isAdvanced": isAdvancedOpen });

        setDefaultPromptObj(savedPromptObject);

        console.log('Changes updated successfully');

        if (onSaveAndGenerate) {
            onSaveAndGenerate(savedPromptObject);
        }
    }

    const onShare = async (promptObj) => {

        onClose()
    }

    const onSaveOnly = async () => {
        setIsLoading(true);

        let prompt = createPromptFromTemplate(promptTemplatesKeyRef.current);
        if (isAdvancedOpen) {
            prompt = advancedText
        }


        const savedPromptObject = await saveGlobalPrompt(promptTemplatesKeyRef.current, { ...defaultPromptObj, "prompt": prompt, "isAdvanced": isAdvancedOpen, "version": "0917" });

        setDefaultPromptObj(savedPromptObject);

        console.log('Changes updated successfully');

        onClose()
        window.location.href = window.location.href
    }



    const handleTemplateChange = async (e) => {
        setAddingSectionInstruction({});
        setIsLoading(true);
        const newTemplate = e.target.value;
        promptTemplatesKeyRef.current = newTemplate;
        setSelectedTemplate(newTemplate);

        let tmpPromptObject = undefined;

        if (promptTemplatesRef.current[newTemplate] && promptTemplatesRef.current[newTemplate].isAdvanced) {
            // setIsAdvancedOpen(true)
            setAdvancedText(promptTemplatesRef.current[newTemplate].prompt)
            tmpPromptObject = promptTemplatesRef.current[newTemplate]
        }
        else if (promptTemplatesRef.current[newTemplate] && promptTemplatesRef.current[newTemplate].ds_id) {
            // setIsAdvancedOpen(true)
            setAdvancedText(promptTemplatesRef.current[newTemplate].prompt)
            tmpPromptObject = promptTemplatesRef.current[newTemplate]
        }
        else {
            promptTemplatesRef.current[newTemplate].prompt = createPromptFromTemplate(newTemplate);
            tmpPromptObject = promptTemplatesRef.current[newTemplate]
        }

        if (promptTemplatesRef.current[newTemplate] && promptTemplatesRef.current[newTemplate].isAdvanced) {
            // setIsAdvancedOpen(promptTemplatesRef.current[newTemplate].isAdvanced)
        }
        else {

            setIsAdvancedOpen(false)
        }

        if (promptTemplatesRef.current[newTemplate] && promptTemplatesRef.current[newTemplate].ds_id) {
            let data = await getPromptDetails(promptTemplatesRef.current[newTemplate].ds_id)
            mapCustomPromptObjToTemplates(data);
            setAdvancedText(data.prompt)
            setDefaultPromptObj(data);
            tmpPromptObject = data

        }
        else if (promptTemplatesRef.current[newTemplate]) {
            setDefaultPromptObj(promptTemplatesRef.current[newTemplate]);
            setAdvancedText(promptTemplatesRef.current[newTemplate].prompt)
            tmpPromptObject = promptTemplatesRef.current[newTemplate]
        }
        else {
            setDefaultPromptObj({});
        }

        if (tmpPromptObject) {
            let structuredTemplate = {};
            let templateType = isHTMLorSimpleText(tmpPromptObject.prompt)

            if (templateType == 'simple') {
                let simpleTextObject = convertSimpleTextToHTMLTemplateObject(tmpPromptObject.prompt);
                structuredTemplate = convertHTMLToStructuredFormat(simpleTextObject, "", "", "");
            }
            else { //legacy system prompt
                let simpleTextObject = convertHTMLTextToSimpleTextObject(tmpPromptObject.prompt);
                setAdvancedText(`${simpleTextObject.prefix}${simpleTextObject.content}${simpleTextObject.suffix}`)
                let htmlObject = convertSimpleTextToHTMLTemplateObject(`${simpleTextObject.prefix}${simpleTextObject.content}${simpleTextObject.suffix}`)
                structuredTemplate = convertHTMLToStructuredFormat(htmlObject, "", "", "");
            }

            currentTemplate = promptTemplatesRef.current[newTemplate];
            currentTemplate["sections"] = structuredTemplate.sections
        }

        setIsLoading(false);

        updateChanges(newTemplate);




    };


    const handleSectionStyleChange = (id, style) => {
        promptTemplatesRef.current[selectedTemplate] = {
            ...promptTemplatesRef.current[selectedTemplate],
            sections: promptTemplatesRef.current[selectedTemplate].sections.map((section) =>
                section.id === id ? { ...section, sectionStyle: style } : section
            ),
        };
        promptTemplatesRef.current[selectedTemplate] = {
            ...promptTemplatesRef.current[selectedTemplate],
            sections: promptTemplatesRef.current[selectedTemplate].sections.map((section) =>
                section.id === id ? { ...section, systemInstruction: buildSystemInstructionFromSection(section) } : section
            ),
        };



        updateChanges(selectedTemplate)
    };

    const handleHideByDefaultChange = (id, hideByDefault) => {
        promptTemplatesRef.current[selectedTemplate] = {
            ...promptTemplatesRef.current[selectedTemplate],
            sections: promptTemplatesRef.current[selectedTemplate].sections.map((section) =>
                section.id === id ? { ...section, hideByDefault } : section
            ),
        };
        updateChanges(selectedTemplate)
    };

    const handleSectionDetailChange = (id, detailLevel) => {
        promptTemplatesRef.current[selectedTemplate] = {
            ...promptTemplatesRef.current[selectedTemplate],
            sections: promptTemplatesRef.current[selectedTemplate].sections.map((section) =>
                section.id === id ? { ...section, detailLevel } : section
            ),
        };
        updateChanges(selectedTemplate)
    };

    const handleCustomTitleChange = (id, customTitle) => {
        promptTemplatesRef.current[selectedTemplate] = {
            ...promptTemplatesRef.current[selectedTemplate],
            sections: promptTemplatesRef.current[selectedTemplate].sections.map((section) =>
                section.id === id ? { ...section, customTitle } : section
            ),
        };
    };

    const handleSplitByProblemChange = (id, splitByProblem) => {
        promptTemplatesRef.current[selectedTemplate] = {
            ...promptTemplatesRef.current[selectedTemplate],
            sections: promptTemplatesRef.current[selectedTemplate].sections.map((section) =>
                section.id === id ? { ...section, splitByProblem } : section
            ),
        };
        updateChanges(selectedTemplate)
    };

    const formatGroupLabel = (data) => (
        <div style={{ marginRight: '1em', color: 'black', fontSize: '20px', fontWeight: 'bold' }}>
            <span>{data.label}</span>
        </div>
    );

    const reload = async function () {
        setIsLoading(true);

        let sharedPrompts = []
        let myPrompts = []
        let systemPrompts = [{ "value": "soapSection", "label": "SOAP", "subtext": "Subjective, Objective, Assessment and Plan" }]

        let groupList = [
            // { "label": "System Templates", "options": systemPrompts },
            // { "label": "Community Templates", "options": sharedPrompts },

            { "label": "Templates", "options": systemPrompts },
            { "label": "My Templates", "options": myPrompts },
            { "label": "Create My Own", "options": [{ "value": "blank", "label": createYourOwnTextEntry, "subtext": createYourOwnSubTextTextEntry }] }

        ]

        //sharedPrompts
        let userInfo = getCookie("info");
        if (userInfo) {
            userInfo = JSON.parse(userInfo);
        }

        if (userInfo.specialty && userInfo.specialty.specialty) {
            let tmpShared = await getSharedPrompts(userInfo.specialty.specialty)
            if (tmpShared && tmpShared.length > 0) {
                for (let i = 0; i < tmpShared.length; i++) {
                    if (tmpShared[i].version && tmpShared[i].version === "0917") {
                        systemPrompts.push({ "value": tmpShared[i].title, "label": tmpShared[i].title, "prompt": tmpShared[i].prompt, "subtext": tmpShared[i].description })
                        mapCustomPromptObjToTemplates(tmpShared[i])
                    }
                }
            }
        }
        else {
            let tmpShared = await getSharedPrompts("general")
            if (tmpShared && tmpShared.length > 0) {
                for (let i = 0; i < tmpShared.length; i++) {
                    if (tmpShared[i].version && tmpShared[i].version === "0917") {
                        systemPrompts.push({ "value": tmpShared[i].title, "label": tmpShared[i].title, "prompt": tmpShared[i].prompt, "subtext": tmpShared[i].description })
                        mapCustomPromptObjToTemplates(tmpShared[i])
                    }
                }
            }
        }

        //systemPrompts
        systemPrompts.push(
            { "value": "soapAPSection", "label": "SOAP ('Assessment' and 'Plan' merged)", "subtext": "Subjective, Objective, Assessment & Plan" }
        )
        systemPrompts.push(
            { "value": "DAP", "label": "DAP", "subtext": "Data, Assessment and Plan" }
        )
        systemPrompts.push(
            { "value": "H&P", "label": "H&P", "subtext": "History of Present Illness, Past Medical History, Past Surgical History, Family History, Social History, Allergies, Medications, Labs, Review of Systems, Physical Exam, Assessment, Plan" }
        )
        systemPrompts.push(
            { "value": "multiSection", "label": "Multi Section", "subtext": "Chief Complaint, History of Present Illness, Past Medical History, Past Surgical History, Family History, Social History, Allergies, Medications, Problem List ..." }
        )
        //myPrompts
        let myDefaultPrompt = null;
        let tmpPrompts = await fetchPrompts()
        for (let i = 0; i < tmpPrompts.length; i++) {
            let title = tmpPrompts[i].title
            let version = tmpPrompts[i].version
            if (version && version === "0917") {

                if (title in promptTemplates) {
                    tmpPrompts[i].title = promptTemplates[title].name
                }

                if (tmpPrompts[i].type === "DEFAULT_NOTE" && !myDefaultPrompt) {
                    myDefaultPrompt = tmpPrompts[i];
                    setMyCurrentTemplateListValue({ "value": tmpPrompts[i].ds_id, "label": tmpPrompts[i].title })
                }
                else if (tmpPrompts[i].type === "DEFAULT_NOTE" || tmpPrompts[i].type === "NOTE_TEMPLATE") {

                    myPrompts.push({ "value": tmpPrompts[i].ds_id, "label": tmpPrompts[i].title, "subtext": tmpPrompts[i].description })

                }

                mapCustomPromptObjToTemplates(tmpPrompts[i])
            }
        }

        if (!myDefaultPrompt) {
            myDefaultPrompt = systemPrompts[0]
            setMyCurrentTemplateListValue(systemPrompts[0])
        }

        setGroupedTemplateList(groupList)



        let defaultPrompt = myDefaultPrompt;


        if (defaultPrompt && defaultPrompt["ds_id"]) {
            getPromptDetails(defaultPrompt["ds_id"]).then((data) => {
                mapCustomPromptObjToTemplates(data) //get details here
                setDefaultPromptObj(data);
                let tmpSelectedTemplateKey = ""
                if (data["title"] in promptTemplatesRef.current) {
                    tmpSelectedTemplateKey = data["title"]
                    let originalTemplate = JSON.parse(JSON.stringify(promptTemplatesRef.current[tmpSelectedTemplateKey]))
                    promptTemplatesRef.current[tmpSelectedTemplateKey] = createTemplateFromPrompt(originalTemplate, data);
                }
                else {
                    tmpSelectedTemplateKey = data["ds_id"]
                }
                promptTemplatesKeyRef.current = tmpSelectedTemplateKey;
                setSelectedTemplate(tmpSelectedTemplateKey)
                setAdvancedText(data.prompt)
                currentTemplate = promptTemplatesRef.current[tmpSelectedTemplateKey]

                let structuredTemplate = {};
                let templateType = isHTMLorSimpleText(data.prompt)

                if (templateType == 'simple') {
                    structuredTemplate = convertHTMLToStructuredFormat(convertSimpleTextToHTMLTemplateObject(data.prompt), "", "", "");
                }
                else { //legacy system prompt
                    let simpleTextObject = convertHTMLTextToSimpleTextObject(data.prompt);
                    setAdvancedText(`${simpleTextObject.prefix}${simpleTextObject.content}${simpleTextObject.suffix}`)
                    let htmlObject = convertSimpleTextToHTMLTemplateObject(`${simpleTextObject.prefix}${simpleTextObject.content}${simpleTextObject.suffix}`)
                    structuredTemplate = convertHTMLToStructuredFormat(htmlObject, "", "", "");

                }


                console.log(structuredTemplate)
                currentTemplate = promptTemplatesRef.current[tmpSelectedTemplateKey];
                currentTemplate["sections"] = structuredTemplate.sections

                forceUpdate({});
                setIsLoading(false);
            }).catch((error) => {
                console.error('Error fetching prompt details:', error);
                setIsLoading(false);
            });
        }
        else {
            setIsLoading(false);
        }

    }

    const toggleAddingSection = (sectionId) => {
        setAddingSectionInstruction(prev => ({
            ...prev,
            [sectionId]: !prev[sectionId]
        }));
        if (!addingSectionInstruction[sectionId]) {

        }
    };

    const onDragUpdate = (update) => {
        if (!update.destination) {
            return;
        }
        const draggableId = update.draggableId;
        const destinationIndex = update.destination.index;
        const draggable = document.querySelector(`[data-rbd-draggable-id='${draggableId}']`);
        if (draggable) {
            const containerRect = draggable.parentElement.getBoundingClientRect();
            const draggableRect = draggable.getBoundingClientRect();
            if (draggableRect.bottom > containerRect.bottom) {
                draggable.parentElement.scrollTop += draggableRect.bottom - containerRect.bottom;
            } else if (draggableRect.top < containerRect.top) {
                draggable.parentElement.scrollTop -= containerRect.top - draggableRect.top;
            }
        }
    };

    const deleteSection = (sectionId) => {
        promptTemplatesRef.current[selectedTemplate] = {
            ...promptTemplatesRef.current[selectedTemplate],
            sections: promptTemplatesRef.current[selectedTemplate].sections.filter(
                (section) => section.id !== sectionId
            ),
        };
        updateChanges(selectedTemplate);
    };
    const [editingNewSection, setEditingNewSection] = useState(null);

    const addNewSection = () => {
        const newSection = {
            id: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
            title: "",
            hideByDefault: false,
            sectionStyle: 'Paragraph',
            userInstruction: '',
        };

        promptTemplatesRef.current[selectedTemplate].sections.unshift(newSection);
        setEditingNewSection(newSection.id);
        forceUpdate({});
    };

    const handleNewSectionTitleChange = (section, newTitle) => {
        section.title = newTitle;

        forceUpdate({});
    };

    const handleNewSectionTitleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setEditingNewSection(null);
        }
    };

    const moveSection = (index, direction) => {
        const newSections = [...currentTemplate.sections];
        const [movedSection] = newSections.splice(index, 1);
        newSections.splice(index + direction, 0, movedSection);

        promptTemplatesRef.current[selectedTemplate] = {
            ...promptTemplatesRef.current[selectedTemplate],
            sections: newSections,
        };

        updateChanges(selectedTemplate);
    };

    const moveUp = (index) => {
        if (index > 0) {
            moveSection(index, -1);
        }
    };

    const moveDown = (index) => {
        if (index < currentTemplate.sections.length - 1) {
            moveSection(index, 1);
        }
    };


    setEditingNewSection
    useEffect(() => {
        if (isOpen) {
            analyticsService.trackScreen(Analytics.Category.TEMPLATE_WIZARD_JOSH, Analytics.ScreenName.TEMPLATE_WIZARD_JOSH);
        }
        reload();
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className="modal modal-open">
            <div className={`modal-box md:ml-[190px] ${isMobile ? 'max-w-xl' : 'md:max-w-[calc(100vw-300px)]'}${!isAdvancedOpen ? ' xl:max-w-[900px]' : ""}`}>
                <button onClick={onClose} className="btn btn-sm btn-circle absolute right-2 top-2">✕</button>
                <h3 className="font-bold text-lg mb-4">Pick a starter template below:</h3>
                <div className={`${isMobile ? 'max-w-full' : 'max-w-full'}`}>

                    <div className="flex flex-col ">
                        {isLoading && (
                            <center><br /><br /><div className='hn-ext-transcript-block-summary-progress'><div></div><div></div><div></div></div></center>
                        )}



                        {!isLoading && (
                            <div className={`form-control w-full ${isMobile ? 'md:max-w-xl md:pr-4' : 'md:max-w-[calc(100vw-300px)]'}${!isAdvancedOpen ? ' xl:max-w-[900px]' : ""} `}>
                                <div className="flex items-center space-x-2">
                                    <Select
                                        className="w-full max-w-full"
                                        placeholder="Select a Template"
                                        value={myCurrentTemplateListValue}
                                        noOptionsMessage={(e) => `No value found, please select Other`}
                                        formatOptionLabel={formatOptionLabel}
                                        onChange={(option) => {
                                            setMyCurrentTemplateListValue(option)

                                            if (option.value === "blank") {
                                                onCreateNewTemplate()
                                            }
                                            else {
                                                handleTemplateChange({ target: { value: option.value } })

                                            }
                                            analyticsService.trackEvent(Analytics.Category.TEMPLATE_WIZARD_JOSH, Analytics.Action.CHANGED, "", option.value, Analytics.ScreenName.TEMPLATE_WIZARD_JOSH);

                                        }}
                                        formatGroupLabel={formatGroupLabel}
                                        maxMenuHeight={'450px'}
                                        options={groupedTemplateList} // Use react-select for better UI
                                    >

                                    </Select>
                                </div>

                                <div className="mt-1">
                                    <div className="flex justify-between items-center ">
                                        {!isAdvancedOpen && (
                                            <div className="flex items-center align-middle">
                                                <button className="btn btn-accent btn-sm" onClick={addNewSection}>Add Section</button>

                                            </div>
                                        )}
                                        {isAdvancedOpen && (
                                            <div className="mt-2 mb-2 text-sm">
                                                <p className=""><b>Template Instructions:</b></p>
                                                <p className="">Add :: to the end of a title to create a section.<i>e.g. Subjective::</i> </p>
                                            </div>
                                        )}

                                        {isAlphaFlag() && (
                                            <label className="cursor-pointer label">
                                                <span className="label-text mr-2">Advance Text Mode</span>
                                                <input
                                                    type="checkbox"
                                                    className="toggle btn-template-override toggle-primary"
                                                    checked={isAdvancedOpen}
                                                    onChange={() => {
                                                        let templateType = isHTMLorSimpleText(advancedText)
                                                        let structuredTemplate = {};
                                                        setAddingSectionInstruction({});
                                                        if (!isAdvancedOpen) { //structure to text
                                                            let tempTextObject = {
                                                                prefix: currentTemplate.prefix || "",
                                                                content: currentTemplate.content || "",
                                                                suffix: currentTemplate.suffix || ""
                                                            }

                                                            if ((currentTemplate.prefix || "").length == 0) {
                                                                let legacyPrompt = createPromptFromTemplate(selectedTemplate)
                                                                let legacyTextObject = convertHTMLTextToSimpleTextObject(legacyPrompt) // hidden sections will be removed
                                                                promptTemplates[selectedTemplate].prefix = legacyTextObject.prefix
                                                                promptTemplates[selectedTemplate].suffix = legacyTextObject.suffix
                                                                let htmlTemplateObject = convertStructuredFormatToHTMLTemplateObject(promptTemplates[selectedTemplate])
                                                                tempTextObject = convertHTMLTextToSimpleTextObject(`${htmlTemplateObject.prefix}${htmlTemplateObject.content}${htmlTemplateObject.suffix}`)

                                                            }
                                                            else {
                                                                let htmlTemplateObject = convertStructuredFormatToHTMLTemplateObject(currentTemplate)
                                                                tempTextObject = convertHTMLTextToSimpleTextObject(`${htmlTemplateObject.prefix}${htmlTemplateObject.content}${htmlTemplateObject.suffix}`)
                                                            }

                                                            setAdvancedText(`${tempTextObject.prefix}${tempTextObject.content}${tempTextObject.suffix}`)

                                                        }
                                                        else {// text to structure
                                                            if (templateType == 'simple') {
                                                                structuredTemplate = convertHTMLToStructuredFormat(convertSimpleTextToHTMLTemplateObject(advancedText), "", "", "");
                                                            }
                                                            else {
                                                                structuredTemplate = convertLegacySystemPromptToStructuredFormat(advancedText, "", "");
                                                            }
                                                            currentTemplate.prefix = structuredTemplate.prefix
                                                            currentTemplate.suffix = structuredTemplate.suffix
                                                            currentTemplate["sections"] = structuredTemplate.sections
                                                        }

                                                        setIsAdvancedOpen(!isAdvancedOpen)


                                                    }
                                                    }
                                                />
                                            </label>
                                        )}
                                    </div>

                                    <div className=' overflow-x-visible max-h-[calc(100vh-360px)] overflow-y-auto'>

                                        {isAdvancedOpen && (
                                            <div className="flex flex-col gap-4">

                                                <textarea
                                                    className="textarea textarea-bordered w-full h-[calc(100vh-380px)] mt-1"
                                                    placeholder="Enter template instruction here."
                                                    value={advancedText}
                                                    onChange={(e) => setAdvancedText(e.target.value)}
                                                    rows={5}
                                                />

                                            </div>
                                        )}



                                        {!isAdvancedOpen && Object.keys(currentTemplate.sections).length == 0 && (
                                            <div className="block relative mt-2 p-4 border rounded-lg border-transparent bg-gray-100">
                                                Preview not available
                                            </div>
                                        )}

                                        {!isAdvancedOpen && (
                                            <div className="overflow-visible min-h-96">
                                                {currentTemplate.sections.map((section, index) => (
                                                    <div key={section.id}>
                                                        <div
                                                            className={`block relative mt-2 mb-2 p-2 border rounded-lg border-transparent ${section.hideByDefault ? "opacity-80" : "bg-gray-100"
                                                                }`}
                                                        >
                                                            <button
                                                                className="btn btn-down btn-circle btn-xs absolute right-10 top-2"
                                                                onClick={() => moveDown(index)}
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth={1.5}
                                                                    stroke="currentColor"
                                                                    className="size-6"
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                                                    />
                                                                </svg>
                                                            </button>
                                                            <button
                                                                className="btn btn-up btn-circle btn-xs absolute right-[70px] top-2"
                                                                onClick={() => moveUp(index)}
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth={1.5}
                                                                    stroke="currentColor"
                                                                    className="size-6"
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="m15 11.25-3-3m0 0-3 3m3-3v7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                                                    />
                                                                </svg>
                                                            </button>
                                                            <button
                                                                className="btn block btn-xs btn-outline btn-circle border-transparent ml-2 absolute right-2"
                                                                onClick={() => deleteSection(section.id)}
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth={1.5}
                                                                    stroke="currentColor"
                                                                    className="size-5"
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                                                    />
                                                                </svg>
                                                            </button>

                                                            <div>
                                                                {editingNewSection === section.id ? (
                                                                    <input
                                                                        type="text"
                                                                        className="font-semibold w-[90%] p-1"
                                                                        value={section.title}
                                                                        placeholder="New Section"
                                                                        onChange={(e) =>
                                                                            handleNewSectionTitleChange(section, e.target.value)
                                                                        }
                                                                        onBlur={() => setEditingNewSection(null)}
                                                                        onKeyDown={handleNewSectionTitleKeyDown}
                                                                        autoFocus
                                                                    />
                                                                ) : (
                                                                    <h3 className="font-semibold flex">
                                                                        <span onClick={() => setEditingNewSection(section.id)}>
                                                                            {section.title}
                                                                        </span>
                                                                    </h3>
                                                                )}
                                                            </div>

                                                            {!addingSectionInstruction[section.id] && (
                                                                <div className="text-sm">{section.userInstruction}</div>
                                                            )}

                                                            {!section.hideByDefault && section.splitByProblem !== undefined && (
                                                                <div className="form-control mt-1">
                                                                    <label className="cursor-pointer label">
                                                                        <span className="label-text">Split by problem</span>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="toggle toggle-primary"
                                                                            checked={section.splitByProblem}
                                                                            onChange={(e) =>
                                                                                handleSplitByProblemChange(section.id, e.target.checked)
                                                                            }
                                                                        />
                                                                    </label>
                                                                </div>
                                                            )}

                                                            {!section.hideByDefault && (
                                                                <label className="flex justify-between">
                                                                    {!addingSectionInstruction[section.id] && (
                                                                        <div className="flex justify-end space-x-1 mt-2">
                                                                            <button
                                                                                className="btn btn-xs btn-outline"
                                                                                onClick={(e) => toggleAddingSection(section.id, e)}
                                                                            >
                                                                                {addingSectionInstruction[section.id] ? "Save" : "+ Customize"}
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </label>
                                                            )}

                                                            {!section.hideByDefault && (
                                                                <div key={section.id} className="form-control mt-2">
                                                                    <label className="flex justify-between items-center w-full">
                                                                        <div className="flex justify-end space-x-1"></div>
                                                                    </label>
                                                                    {addingSectionInstruction[section.id] && (
                                                                        <div className="mt-1">
                                                                            <div className="flex items-center mt-1">
                                                                                <button
                                                                                    className={`btn btn-xs ${section.sectionStyle === "Bullet points"
                                                                                        ? "btn-accent"
                                                                                        : "btn-outline"
                                                                                        }`}
                                                                                    onClick={() =>
                                                                                        handleSectionStyleChange(section.id, "Bullet points")
                                                                                    }
                                                                                >
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        fill="none"
                                                                                        viewBox="0 0 24 24"
                                                                                        strokeWidth={1.5}
                                                                                        stroke="currentColor"
                                                                                        className="size-6"
                                                                                    >
                                                                                        <path
                                                                                            strokeLinecap="round"
                                                                                            strokeLinejoin="round"
                                                                                            d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                                                                                        />
                                                                                    </svg>
                                                                                    Bullet Style
                                                                                </button>
                                                                                <button
                                                                                    className={`btn btn-xs ml-2 ${section.sectionStyle === "Paragraph"
                                                                                        ? "btn-accent"
                                                                                        : "btn-outline"
                                                                                        }`}
                                                                                    onClick={() =>
                                                                                        handleSectionStyleChange(section.id, "Paragraph")
                                                                                    }
                                                                                >
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        fill="none"
                                                                                        viewBox="0 0 24 24"
                                                                                        strokeWidth={1.5}
                                                                                        stroke="currentColor"
                                                                                        className="size-6"
                                                                                    >
                                                                                        <path
                                                                                            strokeLinecap="round"
                                                                                            strokeLinejoin="round"
                                                                                            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                                                                                        />
                                                                                    </svg>
                                                                                    Paragraph Style
                                                                                </button>

                                                                                {section.detailLevel && (
                                                                                    <>
                                                                                        <button
                                                                                            className={`btn btn-xs ml-2 ${section.detailLevel === "High"
                                                                                                ? "btn-accent"
                                                                                                : "btn-outline"
                                                                                                }`}
                                                                                            onClick={() =>
                                                                                                handleSectionDetailChange(section.id, "High")
                                                                                            }
                                                                                        >
                                                                                            High Detail
                                                                                        </button>
                                                                                        <button
                                                                                            className={`btn btn-xs ml-2 ${section.detailLevel === "Normal"
                                                                                                ? "btn-accent"
                                                                                                : "btn-outline"
                                                                                                }`}
                                                                                            onClick={() =>
                                                                                                handleSectionDetailChange(section.id, "Normal")
                                                                                            }
                                                                                        >
                                                                                            Normal Detail
                                                                                        </button>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                            <div className="mt-4">Custom Instructions for AI:</div>
                                                                            <textarea
                                                                                className="textarea textarea-bordered w-full mt-2 min-h-[150px]"
                                                                                value={section.userInstruction || ""}
                                                                                placeholder="e.g., remove parts about..., include discussion about..."
                                                                                onChange={(e) =>
                                                                                    handleSectionInstructionChange(
                                                                                        section.id,
                                                                                        e.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                            <button
                                                                                className="btn btn-xs btn-accent mb-4"
                                                                                onClick={(e) => toggleAddingSection(section.id, e)}
                                                                            >
                                                                                {addingSectionInstruction[section.id] ? "Save" : "+ Customize"}
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-xs ml-2 mb-4"
                                                                                onClick={(e) => toggleAddingSection(section.id, e)}
                                                                            >
                                                                                {addingSectionInstruction[section.id] ? "Cancel" : "+ Customize"}
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>

                </div>

                {!isLoading && (
                    <div className="modal-action">
                        {promptTemplatesRef.current[selectedTemplate].ds_id && isAlphaFlag() && (<button className="btn btn-template-delete btn-error" onClick={onDelete}>                                                                <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                            />
                        </svg></button>)}

                        <button className="btn btn-template-make-default btn-accent" onClick={onSave}>Use Template </button>
                        {isAdvancedOpen && isAlphaFlag() && (<button className="btn btn-template-onsave" onClick={onSaveOnly}>Update Template</button>)}

                        {isAdvancedOpen && isHNUser && (<button className="btn btn-template-share" onClick={() => { setIsSharePromptModal(true) }}>Share</button>)}
                        <button className="btn btn-template-cancel" onClick={onClose}>Cancel</button>
                    </div>
                )}
            </div>
            <PromptTemplateModal onSave={handleOnAddedNewTemplate} onClose={() => { setIsAddNewPromptModal(false) }} isOpen={isAddNewPromptModal} mode="simple" />
            <PromptShareModal key={defaultPromptObj['ds_id'] || ''} promptObj={defaultPromptObj} onShare={onShare} onClose={() => { setIsSharePromptModal(false) }} isOpen={isSharePromptModal} />
        </div>
    );
};


export default DocumentTemplateBuilder;