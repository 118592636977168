import React, { useEffect, useState } from 'react';
import { savePrompt, getUserProfile } from './db.js';
import {
  Analytics,
  StandardAnalyticsService,
} from "./analytics"; // Adjust this import path as necessary

const PromptTemplateModal = ({ isOpen, onClose, onSave, mode }) => {
  const analyticsService = StandardAnalyticsService.shared;

  const [title, setTitle] = useState('');
//   const [description, setDescription] = useState('');
  const [templateText, setTemplateText] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const [specialty, setSpecialty] = useState(false)

  const validateForm = () => {
    const newErrors = {};
    if (!title.trim()) newErrors.title = 'Title is required';
    // if (!description.trim()) newErrors.description = 'Description is required';
    if (!templateText.trim()) newErrors.templateText = 'Template Text is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if(mode && !title){
        setErrors({title:"Title is required"})
        return
    }
    if(mode && mode == "simple"){

      return onSave({title:title})
    }

    if (!validateForm()) return;

    setIsSaving(true);
    try {
        let objectToSave = { "prompt": templateText,"isAdvanced":true,"type":"NOTE_TEMPLATE",  "title":specialty ? `[${specialty}] ${title}` :`${title}`,"description":title,"createdBy":specialty ? specialty : "General"}

      let results = await savePrompt(objectToSave);
      onSave(results);
      onClose();
    } catch (error) {
      console.error('Error saving prompt:', error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const init = async () =>{
        if (isOpen) {
          analyticsService.trackScreen(Analytics.Category.TEMPLATE,Analytics.ScreenName.TEMPLATE_PROMPT); 
        }

        setTitle("")
        setTemplateText("")
        setSpecialty(false)
        let myProfile = await getUserProfile()
        if(myProfile && myProfile.specialty && myProfile.specialty.specialty && myProfile.specialty.specialty.indexOf('Other') == -1){
            setSpecialty(myProfile.specialty.specialty)
        }

    }

    init();



  },[isOpen])

  if (!isOpen) return null;

  return (
    <div className="modal modal-open">
      <div className="modal-box">
        <h3 className="font-bold text-lg mb-4">New Template {specialty ? '- '+specialty :""}</h3>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">Title *</span>
          </label>
          <input
            type="text"
            placeholder="Enter title"
            className={`input input-bordered w-full ${errors.title ? 'input-error' : ''}`}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          {errors.title && <span className="text-error text-sm mt-1">{errors.title}</span>}
        </div>
        {/* <div className="form-control w-full mt-4">
          <label className="label">
            <span className="label-text">Description *</span>
          </label>
          <textarea
            placeholder="Enter description"
            className={`textarea textarea-bordered w-full ${errors.description ? 'textarea-error' : ''}`}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
          {errors.description && <span className="text-error text-sm mt-1">{errors.description}</span>}
        </div> */}
        {!mode &&(
        <div className="form-control w-full mt-4">
        <label className="label">
          <span className="label-text">Template Instruction *</span>
        </label>
        <textarea
          placeholder="Enter template Instruction"
          className={`textarea textarea-bordered w-full ${errors.templateText ? 'textarea-error' : ''}`}
          value={templateText}
          onChange={(e) => setTemplateText(e.target.value)}
        ></textarea>
        {errors.templateText && <span className="text-error text-sm mt-1">{errors.templateText}</span>}
      </div>
        )}

        <div className="modal-action">
          <button 
            className={`btn btn-accent ${isSaving ? 'loading' : ''}`} 
            onClick={handleSave}
            disabled={isSaving}
          >
            {isSaving ? 'Saving...' : 'Save'}
          </button>
          <button className="btn" onClick={onClose} disabled={isSaving}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default PromptTemplateModal;