import React, { useState, useEffect, useRef } from 'react';

import { savePrompt, runClinicalLLM, htmlToText, getPatientNoteSessionPrompt, getLLMPromptWithContext, getPromptDetails, isAlphaFlag } from './db'; // Ensure this import points to your db handling file where updatePatient is defined
import {
    Analytics,
    StandardAnalyticsService,
  } from "./analytics"; // Adjust this import path as necessary

const actions = {
    APPEND_NOTES: 'Append to all my notes',
    DEFAULT_NOTE: 'Use as my default note',
    ADD_TAB: 'Add as another tab',
    PREVISIT_SUMMARY: 'Use as my pre-visit summary',
    ADD_BUTTON: 'Add as a button below my note',
    SAVE_LATER: 'Save for later',
};

let summary_prompts_custom_results = {};

const PromptManagement = ({ patient, patientSessionId, activeNote, prompts, transcriptText, handlePromptChange }) => {
    const analyticsService = StandardAnalyticsService.shared;

    const scrollCustomNoteRef = useRef(null);
    const inputRef = useRef(null);
    const [customNote, setCustomNote] = useState('');
    const [isCustomNoteGenerating, setIsCustomNoteGenerating] = useState(false);
    const [shouldGenerate, setShouldGenerate] = useState(false);

    const [customPrompt, setCustomPrompt] = useState('');
    const [customPromptObj, setCustomPromptObj] = useState(undefined);

    const [promptList, setPromptList] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingPromptDetails, setIsLoadingPromptDetails] = useState(false);


    

    const handleCustomContentChange = (e) => {
        setCustomNote(e.target.innerHTML);
    }
    const handleCustomContentBlur = () => {
    }

    const addNewPrompt = () => {
        setCustomPrompt('');
        setCustomPromptObj(undefined);
        setCustomNote('');
        setDefaultAction('Select a workflow');
        inputRef.current.focus();
    }
    const onClickGenerateCustomNote = () => {

        if (customPrompt.length > 10) {

            setIsCustomNoteGenerating(true);

            analyticsService.trackEvent(
                Analytics.Category.PROMPT,
                Analytics.Action.EXECUTED,
                "",
                "",
                Analytics.ScreenName.CUSTOM_PROMPT);

            let objectToSave = { "prompt": customPrompt, "note_session_id": (patientSessionId || patient.ds_id || patient.id) }
            if (customPromptObj) {
                objectToSave = customPromptObj;
                if (objectToSave["note_session_id"] == undefined || objectToSave["note_session_id"] == "") {
                    objectToSave["note_session_id"] = (patientSessionId || patient.ds_id || patient.id);
                }

                objectToSave["prompt"] = customPrompt;
            }

            console.log(objectToSave)

            let isNewEntry = false;
            if (objectToSave.ds_id == undefined) {
                isNewEntry = true;
            }
            setCustomNote("")

            savePrompt(objectToSave).then((data) => {
                setCustomPromptObj(data);
                let tmpPromptObj = data;

                let llmPrompt = getLLMPromptWithContext(customPrompt, patient, activeNote, transcriptText);


                runClinicalLLM(llmPrompt, (text) => {
                    setCustomNote(text)

                }, (text) => {
                    summary_prompts_custom_results["custom_01"] = text;
                    if (text.length == 0) {
                        text = "Unable to generate data."
                    }
                    setCustomNote(text)
                    setIsCustomNoteGenerating(false);

                    setCustomNote(text)
                    try {
                        tmpPromptObj["output"] = text;

                    }
                    catch (e) {
                        console.log(e)
                    }
                    setCustomPromptObj(tmpPromptObj);

                    savePrompt(tmpPromptObj).then((output_data) => {
                        setCustomPromptObj(output_data);
                        // if(isNewEntry){
                        //     handlePromptChange(output_data);
                        // }
                        handlePromptChange(output_data);

                        getPatientNoteSessionPrompt((patientSessionId || patient.ds_id || patient.id)).then((data) => {

                            if (data && data.length > 0) {
                                setPromptList(data);

                            }

                        })

                    })


                });

            });


        }


    }

    const handlePromptSelection = async (promptObj) => {

        setIsCustomNoteGenerating(true);
        setIsLoadingPromptDetails(true);
        let hasOutput = false;
        let foundPromptOutput = undefined

        analyticsService.trackEvent(
            Analytics.Category.PROMPT,
            Analytics.Action.SELECTED,
            "",
            "",
            Analytics.ScreenName.CUSTOM_PROMPT);


        if (promptList != undefined) {

            promptList.forEach((element) => {
                if (element.ds_id == promptObj.ds_id) {

                    foundPromptOutput = element;

                    if (element['type']) {
                        setDefaultAction(actions[element['type']]);
                    }
                    else {
                        setDefaultAction('Select a workflow');
                    }
                    hasOutput = true;
                }
            })
        }


        setDefaultAction('Select a workflow');
        setCustomNote('');
        await setCustomPrompt(promptObj.prompt)
        await setCustomPromptObj(promptObj)

        // update prompt object with long prompt details
        let selectedPromptDetail = await getPromptDetails(promptObj.ds_id)
        
        if(selectedPromptDetail){
            if(foundPromptOutput){
                selectedPromptDetail.output = foundPromptOutput.output
                setCustomNote(foundPromptOutput.output);
            }

            setCustomPromptObj(selectedPromptDetail);
            setCustomPrompt(selectedPromptDetail.prompt);

        }

        setIsLoadingPromptDetails(false);

        if(selectedPromptDetail.output){
            setIsCustomNoteGenerating(false);

            return;
        }

        setShouldGenerate(true)

    }

    const [defaultAction, setDefaultAction] = useState('Select a workflow');
    const [isOpen, setIsOpen] = useState(false);


    const handleSelection = (key) => {
        setDefaultAction(actions[key]);
        document.activeElement.blur(); // Close the dropdown by blurring the focused element
        console.log(`Selected action: ${actions[key]}`);

        let tmpPromptObj = customPromptObj;
        if (tmpPromptObj != undefined) {
            tmpPromptObj["type"] = key;
            setCustomPromptObj(tmpPromptObj);
            savePrompt(tmpPromptObj).then((output_data) => {
                setPromptList(output_data['data']);
                handlePromptChange(output_data);
            })
        }
    };

    useEffect(() => {
        if (shouldGenerate) {
            onClickGenerateCustomNote();
            setShouldGenerate(false);
        }
    }, [customPrompt, customPromptObj, shouldGenerate]);

    useEffect(() => {
        //analyticsService.trackScreen(Analytics.Category.Encounter,Analytics.ScreenName.CUSTOM_PROMPT);  //AR this is a sub component
        
        getPatientNoteSessionPrompt((patientSessionId || patient.ds_id || patient.id)).then((data) => {

            if (data && data.length > 0) {
                setPromptList(data);
            }
            setIsLoading(false);
        })
    }, [])

    if (isLoading) {
        return <><br /><br /><br /><center>Loading</center><br /><br /><div className='hn-ext-transcript-block-summary-progress'><div></div><div></div><div></div></div></>; // or a loading spinner, or some placeholder content
    }


    return (
        <div className="flex h-[calc(100vh-110px)]">
            {/* Left section */}
            <div className="w-1/3 bg-gray-100">
                {/* <h3>
                    {patient.name}
                </h3> */}
                <h3 className="text-xl w-fill font-bold">
                    <button className="btn ml-2 btn-add-custom-prompt" onClick={addNewPrompt}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                        </svg>
                        Add an instruction
                    </button>
                </h3>
                <h3 className='m-3'>My instructions:</h3>
                <ul>
                    {prompts.map((prompt) => (
                        <li
                            key={prompt.ds_id}
                            className={`btn-use-custom-prompts cursor-pointer m-2 p-2 rounded shadow ${(prompt.type == "DEFAULT_NOTE"  ? "hidden":'' )} ${(customPromptObj || { ds_id: -1 }).ds_id === prompt.ds_id ? 'bg-blue-200' : 'bg-white'
                                }`}
                            onClick={async () => {
                                handlePromptSelection(prompt)
                            }}
                        >
                            <div>{prompt.title}</div>
                            <div>{prompt.prompt}</div>
                            {actions[prompt.type] ? (<span className='text-md font-light bg-red-600 text-white p-1 rounded-lg'>{actions[prompt.type]}</span>) : ""}

                        </li>
                    ))}
                </ul>
            </div>

            {/* Right section */}
            {isLoadingPromptDetails &&( <div className='hn-ext-transcript-block-summary-progress'><div></div><div></div><div></div></div>)}
            {!isLoadingPromptDetails && (
                <div key={prompt.ds_id} className="w-2/3 bg-gray-50 p-4 flex flex-col">
                <textarea
                    ref={inputRef}
                    value={customPrompt}
                    onChange={e => setCustomPrompt(e.target.value)}
                    className="input input-bordered mb-4 p-2 txt-input-custom-prompt"
                    placeholder="Enter your note instructions here"
                ></textarea>

                <div className="flex items-center">
                    <button onClick={async (e) => {

                        onClickGenerateCustomNote(e)

                    }} className="btn btn-accent mb-4 btn-generate-custom-note">
                        {customPromptObj ? 'Update instruction' : 'Generate'}
                    </button>
                    {/* <div className="dropdown mb-4 ml-2 btn-select-workflow">
                        <label tabIndex="0" className="btn m-1">{defaultAction}</label>
                        <ul tabIndex="0" className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52">
                            <li><span onClick={() => handleSelection('APPEND_NOTES')}>{actions.APPEND_NOTES}</span></li>
                            <li><span onClick={() => handleSelection('ADD_TAB')}>{actions.ADD_TAB}</span></li>
                            <li><span onClick={() => handleSelection('PREVISIT_SUMMARY')}>{actions.PREVISIT_SUMMARY}</span></li>
                            <li><span onClick={() => handleSelection('ADD_BUTTON')}>{actions.ADD_BUTTON}</span></li>
                            <li><span onClick={() => handleSelection('DEFAULT_NOTE')}>{actions.DEFAULT_NOTE}</span></li>
                            <li><span className="btn-workflow-save-later" onClick={() => handleSelection('SAVE_LATER')}>{actions.SAVE_LATER}</span></li> 
                        </ul>
                    </div> */}
                </div>
                <div className=" bg-white p-4 rounded shadow ">
                    <b className="text-lg font-semibold mb-2">Examples</b>
                    <p>"Create a patient letter from my [note]"</p>
                    <p>"Create a plan from [history] and [note]"</p>

                </div>
                <div contentEditable="true"
                    onInput={handleCustomContentChange}
                    onBlur={handleCustomContentBlur}
                    ref={scrollCustomNoteRef} className="editable overflow-auto p-2 mt-4 max-h-[calc(100vh-110px)]" style={{ minHeight: '0px' }} dangerouslySetInnerHTML={{ __html: customNote }} />
                {isCustomNoteGenerating ? <div className='hn-ext-transcript-block-summary-progress'><div></div><div></div><div></div></div> : ""}

            </div>
            )}

        </div>
    );
};

export default PromptManagement;
