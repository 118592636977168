import React, { useEffect, useState, useRef } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { createPromptFromTemplate, isHealthNoteUser, getCookie, getSharedPrompts, mapCustomPromptObjToTemplates, deletePrompt, saveGlobalPrompt, createTemplateFromPrompt, saveDefaultGlobalPrompt, promptTemplates, fetchPrompts, getPromptDetails, isAlphaFlag } from './db';

import PromptTemplateModal from "./PromptTemplateModal";
import PromptShareModal from './PromptShareModal';
import Select from 'react-select';

import {
  Analytics,
  StandardAnalyticsService,
} from "./analytics"; // Adjust this import path as necessary

const PromptConfiguration = ({ isOpen, onClose, onSaveAndGenerate, onCreateTemplateWizard }) => {
  const analyticsService = StandardAnalyticsService.shared;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isHNUser = isHealthNoteUser();

  const [selectedTemplate, setSelectedTemplate] = useState('soapSection');
  const [defaultPromptObj, setDefaultPromptObj] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const promptTemplatesRef = useRef(promptTemplates);
  const promptTemplatesKeyRef = useRef('soapSection');

  const [, forceUpdate] = useState({});

  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [hasAdvanceModeChanges, setHasAdvanceModeChanges] = useState(false)
  const [advancedText, setAdvancedText] = useState('');

  let currentTemplate = promptTemplatesRef.current[selectedTemplate];
  const [isAddNewPromptModal, setIsAddNewPromptModal] = useState(false)
  const [isSharePromptModal, setIsSharePromptModal] = useState(false)


  const [activeTemplateList, setActiveTemplateList] = useState([])
  const [myCurrentTemplateListValue, setMyCurrentTemplateListValue] = useState(null)
  const [groupedTemplateList, setGroupedTemplateList] = useState([])


  const handleAddTemplate = async () => {
    setIsAddNewPromptModal(true)
  }
  const updateChanges = async (templateKey) => {

    try {
      forceUpdate({});
    } catch (error) {
      console.error('Error updating changes:', error);
    } finally {
    }
  };

  const onDelete = async () => {
    setIsLoading(true);
    if (promptTemplatesRef.current[selectedTemplate].ds_id) {
      await deletePrompt(promptTemplatesRef.current[selectedTemplate].ds_id)
    }
    onClose()
    window.location.href = window.location.href
  }

  const onSave = async () => {
    setIsLoading(true);

    let prompt = createPromptFromTemplate(promptTemplatesKeyRef.current);
    if (isAdvancedOpen) {
      prompt = advancedText
    }
    analyticsService.trackEvent(Analytics.Category.TEMPLATE, Analytics.Action.SAVED, "", promptTemplatesKeyRef.current, Analytics.ScreenName.TEMPLATE);


    const savedPromptObject = await saveDefaultGlobalPrompt(promptTemplatesKeyRef.current, { ...defaultPromptObj, "prompt": prompt, "isAdvanced": isAdvancedOpen });

    setDefaultPromptObj(savedPromptObject);

    console.log('Changes updated successfully');

    if (onSaveAndGenerate) {
      onSaveAndGenerate(savedPromptObject);
    }
  }

  const onShare = async (promptObj) => {

    onClose()
  }

  const onSaveOnly = async () => {
    setIsLoading(true);

    let prompt = createPromptFromTemplate(promptTemplatesKeyRef.current);
    if (isAdvancedOpen) {
      prompt = advancedText
    }


    const savedPromptObject = await saveGlobalPrompt(promptTemplatesKeyRef.current, { ...defaultPromptObj, "prompt": prompt, "isAdvanced": isAdvancedOpen });

    setDefaultPromptObj(savedPromptObject);

    console.log('Changes updated successfully');

    onClose()
    window.location.href = window.location.href
  }

  const handleOnAddedNewTemplate = async (data) => {
    setIsAddNewPromptModal(false);
    let newTemplate = data.ds_id;
    promptTemplatesKeyRef.current = newTemplate
    mapCustomPromptObjToTemplates(data);
    setIsAdvancedOpen(true);
    setAdvancedText(promptTemplatesRef.current[newTemplate].prompt);
    setActiveTemplateList(prevList => [
      ...prevList,
      { value: data.ds_id, label: data.title }
    ]);
    setSelectedTemplate(newTemplate);
    setDefaultPromptObj(data);
    setMyCurrentTemplateListValue({ "value": data.ds_id, "label": data.title })
    updateChanges(newTemplate);
  };

  const handleTemplateChange = async (e) => {
    setIsLoading(true);
    const newTemplate = e.target.value;
    promptTemplatesKeyRef.current = newTemplate;
    setSelectedTemplate(newTemplate);
    if (promptTemplatesRef.current[newTemplate] && promptTemplatesRef.current[newTemplate].isAdvanced) {
      setIsAdvancedOpen(true)
      setAdvancedText(promptTemplatesRef.current[newTemplate].prompt)
    }
    else if (promptTemplatesRef.current[newTemplate] && promptTemplatesRef.current[newTemplate].ds_id) {
      setIsAdvancedOpen(true)
      setAdvancedText(promptTemplatesRef.current[newTemplate].prompt)
    }
    else {
      promptTemplatesRef.current[newTemplate].prompt = createPromptFromTemplate(newTemplate);
    }

    if (promptTemplatesRef.current[newTemplate] && promptTemplatesRef.current[newTemplate].isAdvanced) {
      setIsAdvancedOpen(promptTemplatesRef.current[newTemplate].isAdvanced)
    }
    else {

      setIsAdvancedOpen(false)
    }

    if (promptTemplatesRef.current[newTemplate] && promptTemplatesRef.current[newTemplate].ds_id) {
      let data = await getPromptDetails(promptTemplatesRef.current[newTemplate].ds_id)
      mapCustomPromptObjToTemplates(data);
      setAdvancedText(data.prompt)
      setDefaultPromptObj(data);
    }
    else if (promptTemplatesRef.current[newTemplate]) {
      setDefaultPromptObj(promptTemplatesRef.current[newTemplate]);
      setAdvancedText(promptTemplatesRef.current[newTemplate].prompt)

    }
    else {
      setDefaultPromptObj({});
    }
    setIsLoading(false);

    updateChanges(newTemplate);



  };

  const handleSectionStyleChange = (id, style) => {
    promptTemplatesRef.current[selectedTemplate] = {
      ...promptTemplatesRef.current[selectedTemplate],
      sections: promptTemplatesRef.current[selectedTemplate].sections.map((section) =>
        section.id === id ? { ...section, sectionStyle: style } : section
      ),
    };
    updateChanges(selectedTemplate)
  };

  const handleHideByDefaultChange = (id, hideByDefault) => {
    promptTemplatesRef.current[selectedTemplate] = {
      ...promptTemplatesRef.current[selectedTemplate],
      sections: promptTemplatesRef.current[selectedTemplate].sections.map((section) =>
        section.id === id ? { ...section, hideByDefault } : section
      ),
    };
    updateChanges(selectedTemplate)
  };

  const handleSectionDetailChange = (id, detailLevel) => {
    promptTemplatesRef.current[selectedTemplate] = {
      ...promptTemplatesRef.current[selectedTemplate],
      sections: promptTemplatesRef.current[selectedTemplate].sections.map((section) =>
        section.id === id ? { ...section, detailLevel } : section
      ),
    };
    updateChanges(selectedTemplate)
  };

  const handleCustomTitleChange = (id, customTitle) => {
    promptTemplatesRef.current[selectedTemplate] = {
      ...promptTemplatesRef.current[selectedTemplate],
      sections: promptTemplatesRef.current[selectedTemplate].sections.map((section) =>
        section.id === id ? { ...section, customTitle } : section
      ),
    };
  };

  const handleSplitByProblemChange = (id, splitByProblem) => {
    promptTemplatesRef.current[selectedTemplate] = {
      ...promptTemplatesRef.current[selectedTemplate],
      sections: promptTemplatesRef.current[selectedTemplate].sections.map((section) =>
        section.id === id ? { ...section, splitByProblem } : section
      ),
    };
    updateChanges(selectedTemplate)
  };

  const formatGroupLabel = (data) => (
    <div style={{ marginRight: '1em', color: 'black', fontSize: '20px', fontWeight: 'bold' }}>
      <span>{data.label}</span>
    </div>
  );

  const reload = async function () {
    setIsLoading(true);

    let sharedPrompts = []
    let myPrompts = []
    let systemPrompts = [{ "value": "soapSection", "label": "SOAP Section" }]

    let groupList = [
      { "label": "System Templates", "options": systemPrompts },
      { "label": "My Templates", "options": myPrompts },
      { "label": "Community Templates", "options": sharedPrompts }

    ]

    //sharedPrompts
    let userInfo = getCookie("info");
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
    }

    if (userInfo.specialty && userInfo.specialty.specialty) {
      let tmpShared = await getSharedPrompts(userInfo.specialty.specialty)
      if (tmpShared && tmpShared.length > 0) {
        for (let i = 0; i < tmpShared.length; i++) {
          if(tmpShared[i].version != "0917"){
            sharedPrompts.push({ "value": tmpShared[i].title, "label": tmpShared[i].title, "prompt": tmpShared[i].prompt })
            mapCustomPromptObjToTemplates(tmpShared[i])
          }
        }
      }
    }
    else
    {
      let tmpShared = await getSharedPrompts("general")
      if (tmpShared && tmpShared.length > 0) {
        for (let i = 0; i < tmpShared.length; i++) {
          if(tmpShared[i].version != "0917"){
            sharedPrompts.push({ "value": tmpShared[i].title, "label": tmpShared[i].title, "prompt": tmpShared[i].prompt })
            mapCustomPromptObjToTemplates(tmpShared[i])
          }
        }
      }
    }

    //systemPrompts
    systemPrompts.push(
      { "value": "soapAPSection", "label": "SOAP Section - Assessment & Plan" }
    )
    systemPrompts.push(
      { "value": "DAP", "label": "DAP" }
    )
    systemPrompts.push(
      { "value": "H&P", "label": "H&P" }
    )
    systemPrompts.push(
      { "value": "multiSection", "label": "Multi Section" }
    )
    //myPrompts
    let myDefaultPrompt = null;
    let tmpPrompts = await fetchPrompts()
    for (let i = 0; i < tmpPrompts.length; i++) {
      let title = tmpPrompts[i].title
      if (title in promptTemplates) {
        tmpPrompts[i].title = promptTemplates[title].name
      }

      if (tmpPrompts[i].type === "DEFAULT_NOTE" && !myDefaultPrompt) {
        myDefaultPrompt = tmpPrompts[i];
        setMyCurrentTemplateListValue({ "value": tmpPrompts[i].ds_id, "label": tmpPrompts[i].title })
      }
      else if (tmpPrompts[i].type === "DEFAULT_NOTE" || tmpPrompts[i].type === "NOTE_TEMPLATE") {
        myPrompts.push({ "value": tmpPrompts[i].ds_id, "label": tmpPrompts[i].title })
      }

      mapCustomPromptObjToTemplates(tmpPrompts[i])
    }

    if (!myDefaultPrompt) {
      myDefaultPrompt = systemPrompts[0]
      setMyCurrentTemplateListValue(systemPrompts[0])
    }

    setGroupedTemplateList(groupList)



    let defaultPrompt = myDefaultPrompt;
    // let tmpActivePromptTemplateList = [
    //   { "value": "soapSection", "label": "SOAP Section" }
    // ]
    // if (isAlphaFlag) {
    //   tmpActivePromptTemplateList.push(
    //     { "value": "soapAPSection", "label": "SOAP Section - Assessment & Plan" }
    //   )
    //   tmpActivePromptTemplateList.push(
    //     { "value": "multiSection", "label": "Multi Section" }
    //   )
    // }
    // let data = await fetchPrompts()
    // for (let i = 0; i < data.length; i++) {


    //   if (data[i].type === "DEFAULT_NOTE" && !defaultPrompt) {
    //     defaultPrompt = data[i];
    //     if (defaultPrompt.title in promptTemplates) {
    //       //Already in the default
    //     }
    //     else {
    //       //Custom / New Advanced Templates
    //       tmpActivePromptTemplateList.push({ "value": defaultPrompt.ds_id, "label": defaultPrompt.title })
    //     }
    //   }
    //   else if (data[i].type === "DEFAULT_NOTE" || data[i].type === "NOTE_TEMPLATE") {
    //     if (!(data[i].title in promptTemplates)) {
    //       tmpActivePromptTemplateList.push({ "value": data[i].ds_id, "label": data[i].title })
    //     }
    //   }

    //   mapCustomPromptObjToTemplates(data[i])
    // }

    // setActiveTemplateList(tmpActivePromptTemplateList)

    if (defaultPrompt && defaultPrompt["ds_id"]) {
      getPromptDetails(defaultPrompt["ds_id"]).then((data) => {
        mapCustomPromptObjToTemplates(data) //get details here
        setDefaultPromptObj(data);
        let tmpSelectedTemplateKey = ""
        if (data["title"] in promptTemplatesRef.current) {
          tmpSelectedTemplateKey = data["title"]
          let originalTemplate = JSON.parse(JSON.stringify(promptTemplatesRef.current[tmpSelectedTemplateKey]))
          promptTemplatesRef.current[tmpSelectedTemplateKey] = createTemplateFromPrompt(originalTemplate, data);
        }
        else {
          tmpSelectedTemplateKey = data["ds_id"]
        }
        promptTemplatesKeyRef.current = tmpSelectedTemplateKey;
        setSelectedTemplate(tmpSelectedTemplateKey)


        setAdvancedText(data.prompt)
        if (data.isAdvanced) {
          setIsAdvancedOpen(true)
        }

        currentTemplate = promptTemplatesRef.current[tmpSelectedTemplateKey]
        forceUpdate({});
        setIsLoading(false);
      }).catch((error) => {
        console.error('Error fetching prompt details:', error);
        setIsLoading(false);
      });
    }
    else {
      setIsLoading(false);
    }

  }

  useEffect(() => {
    if (isOpen) {
      analyticsService.trackScreen(Analytics.Category.TEMPLATE,Analytics.ScreenName.TEMPLATE); 
    }
    reload();
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="modal modal-open">


      <div className={`modal-box md:ml-[190px] ${isMobile || !isAdvancedOpen ? 'max-w-xl' : 'max-w-[calc(100vw-300px)]'}`}>
        <button onClick={onClose} className="btn btn-sm btn-circle absolute right-2 top-2">✕</button>
        <h3 className="font-bold text-lg mb-4">Pick a template below:</h3>
        <div className={`${isMobile ? 'max-w-full' : 'max-w-full'}`}>

          <div className="flex flex-col ">
            {isLoading && (
              <center><br /><br /><div className='hn-ext-transcript-block-summary-progress'><div></div><div></div><div></div></div></center>
            )}



            {!isLoading && (
              <div className={`form-control w-full ${!isAdvancedOpen || isMobile ? 'md:max-w-xl md:pr-4' : 'max-w-[calc(100vw-300px)]'} `}>
                <div className="flex items-center space-x-2">
                  <Select
                    className="w-full max-w-full"
                    placeholder="Select a Template"
                    value={myCurrentTemplateListValue}
                    noOptionsMessage={(e) => `No value found, please select Other`}
                    onChange={(option) => {
                      setMyCurrentTemplateListValue(option)
                      handleTemplateChange({ target: { value: option.value } })

                      analyticsService.trackEvent(Analytics.Category.TEMPLATE, Analytics.Action.CHANGED, "", option.value, Analytics.ScreenName.TEMPLATE);
                    }}
                    formatGroupLabel={formatGroupLabel}
                    maxMenuHeight={'450px'}
                    options={groupedTemplateList} // Use react-select for better UI
                  >

                  </Select>

                  {/* <select className="select select-bordered flex-grow" value={selectedTemplate} onChange={handleTemplateChange}>
                    {activeTemplateList.map(item => (
                      <option key={item.value} value={item.value}>{item.label}</option>
                    ))}
                  </select> */}
                  {/* {isAlphaFlag() && (
                    <button onClick={handleAddTemplate} className="btn btn-add-new-templates btn-accent btn-square btn-sm">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                      </svg>
                    </button>
                  )} */}

                </div>
                <div className='mt-4'>
                  or <div className='ml-2 btn btn-sm btn-accent' onClick={(e)=>{onCreateTemplateWizard();}}>Create Your Own</div>
                </div>

                <div className="mt-6">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-lg font-bold">Note Section Settings</h3>
                    {isAlphaFlag() && (
                      <label className="cursor-pointer label">
                        <span className="label-text mr-2">Override</span>
                        <input
                          type="checkbox"
                          className="toggle btn-template-override toggle-primary"
                          checked={isAdvancedOpen}
                          onChange={() => setIsAdvancedOpen(!isAdvancedOpen)}
                        />
                      </label>
                    )}
                  </div>
                  <p className="text-sm">{currentTemplate.description}</p>
                  {isAdvancedOpen && (
                    <div></div>
                  )}
                  <div className=' overflow-auto max-h-[calc(60vh-200px)]'>
                    {isAdvancedOpen && (
                      <textarea
                        className="textarea textarea-bordered w-full h-[calc(60vh-220px)] mt-2"
                        placeholder="Enter template instruction here."
                        value={advancedText}
                        onChange={(e) => setAdvancedText(e.target.value)}
                        rows={5}
                      />
                    )}

                    {!isAdvancedOpen && currentTemplate.sections.map((section) => (
                      <div key={section.id} className={`block relative mt-4 p-4 border rounded-lg border-transparent ${section.hideByDefault? "opacity-50":" bg-gray-100"}  `}>
                        <input
                          type="checkbox"
                          className="block toggle toggle-sm toggle-primary absolute right-2"
                          checked={!section.hideByDefault}
                          onChange={(e) => handleHideByDefaultChange(section.id, !e.target.checked)}
                        />

                        <div>
                          <h4 className="font-semibold">{section.title}</h4>
                          {/* 
                          {isAlphaFlag() && (
                            <div className="form-control">
                              <label className="flex justify-between items-center w-full">
                                <span className="label-text">Display Name</span>
                                <input
                                  type="text"
                                  value={section.customTitle || ''}
                                  onChange={(e) => handleCustomTitleChange(section.id, e.target.value)}
                                  className="input input-sm input-bordered"
                                />
                              </label>
                            </div>
                          )} */}
                        </div>

                        {!section.hideByDefault && section.splitByProblem !== undefined && (
                          <div className="form-control mt-1">
                            <label className="cursor-pointer label">
                              <span className="label-text">Split by problem</span>
                              <input
                                type="checkbox"
                                className="toggle toggle-primary"
                                checked={section.splitByProblem}
                                onChange={(e) => handleSplitByProblemChange(section.id, e.target.checked)}
                              />
                            </label>
                          </div>
                        )}
                        {!section.hideByDefault && (
                        <div className="form-control mt-2">
                          <label className="flex justify-between items-center w-full">
                            <span className="label-text">Section style</span>
                            <div className="flex justify-end space-x-1">
                              <button
                                className={`btn btn-xs ${section.sectionStyle === 'Bullet points' ? 'btn-accent' : 'btn-outline'}`}
                                onClick={() => handleSectionStyleChange(section.id, 'Bullet points')}
                              >
                                Bullet points
                              </button>
                              <button
                                className={`btn btn-xs ${section.sectionStyle === 'Paragraph' ? 'btn-accent' : 'btn-outline'}`}
                                onClick={() => handleSectionStyleChange(section.id, 'Paragraph')}
                              >
                                Paragraph
                              </button>
                            </div>
                          </label>
                        </div>
                        )}

                        {!section.hideByDefault && section.detailLevel && (
                          <div className="form-control mt-2">
                            <label className="flex justify-between items-center w-full">
                              <span className="label-text">Detail Level</span>
                              <div className="flex justify-end space-x-1">
                                <button
                                  className={`btn btn-xs ${section.detailLevel === 'High' ? 'btn-accent' : 'btn-outline'}`}
                                  onClick={() => handleSectionDetailChange(section.id, 'High')}
                                >
                                  High
                                </button>
                                <button
                                  className={`btn btn-xs ${section.detailLevel === 'Normal' ? 'btn-accent' : 'btn-outline'}`}
                                  onClick={() => handleSectionDetailChange(section.id, 'Normal')}
                                >
                                  Normal
                                </button>
                              </div>
                            </label>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

          </div>

        </div>

        {!isLoading && (
          <div className="modal-action">
            {isAdvancedOpen && isAlphaFlag() && promptTemplatesRef.current[selectedTemplate].ds_id && (<button className="btn btn-template-delete btn-error" onClick={onDelete}>Delete</button>)}

            <button className="btn btn-template-make-default btn-accent" onClick={onSave}>Update Note </button>
            {isAdvancedOpen && isAlphaFlag() && (<button className="btn btn-template-onsave" onClick={onSaveOnly}>Update Template</button>)}

            {isAdvancedOpen && isHNUser && (<button className="btn btn-template-share" onClick={() => { setIsSharePromptModal(true) }}>Share</button>)}
            <button className="btn btn-template-cancel" onClick={onClose}>Cancel</button>
          </div>
        )}
      </div>
      <PromptTemplateModal onSave={handleOnAddedNewTemplate} onClose={() => { setIsAddNewPromptModal(false) }} isOpen={isAddNewPromptModal} />
      <PromptShareModal key={defaultPromptObj['ds_id'] || ''} promptObj={defaultPromptObj} onShare={onShare} onClose={() => { setIsSharePromptModal(false) }} isOpen={isSharePromptModal} />
    </div>
  );
};

const savePromptToDatabase = async (prompt) => {
  // Implementation of saving prompt to database
  // This is a placeholder and should be replaced with actual API call
  return new Promise((resolve) => {
    setTimeout(() => resolve({ ...prompt, id: Date.now() }), 1000);
  });
};

export default PromptConfiguration;