import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import PatientNote from "./PatientNote";
import MenuIcon from "@mui/icons-material/Menu";
import {
  checkDatabaseExists,
  addPatient,
  deleteEncounter,
  getSharedPrompts,
  fetchPrompts,
  logError,
  getKeys,
  saveKeys,
  getAuth,
  getAuthToken,
  setInfo,
  setAuthToken,
  decodeURLToken,
  handleLogout,
  setJsonCookie,
  checkMicroPhonePermission,
  setMicroPhonePermission,
  isHealthNoteUser,
  convertToUnixTimestamp
} from "./db";

import {
  Analytics,
  StandardAnalyticsService,
} from "./analytics"; // Adjust this import path as necessary
import {
  fetchPatients,
  getUserProfile,
  updateUserProfile,
  initializeUserProfile,
  getCookie,
  getHubspotAlert,
  setHubspotAlert,
  isAlphaFlag,
  getUrlParameters,
} from "./db"; // Adjust this import path as necessary
import { getCurrentDefaultPrompt, checkNewPromptFormat } from "./prompt.formatting.js";

import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import ModalHubspot from "./ModalHubspot";
import SpecialtySelector from "./SpecialtySelector";
import PromptConfiguration from "./PromptConfiguration";
import PromptNotificationModal from "./PromptNotificationModal";
import Intake from "./Intake";
import Header from "./header";
import * as Sentry from "@sentry/react";
import AsyncMicrophonePermissionModal from "./AsyncMicrophonePermissionModal.jsx";
import DocumentTemplateComponent from "./DocumentTemplateComponent";
import DocumentTemplateBuilder from "./DocumentTemplateBuilder.jsx";
import Intercom from '@intercom/messenger-js-sdk';
import DotPhraseModal from "./DotPhraseModal.jsx";


Sentry.init({
  dsn: "https://04c2a389801463d948ad02d1c6027893@o553809.ingest.us.sentry.io/4507495059619840",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    ,
    /^https:\/\/listen.healthnote\.com/,
    /^https:\/\/listen-preview.healthnote\.com/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If  you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function formatDateWithMoment(date) {
  return moment(date).format("MM/DD/YY hh:mm A");
}

function getUrlParameter(name) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
}


const languages = [
  { title: "English", value: "en" },
  { title: "Spanish", value: "enes" },

  { title: "Albanian", value: "sq" },
  { title: "Arabic", value: "ar" },
  { title: "Bengali", value: "bn" },
  { title: "Bulgarian", value: "bg" },
  { title: "Catalan", value: "ca" },
  { title: "Chinese (Mandarin) Simplified", value: "zh" },
  { title: "Chinese (Mandarin) Traditional", value: "zh-TW" },

  { title: "Croatian", value: "hr" },
  { title: "Czech", value: "cs" },
  { title: "Danish", value: "da" },
  { title: "Dutch", value: "nl" },
  { title: "Estonian", value: "et" },
  { title: "Finnish", value: "fi" },
  { title: "French", value: "fr" },
  { title: "German", value: "de" },
  { title: "Greek", value: "el" },
  { title: "Gujarati", value: "gu" },
  { title: "Hindi", value: "hi" },
  { title: "Hungarian", value: "hu" },
  { title: "Indonesian", value: "id" },
  { title: "Italian", value: "it" },
  { title: "Japanese", value: "ja" },
  { title: "Kannada", value: "kn" },
  { title: "Korean", value: "ko" },
  { title: "Latvian", value: "lv" },
  { title: "Lithuanian", value: "lt" },
  { title: "Malay", value: "ms" },
  { title: "Malayalam", value: "ml" },
  { title: "Marathi", value: "mr" },
  { title: "Norwegian", value: "no" },
  { title: "Persian", value: "fa" },
  { title: "Polish", value: "pl" },
  { title: "Portuguese", value: "pt" },
  { title: "Punjabi", value: "pa" },
  { title: "Romanian", value: "ro" },
  { title: "Russian", value: "ru" },
  { title: "Serbian", value: "sr" },
  { title: "Slovak", value: "sk" },

  { title: "Swahili", value: "sw" },
  { title: "Swedish", value: "sv" },
  { title: "Tamil", value: "ta" },
  { title: "Telugu", value: "te" },
  { title: "Thai", value: "th" },
  { title: "Turkish", value: "tr" },
  { title: "Ukrainian", value: "uk" },
  { title: "Urdu", value: "ur" },
  { title: "Vietnamese", value: "vi" },
];

initializeUserProfile();

function IsIphoneOrIpad() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Check for iPhone or iPad in the user agent string
  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
}


const AUTH_URL = import.meta.env.VITE_VERCEL_ENV == 'preview' ? import.meta.env.VITE_PREVIEW_AUTH_URL : import.meta.env.VITE_AUTH_URL;
const LOGIN_URL = import.meta.env.VITE_VERCEL_ENV == 'preview' ? import.meta.env.VITE_PREVIEW_LOGIN_URL : import.meta.env.VITE_LOGIN_URL;
const LANDING_URL = import.meta.env.VITE_VERCEL_ENV == 'preview' ? import.meta.env.VITE_PREVIEW_LANDING_PAGE_URL : import.meta.env.VITE_LANDING_PAGE_URL;



function App() {
  const analyticsService = StandardAnalyticsService.shared;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  const [patientToBeDelete, setPatientToBeDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDictationEnabled, setIsDictationEnabled] = useState(false);

  const [isSpecialtyModalOpen, setSpecialtyModalOpen] = useState(false);
  const [specialty, setSpecialty] = useState({});

  const [isHubspotModalOpen, setIsHubspotModalOpen] = useState(false);

  const [patientName, setPatientName] = useState("");
  const [ehrId, setEhrId] = useState("");
  const [patientHistory, setPatientHistory] = useState("");
  const [region, setRegion] = useState("west");
  const [language, setLanguage] = useState("en");

  const [selectedPatient, setSelectedPatient] = useState(null);
  const [aboutToSelectPatient, setAboutToSelectPatient] = useState(null);

  const [patients, setPatients] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [newPatient, setNewPatient] = useState({ name: "", clinicalNote: "" });
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [prompts, setPrompts] = useState([]);
  const [sharedPrompts, setSharedPrompts] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIphoneOrIpad = IsIphoneOrIpad();
  const drawerWidth = 200;

  const [hasChanges, setHasChanges] = useState(false);
  const [hasChangesModalOpen, setHasChangesModalOpen] = useState(false);

  const [dotPhraseModalOpen, setDotPhraseModalOpen] = useState(false);

  const [isTemplateWizardOpen, setIsTemplateWizardOpen] = useState(false);
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);
  const [isPromptNotificationModalOpen, setPromptNotificationModalOpen] = useState(false)

  const [continueStatus, setContinueStatus] = useState(null);
  const patientNoteRef = useRef();

  const microphonePermissionModalRef = React.useRef(null);
  const [microphoneAccessMessage, setMicrophoneAccessMessage] = useState("");

  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);

  const [isNewPromptFormat, setIsNewPromptFormat] = useState(false);

  const [intakeModeKey, setIntakeModeKey] = useState(false);


  const maxHistoryLength = 1000; // Set your desired maximum length here

  function openEmail() {
    var subject = encodeURIComponent("Ambient Scribe AI - Early Access");
    var body = encodeURIComponent("Link https://www.healthnote.com/invite");
    window.location.href = "mailto:?subject=" + subject + "&body=" + body;
  }


  async function getMyPrompts() {
    const prompts = await fetchPrompts();

    setPrompts(prompts);
    let tmpMyDefaultPrompt = getCurrentDefaultPrompt(prompts);
    let isDefault = checkNewPromptFormat(tmpMyDefaultPrompt)
    if(isNewPromptFormat && isHealthNoteUser()){
      setIsNewPromptFormat(isDefault);
    }

    return prompts;
  }

  useEffect(() => {
    async function init() {
      //analyticsService.trackScreen("", Analytics.ScreenName.MAIN);
      let code = getUrlParameter("code");
      let email = getUrlParameter("email");
      let token = getUrlParameter("token");
      let TOS = getUrlParameter("TOS");
      let intakeKey = getUrlParameter("intake");
      let first_name = getUrlParameter("first_name");
      let last_name = getUrlParameter("last_name");



      // Define the request options
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", mode: "no-cors" },
        body: JSON.stringify({ code: code }),
      };


      let authToken = getCookie("authToken");
      let userInfo = getCookie("info");
      if (userInfo) {
        userInfo = JSON.parse(userInfo);
      }

      if(!authToken){
        authToken = await getAuthToken();
      }

      console.log("authToken", authToken)




      const invited = getCookie("invite")
      let source = 'unknown';

      if(token){
        let pk = prompt("Enter the code");

        if (pk === "Hn2025") {
          authToken = decodeURLToken(token);
          await setAuthToken(authToken);
        } else {
          window.location.href = LOGIN_URL;
        }


      }

      if(intakeKey){
        setIntakeModeKey(intakeKey);
        return;
      }


      if (authToken && userInfo) {
        // todo check if the user is already authorized
        getUserProfile().then((userInfo) => {
          if (!userInfo) {
            window.location.href = LOGIN_URL + '/sign-up';//'https://www.healthnote.com/sign-in/'; //
            return;
          }
          if (userInfo.id) {
            Intercom({
              app_id: 'tr9tvfh3',
              user_id: userInfo.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
              name: userInfo.first_name + " " + userInfo.last_name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
              email: userInfo.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
              created_at: convertToUnixTimestamp(userInfo.TOSBAAPrivacyAgreementDate), // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
              user_hash: userInfo.hash
            });
          }



          if (!userInfo.specialty) {
            // setSpecialtyModalOpen(true);
          } else {
            setSpecialty(userInfo.specialty);
          }
          if (userInfo.id) {
            if (invited) {
              try {
                let invite_info = JSON.parse(decodeURIComponent(invited));
                source = invite_info.source || 'unknown';
              } catch (e) {

              }
            }
            analyticsService.setup({
              id: userInfo.id,
              email: userInfo.email,
              name: userInfo.first_name + " " + userInfo.last_name,
              specialty: userInfo.specialty,
              source: source
            });
          }

          const keys = getKeys().then((keys) => {
            getAuth().then((data) => {
              if (!data) {
                handleLogout();
                return
              }
              if (data && data.region) {
                setRegion(data.region);
              }
              if (data && data.gpt) {
                saveKeys(data).then(() => {
                  setIsAuthorized(true);
                });
              }
            });
          });
        });
      } else {
        if (!code && !authToken && !invited) {
          if (LANDING_URL)
            window.location.href = LANDING_URL;
          return;
        } else if (code == 'invite2025') {
          try {
            let params = getUrlParameters();
            setJsonCookie("invite", params, 30);

            if (params.email) {
              analyticsService.setup({
                page: '/landing',
                email: params.email,
                source: params.source || 'unknown',
              }, true);
            }
          } catch (e) {
            console.log(e)
          }
          setTimeout(() => {
            let redirectURL = LOGIN_URL + '/sign-up';
            if (email) {
              window.location.href = redirectURL + '?email=' + email
            }
          }, 200);


        } else if (code) {
          // Fetch the auth token
          // initGoogleAnalytics();
          fetch(AUTH_URL + "/jwt", requestOptions)
            .then((response) => response.json())
            .then(async (data) => {
              if (data.token) {
                // Calculate the expiration date
                const days = 365;
                const expirationHours = 1;
                const date = new Date();
                date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
                const expires = `expires=${date.toUTCString()}`;



                // Save the token as a cookie with expiration
                await setInfo({
                  id: data.id,
                  email: data.email,
                  first_name: data.first_name,
                  last_name: data.last_name,
                });
                await setAuthToken(data.token);

                try {
                  let invite_info = JSON.parse(decodeURIComponent(invited));
                  setJsonCookie("invite", invite_info, 30);
                  source = invite_info.source || 'unknown';
                } catch (e) {

                  let strInfo = JSON.stringify({
                    id: data.id,
                    email: data.email,
                    first_name: data.first_name,
                    last_name: data.last_name,
                  });
                  document.cookie = `invite=${strInfo}; path=/; secure; SameSite=Strict; ${expires};`;

                }


                getAuth().then((data) => {
                  if (data && data.gpt) {
                    saveKeys(data);

                    if (code) {
                      setTimeout(() => {
                        window.location.href = "/";
                      }, 100);
                      return;
                    }

                    setIsAuthorized(true);
                  }
                });
              }
              else {
                window.location.href = LOGIN_URL;
              }
            })
            .catch((error) => {
              logError("Error fetching auth data:", error);
              window.location.href = LOGIN_URL;

            });
        } else if (authToken) {
          setIsAuthorized(true);
          getUserProfile().then((userInfo) => { });
        } else {
          window.location.href = LOGIN_URL;
        }
      }

      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 1000); // Set the delay time in milliseconds

      const loadPatients = async () => {
        const allPatients = await fetchPatients();
        if (allPatients.length == 0) {
          setTimeout(() => {

            analyticsService.trackEvent(Analytics.Category.TRIAL, Analytics.Action.SIGNED_UP, "", "", Analytics.ScreenName.MAIN, {
              source: source
            });
          }, 3000);
        }
        const prompts = await getMyPrompts();


        setPatients(allPatients);
        setIsLoading(false);
      };
      loadPatients();
    }
    init();
  }, []);

  useEffect(() => {
    // const checkHubspotAlert = async () => {
    //   const alertClosed = await getHubspotAlert();
    //   if (!alertClosed) {
    //     setIsHubspotModalOpen(true);
    //   }
    // }
    // if(patients.length > 5){
    //   checkHubspotAlert()
    // }
  }, [patients]);

  const updatePatientsList = async (patient) => {
    setHasChanges(false);
    // const updatedPatients = await fetchPatients(); // Assume this fetches updated list
    // setPatients(updatedPatients); // Update state with new patient list

    let tmpPatients = [...patients];
    if (patient && patient.ds_id) {
      for (let i = 0; i < patients.length; i++) {
        if (tmpPatients[i].ds_id === patient.ds_id) {
          tmpPatients[i] = patient;
          tmpPatients[i].name = patient.name;

          break;
        }
      }
      setPatients(tmpPatients);
    }

  };

  const toggleDrawer = (open) => () => {
    if (open) {
      analyticsService.trackScreen(Analytics.Category.ENCOUNTER_LIST, Analytics.ScreenName.MAIN);
    }
    setDrawerOpen(open);
  };
  const handleCancel = () => {
    setEhrId("");
    setPatientName("");
    setPatientHistory("");

    setIsModalOpen(false);
  };

  const onNoteSettingsFromNote = () => {
    setIsPromptModalOpen(true);
    setEhrId("");
    setPatientName("");
    setPatientHistory("");
    // setSelectedPatient(null); //AR: keep this since use might be wanting to regenerate the current note
    setDrawerOpen(false);
  };

  const handleHasChangesContinue = () => {
    setHasChangesModalOpen(false);
    setSelectedPatient(aboutToSelectPatient);
  };

  const handlePatentChanges = (hasChanges) => {
    console.log("Changes:", hasChanges);
    setHasChanges(true);
  };

  const handlePromptChange = async (changedPrompts) => {
    const prompts = await getMyPrompts()
  };

  const handleSpecialtyCancel = () => {
    setSpecialtyModalOpen(false);
  };

  const handleSpecialtyContinue = (selectedSpecialty, experimentEnabled) => {
    setSpecialty(selectedSpecialty);
    setSpecialtyModalOpen(false);
    analyticsService.trackEvent(Analytics.Category.USER, Analytics.Action.SAVED, selectedSpecialty, "", Analytics.ScreenName.PROFILE, { healthnote_labs: experimentEnabled });
    updateUserProfile({ specialty: selectedSpecialty, experimentEnabled: experimentEnabled });
  };

  const handleChangeSpecialty = () => {
    setSpecialtyModalOpen(true);
  };

  const handleOnDeleteEncounter = async (event, patient) => {
    event.stopPropagation();
    // event.currentTarget.className = '-ml-[50px] mt-4 loading loading-spinner loading-sm';
    // setSelectedPatient(null);
    setPatientToBeDelete(patient);
    setIsDeleteConfirmation(true);
    // deleteEncounter(patient.id || patient.ds_id).then(() => {
    //   setSelectedPatient(null);
    //   setPatients(patients.filter((p) => p.id !== patient.id || p.ds_id !== patient.ds_id));
    // })
  };

  const handleDeleteEncounterConfirmation = () => {
    setIsDeleteConfirmation(true);
    setSelectedPatient(null);
    setIsDeleting(true);
    if (patientToBeDelete) {
      deleteEncounter(patientToBeDelete.id || patientToBeDelete.ds_id).then(
        () => {
          setSelectedPatient(null);
          setPatients(
            patients.filter(
              (p) =>
                p.id !== patientToBeDelete.id ||
                p.ds_id !== patientToBeDelete.ds_id
            )
          );
          setIsDeleteConfirmation(false);
          setPatientToBeDelete(null);
          setIsDeleting(false);
        }
      );
    } else {
      setIsDeleteConfirmation(false);
      setIsDeleting(false);
    }
  };
  const handleContinue = async (source) => {
    setContinueStatus("loading");
    // Handle continue action here

    const newPatient = {
      name: patientName,
      clinicalNote: "Placeholder",
      appointmentTimestamp: new Date().toISOString(),
      date: formatDateWithMoment(new Date()),
      history: patientHistory,
      language: language,
      ehrId: ehrId,
      isDictation: isDictationEnabled
    };



    const id = await addPatient(newPatient);
    const addedPatient = { id, ...newPatient };
    setPatients([addedPatient, ...patients]);
    setNewPatient({ name: "", clinicalNote: "" });
    setSelectedPatient(addedPatient);

    setEhrId("");
    setPatientName("");
    setPatientHistory("");
    setLanguage("en");
    setIsDictationEnabled(false)

    setContinueStatus(null);
    setIsModalOpen(false);

    analyticsService.trackEvent(
      Analytics.Category.ENCOUNTER,
      Analytics.Action.SAVED,
      "",
      id,
      source);

  };

  const handleContinueStart = async (source, params) => {

    let isMicrophonePermissionGranted = await checkMicroPhonePermission();

    if (!isMicrophonePermissionGranted && patients.length <= 2) {
      const grantedAccess = await microphonePermissionModalRef.current.openModal({
        screenName: Analytics.ScreenName.MAIN,
      });

      if (!grantedAccess) {
        setMicrophoneAccessMessage("Please allow microphone access every time to continue.");
        return;
      }
      else if (grantedAccess.indexOf && grantedAccess.indexOf("enable") > -1) {
        setMicrophoneAccessMessage(grantedAccess);
        return;
      }
      setMicroPhonePermission(true);
    }

    setContinueStatus("loading");
    // Handle continue action here

    const newPatient = {
      name: patientName,
      clinicalNote: "Placeholder",
      appointmentTimestamp: new Date().toISOString(),
      date: formatDateWithMoment(new Date()),
      history: patientHistory,
      language: language,
      ehrId: ehrId,
      isDictation: isDictationEnabled
    };
    const id = await addPatient(newPatient);
    const addedPatient = { id, ...newPatient };
    setPatients([addedPatient, ...patients]);
    setNewPatient({ name: "", clinicalNote: "" });
    setSelectedPatient(addedPatient);

    analyticsService.trackEvent(
      Analytics.Category.ENCOUNTER,
      Analytics.Action.STARTED,
      isDictationEnabled,
      language,
      source, {
      mrm_present: ehrId ? true : false,
      history_present: patientHistory ? true : false,
      encounter_id: id,
      quick_start: params && params.quick_start ? true : false
    });


    setEhrId("");
    setPatientName("");
    setPatientHistory("");
    setLanguage("en");
    setIsDictationEnabled(false)

    setContinueStatus(null);
    setIsModalOpen(false);



    setTimeout(() => {

      patientNoteRef.current.toggleMic(source);
    }, 200)
  };

  const onDotPhraseModalOpen = () => {
    console.log("Dot Phrase Modal Opened");
    setDotPhraseModalOpen(true);
  };


  const handleStart = async () => {
    setIsPromptModalOpen(false);
    setEhrId("");
    setPatientName("");
    setPatientHistory("");
    setLanguage("en");
    setIsDictationEnabled(false)

    setIsModalOpen(true);
    analyticsService.trackEvent(Analytics.Category.ENCOUNTER, Analytics.Action.PREP, "", "", Analytics.ScreenName.MAIN);
    setDrawerOpen(false);
  };

  const closeHubspotModal = () => {
    setIsHubspotModalOpen(false);
  };

  const drawerContent = (
    <ul className={`list-none relative -ml-2 w-[220px] block `}>
      {patients.map((patient) => (
        <li
          className={`border-b patient-item border-gray-200 cursor-pointer p-3 ${isMobile || isIphoneOrIpad ? "" : " hover:bg-gray-100 "}${selectedPatient && selectedPatient.ds_id === patient.ds_id
            ? "bg-gray-200"
            : ""
            }`}
          key={patient.id || patient.ds_id}
          onClick={() => {
            setIsPromptModalOpen(false);
            if (hasChanges) {
              setAboutToSelectPatient(patient);
              setHasChangesModalOpen(true);
            } else {
              setSelectedPatient(patient);
              analyticsService.trackEvent(Analytics.Category.ENCOUNTER, Analytics.Action.SELECTED, "", "", Analytics.ScreenName.MAIN);

              if (isMobile) setDrawerOpen(false);
            }
          }}
        >
          <div className="block items-center">
            <button
              className="btn absolute  z-2 ml-[128px] mt-5 btn-delete-encounter  btn-xs btn-outline btn-circle border-transparent"
              onClick={(e) => {
                e.stopPropagation();
                handleOnDeleteEncounter(e, patient);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                />
              </svg>
            </button>

            <div className="">
              <div className="text-sm text-gray-600 w-[180px]">
                {patient.date} <br />
                {patient.ehrId && (
                  <span className="text-xs">ID:{patient.ehrId}</span>

                )}
              </div>
              {patient.name !== "-" && (
                <div className="font-bold text-md pt-name w-[130px]">

                  {patient.name || "Encounter"}

                </div>
              )}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
  if(intakeModeKey){
    return (
      <Intake></Intake>
    )
  }

  if (!isAuthorized || isLoading) {
    return (
      <>
        <br />
        <br />
        <br />
        <center>Loading</center>
        <br />
        <br />
        <div className="hn-ext-transcript-block-summary-progress">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </>
    ); // or a loading spinner, or some placeholder content
  }



  return (
    <div data-theme="light">
      {/* <div
        className={`absolute block bottom-1/2 z-50 -right-6 -rotate-90 ${isVisible ? "visible" : "invisible"
          }`}
      >
        <button
          id="feedback"
          className="btn-error transform btn relative block feedback "
        >
          Feedback
        </button>
      </div> */}

      <Box
        sx={{
          display: "flex",
          backgroundColor: "#f1f1f1",
          height: "calc(100vh - 0px)",

          // width: isMobile ? 'auto' : 'calc(100vw - 15px)' // Adjusted for mobile and default case
        }}
      >
        {isMobile && (
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer(true)}
            sx={{
              marginRight: 2,
              position: "fixed",
              zIndex: 1000,
              top: 8,
              left: 15,
              color: "white",
            }}
          >
            <MenuIcon />
          </IconButton>
        )}

        <Drawer
          variant={isMobile ? "temporary" : "permanent"}
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          sx={{
            marginTop: "64px",
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              border: "none",
            },
          }}
        >
          {/* <button className="btn btn-outline m-2" onClick={handleChangeSpecialty}>
            {specialty}
          </button> */}
          <Header mode={"left"} />
          <div className="border-b-2 mt-14 left-0 w-[200px] bg-white">
            <button
              className="btn w-[180px] btn-accent btn-sm btn-add-encounter ml-2 mr-2 mt-2 mb-[6px]"
              onClick={handleStart}
            >
              {/* <svg width="15px" height="20px" viewBox="0 0 406 567" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="logo-white-trans" transform="translate(-337.000000, -257.000000)">
                  <polygon id="Fill-53" points="0 1080 1080 1080 1080 0 0 0"></polygon>
                  <g id="Group-76" transform="translate(337.000000, 257.000000)">
                    <path d="M325.29,292.499 L271.949,292.499 L244.287,368.372 C243.011,371.87 239.715,374.22 235.995,374.287 C235.939,374.29 235.885,374.29 235.831,374.29 C232.174,374.29 228.873,372.075 227.493,368.677 L169.831,226.736 L148.012,286.581 C146.715,290.135 143.337,292.499 139.555,292.499 L79.915,292.499 C74.947,292.499 70.915,288.471 70.915,283.499 C70.915,278.527 74.947,274.499 79.915,274.499 L133.258,274.499 L160.92,198.626 C162.196,195.128 165.492,192.778 169.212,192.711 C169.268,192.708 169.322,192.708 169.376,192.708 C173.033,192.708 176.334,194.923 177.714,198.321 L235.376,340.262 L257.195,280.417 C258.492,276.863 261.87,274.499 265.652,274.499 L325.29,274.499 C330.26,274.499 334.29,278.527 334.29,283.499 C334.29,288.471 330.26,292.499 325.29,292.499 M351.103,0 L108.103,0 L0.103,108 L0.103,513 C0.103,542.823 24.281,567 54.103,567 L405.103,567 L405.103,54 C405.103,24.176 380.928,0 351.103,0" id="Fill-85" fill="#FFFFFF"></path>
                    <g id="Group-89" transform="translate(0.000000, 53.500000)">

                      <g id="Clip-88"></g>
                      <polygon id="Fill-87" fill="url(#linearGradient-3)" mask="url(#mask-2)" points="0.103 54.5 0.103 130.868 92.288 38.684 54.103 0.5"></polygon>
                    </g>
                    <path d="M0.1031,108 L54.1031,108 C83.9261,108 108.1031,83.824 108.1031,54 L108.1031,0 L0.1031,108 Z" id="Fill-90" fill="#DDDDDD"></path>
                  </g>
                </g>
              </g>
            </svg> */}
              + New Visit
            </button>
          </div>

          <div className="collapse border-b-2 border-cor collapse-arrow rounded-none">
            <input type="checkbox" defaultChecked onChange={(e) => {
              analyticsService.trackEvent(Analytics.Category.ENCOUNTER_LIST, !e.target.checked ? "Collapsed" : "Expanded", "", "", isMobile ? Analytics.ScreenName.ENCOUNTER_LIST : Analytics.ScreenName.MAIN);
            }} />
            <div className="collapse-title font-bold text-md">Past Visits</div>
            <div className={`collapse-content w-[200px]  max-h-[calc(100vh-300px)] overflow-x-hidden overflow-y-auto  ${(isMobile || isIphoneOrIpad) ? "mobile_ios" : "desktop"}`} style={{ minHeight: "10px", important: true }}>
            {drawerContent}
              <div className="block h-[300px]"></div>
            </div>
          </div>

          <div className="text-xs text-gray-500 p-2 w-[200px] fixed border-t-2 bottom-0 bg-white ">
            <ul className="menu">
              {/* <li>

                <a>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#2DD4BF" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                  </svg>
                  What's new!
                </a>
              </li>
              <div className="ml-10 text-xs text-gray-400">1.4.1 {region.toUpperCase()}</div> */}


              {/* <li>
                <a>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#2DD4BF" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  Settings
                </a>
              </li> */}
              <li>
                <a className="btn-nav-trial">
                  <b className="text-lg">Pro Trial</b>
                </a>
              </li>

              {isAlphaFlag() && (
                <li>
                  <a className="btn-nav-invite bold ">
                    🎁  <b>Invite friends</b>
                  </a>
                </li>
              )}
              {/* {isAlphaFlag() && (
              <li>
                <a className="btn-nav-integrations">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#2DD4BF" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
                  </svg>
                  Integrations
                </a>
              </li>

)} */}


              <li>
                <a className="btn-nav-templates" onClick={(e) => {
                  setDrawerOpen(false); setIsTemplateModalOpen(true); setIsPromptModalOpen(false);
                  if (isNewPromptFormat) {
                    analyticsService.trackEvent(Analytics.Category.TEMPLATE_WIZARD_JOSH, Analytics.Action.LAUNCHED, "", "", Analytics.ScreenName.ENCOUNTER_LIST);
                  }
                  else {
                    analyticsService.trackEvent(Analytics.Category.TEMPLATE_WIZARD, Analytics.Action.LAUNCHED, "", "", Analytics.ScreenName.ENCOUNTER_LIST);
                  }
                }}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#2DD4BF" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                  </svg>
                  Templates
                </a>
              </li>
              {/* <li>
                <a>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#2DD4BF" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                  </svg>
                  Contact us
                </a>
              </li> */}

              <li>
                <a className="btn-nav-help">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#2DD4BF" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                  </svg>
                  Help
                </a>
              </li>
              {/* <li>
                <a id="feedback" className="btn-nav-feedback feedback">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#2DD4BF" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                  </svg>

                  Feedback
                </a>
              </li> */}

            </ul>

          </div>
        </Drawer>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            height: "calc(100vh - 56px)",
            marginLeft: isMobile ? 0 : `${drawerOpen ? drawerWidth : 0}px`,
            transition: theme.transitions.create("margin", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }}
        >
          <Header
            onClickItem={(item) => {
              if (item.label === "My Profile") {
                setSpecialtyModalOpen(true);
              } else if (item.label === "Note Settings") {
                onNoteSettingsFromNote();
              } 
              else if (item.label === "Automation") {
                onDotPhraseModalOpen();
              }
              else if (item.label === "Log Out") {
                handleLogout();
              }
            }}
          />

          {/*           
          {patients.length === 0 && !isMobile && (
            <div className="flex ml-10 mt-16">
              <h1>
                👈 Click <b>+</b> to start
              </h1>
            </div>
          )}
          {patients.length === 0 && isMobile && (
            <div className="flex ml-2 mt-16">
              <h1>
                👆 Tap <b>+</b> inside menu to start
              </h1>
            </div>
          )} */}



          {selectedPatient ? (
            <PatientNote
              key={selectedPatient.id || selectedPatient.ds_id}
              prompts={prompts}
              patient={selectedPatient}
              onUpdate={updatePatientsList}
              onChange={handlePatentChanges}
              onNoteSettings={onNoteSettingsFromNote}
              handlePromptChange={handlePromptChange}
              ref={patientNoteRef}
              totalNumberOfPatients={patients.length}
            />
          ) : (
            <>
              <div className="block w-full mt-60 text-center">
                <p className={`text-2xl self-center ${isMobile ? "p-2" : "p-20"}`}>Click <a className="font-bold" onClick={(e) => { handleContinueStart(Analytics.ScreenName.MAIN, { quick_start: true }) }}>"Quick Start"</a> below to start<br />a patient encounter conversation and generate a note.</p>

                {microphoneAccessMessage && (
                  <div className="text-2xl m-3 p-4 border-spacing-1 bg-amber-400 block">
                    {microphoneAccessMessage}
                  </div>
                )}

                <p className="text-2xl self-center mt-10"> <button
                  className="btn btn-new-encounter-start-mic btn-warning"
                  onClick={(e) => { handleContinueStart(Analytics.ScreenName.MAIN, { quick_start: true }) }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z"
                    />
                  </svg>
                  Quick Start
                </button></p>
                {/* <div className="block text-xl opacity-75 mt-32 self-center cursor-pointer" onClick={(e)=>{
                  setIsTemplateModalOpen(true)
                }}>
                You can create templates now.<br/> Click <u>HERE</u> & let magic 🧙🪄🦄 begin!
                </div> */}
              </div>
            </>
          )}

          {isHubspotModalOpen && (
            <ModalHubspot closeModal={closeHubspotModal} />
          )}
          {isModalOpen && (
            <div className="modal modal-open">
              <div className={`modal-box ${!isMobile ? "ml-52" : ""} relative`}>
                {/* Close button */}
                <button
                  className="btn btn-sm btn-circle absolute right-2 top-2"
                  onClick={() => handleCancel()}
                >
                  ✕
                </button>

                {!continueStatus && (
                  <>
                    <h2 className="font-bold text-lg">Patient Information</h2>
                    <div className="py-4">
                      <label className="label">
                        <span className="label-text">Patient Name</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter patient name"
                        className="input input-bordered w-full txt-input-name"
                        value={patientName}
                        onChange={(e) => setPatientName(e.target.value)}
                      />

                      <label className="label mt-4">
                        <span className="label-text">EHR ID (Optional)</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter EHR ID"
                        className="input input-bordered w-full txt-input-ehrid"
                        value={ehrId}
                        onChange={(e) => setEhrId(e.target.value)}
                      />

                      <label className="label mt-4">
                        <span className="label-text">Patient History (Optional)</span>
                      </label>
                      <textarea
                        placeholder="Enter a brief patient history"
                        className="textarea textarea-bordered w-full txt-input-history"
                        value={patientHistory}
                        maxLength={maxHistoryLength}
                        onChange={(e) => setPatientHistory(e.target.value)}
                      />
                      <div className="text-sm text-gray-500">
                        {patientHistory.length}/{maxHistoryLength}
                      </div>

                      <label className="label mt-4">
                        <span className="label-text">Language</span>
                      </label>
                      <select
                        className="select select-bordered w-full"
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                      >
                        {languages.map((lang) => (
                          <option key={lang.value} value={lang.value}>
                            {lang.title}
                          </option>
                        ))}
                      </select>
                      {/* <label className="label cursor-pointer">
                        <span className="label-text">Dictation</span>
                        <input
                          type="checkbox"
                          className="toggle toggle-accent"
                          checked={isDictationEnabled}
                          onChange={(e) => setIsDictationEnabled(e.target.checked)}
                        />
                      </label> */}

                    </div>
                    <div className="modal-action">
                      <button
                        className="btn btn-new-encounter-start-mic btn-accent"
                        onClick={(e) => { handleContinueStart(Analytics.ScreenName.ENCOUNTER_PREP) }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z"
                          />
                        </svg>
                        Start Encounter
                      </button>
                      <button
                        className="btn"
                        onClick={(e) => { handleContinue(Analytics.ScreenName.ENCOUNTER_PREP) }}
                      >
                        Save
                      </button>
                    </div>
                  </>
                )}
                {continueStatus && (
                  <>
                    <div className="hn-ext-transcript-block-summary-progress">
                      <div></div>
                      <div></div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          {/* {isMobile && (
            <footer className="fixed bottom-0 left-0 right-0 bg-white border-t-2 p-4 flex justify-center space-x-4">
              <button className="btn btn-outline font-bold py-2 px-4" onClick={(e) => {
                handleContinueStart(Analytics.ScreenName.MAIN_FOOTER)
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z" />
</svg>
Start Visit
              </button>
              <button className="btn btn-outline font-bold py-2 px-4" onClick={(e) =>{
                analyticsService.trackScreen(Analytics.Category.ENCOUNTER_LIST, Analytics.ScreenName.MAIN_FOOTER);
                setDrawerOpen(true);
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                </svg>

                Notes
              </button>
            </footer>
          )} */}

          {hasChangesModalOpen && (
            <div className="modal modal-open">
              <div className={`modal-box ${!isMobile ? "ml-52" : ""}`}>
                <div className="py-0 -mt-5">
                  {hasChanges && (
                    <>
                      <h2>Please wait, saving in progress for</h2>
                      <h1 className="font-bold text-xlg">
                        {selectedPatient.name}
                      </h1>
                      <div className="hn-ext-transcript-block-summary-progress">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </>
                  )}
                  {!hasChanges && (
                    <>
                      <h2>Do you still want to continue?</h2>
                      <div className="modal-action">
                        <button
                          className="btn"
                          onClick={() => {
                            setAboutToSelectPatient(null);
                            setHasChangesModalOpen(false);
                          }}
                        >
                          Cancel
                        </button>

                        <button
                          className="btn btn-accent"
                          onClick={handleHasChangesContinue}
                        >
                          Continue
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {isSpecialtyModalOpen && (
            <SpecialtySelector
              specialtyInfo={specialty}
              onCancel={handleSpecialtyCancel}
              onContinue={handleSpecialtyContinue}
            />
          )}

          {isDeleteConfirmation && (
            <div className="modal modal-open">
              <div className={`modal-box ${!isMobile ? "ml-52" : ""}`}>
                <div className="py-0 -mt-5">
                  {isDeleting && (
                    <>
                      <h2>Please wait</h2>
                      <div className="hn-ext-transcript-block-summary-progress">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </>
                  )}
                  {!isDeleting && (
                    <>
                      {" "}
                      <h2>Are you sure you want to delete this encounter.</h2>
                      <div className="modal-action">
                        <button
                          className="btn btn-error btn-continue-delete"
                          onClick={handleDeleteEncounterConfirmation}
                        >
                          YES
                        </button>
                        <button
                          className="btn btn-cancel-delete"
                          onClick={() => {
                            setIsDeleteConfirmation(false);
                          }}
                        >
                          NO
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {isPromptModalOpen && !isNewPromptFormat && (
            <PromptConfiguration
              isOpen={isPromptModalOpen}
              onClose={() => {
                setIsPromptModalOpen(false);
              }}
              onCreateTemplateWizard={() => {
                setIsPromptModalOpen(false);

                setIsTemplateWizardOpen(false);
                analyticsService.trackEvent(Analytics.Category.TEMPLATE_WIZARD_JOSH, Analytics.Action.LAUNCHED, "", "", Analytics.ScreenName.TEMPLATE_WIZARD_JOSH);

                setIsTemplateWizardOpen(true);

              }}
              onSaveAndGenerate={async (newPromptObj) => {

                const prompts = await getMyPrompts()

                setIsPromptModalOpen(false);
                if (patientNoteRef.current)
                  patientNoteRef.current.regenerateNote(null, prompts);
              }}
            />
          )}

          {isPromptModalOpen && isNewPromptFormat && (
            <DocumentTemplateBuilder
              isOpen={isPromptModalOpen}
              onClose={() => {
                setIsPromptModalOpen(false);
              }}
              onSaveAndGenerate={async (newPromptObj) => {

                const prompts = await getMyPrompts()

                setIsPromptModalOpen(false);
                if (patientNoteRef.current)
                  patientNoteRef.current.regenerateNote(null, prompts);
              }}


              onCreateTemplateWizard={() => {
                setIsPromptModalOpen(false);

                setIsTemplateWizardOpen(false);
                analyticsService.trackEvent(Analytics.Category.TEMPLATE_WIZARD_JOSH, Analytics.Action.LAUNCHED, "", "", Analytics.ScreenName.TEMPLATE_WIZARD_JOSH);

                setIsTemplateWizardOpen(true);

              }}
            />
          )}

          {isTemplateWizardOpen && (
            <DocumentTemplateComponent
              isOpen={isTemplateWizardOpen}
              onUse={async (newPrompt) => {
                console.log("Template used:", newPrompt);
                setIsTemplateWizardOpen(false);
                // Handle the use action in the parent component

                const prompts = await fetchPrompts();
                await setPrompts(prompts);

                setIsTemplateWizardOpen(false);
                if (patientNoteRef.current)
                  patientNoteRef.current.regenerateNote(null, prompts);

              }}
              onCancel={() => {
                setIsTemplateWizardOpen(false);
                // Handle the cancel action in the parent component
              }}
            />

          )}

          {isTemplateModalOpen && (

            <DocumentTemplateBuilder isOpen={isTemplateModalOpen} onClose={() => {
              setIsTemplateModalOpen(false);
            }
            }
              onUse={async (newPrompt) => {
                console.log("Template used:", newPrompt);
                setIsTemplateModalOpen(false);
                // Handle the use action in the parent component

                const prompts = await getMyPrompts();

                setIsTemplateModalOpen(false);
                if (patientNoteRef.current)
                  patientNoteRef.current.regenerateNote(null, prompts);

              }}
              onSaveAndGenerate={async (newPromptObj) => {

                const prompts = await getMyPrompts();

                setIsTemplateModalOpen(false);
                if (patientNoteRef.current)
                  patientNoteRef.current.regenerateNote(null, prompts);
              }}
              onCreateTemplateWizard={() => {
                setIsTemplateModalOpen(false);

                setIsTemplateWizardOpen(false);
                analyticsService.trackEvent(Analytics.Category.TEMPLATE_WIZARD_JOSH, Analytics.Action.LAUNCHED, "", "", Analytics.ScreenName.TEMPLATE_WIZARD_JOSH);

                setIsTemplateWizardOpen(true);

              }}
            />
          )}

          {isPromptNotificationModalOpen && (
            <PromptNotificationModal
              isOpen={isPromptNotificationModalOpen}
              onClose={() => {
                setPromptNotificationModalOpen(false);
              }}
              items={sharedPrompts}
            />
          )}



        </Box>
      </Box>
      <AsyncMicrophonePermissionModal ref={microphonePermissionModalRef} />

      <DotPhraseModal
              open={dotPhraseModalOpen}
              onClose={() => {
                setDotPhraseModalOpen(false);
              }}
            />
    </div>
  );
}

export default Sentry.withProfiler(App);
