import React, { useState, useCallback } from 'react';
import { AudioLines, Mic } from 'lucide-react';
import MicrophoneSlashIcon from './MicrophoneSlashIcon.jsx'; // Adjust this import path as necessary

// Generic and flexible data structure
const formData = {
  title: "Recipe Suggestion Form",
  steps: [
    {
      id: 1,
      question: "What ingredients would you like to use?",
      type: "multiSelect",
      options: [
        { id: 1, name: "Tomato", category: "Vegetable" },
        { id: 2, name: "Cheese", category: "Dairy" },
        { id: 3, name: "Chicken", category: "Protein" },
        { id: 4, name: "Beef", category: "Protein" },
        { id: 5, name: "Pasta", category: "Grain" },
        { id: 6, name: "Rice", category: "Grain" },
        { id: 7, name: "Lettuce", category: "Vegetable" },
        { id: 8, name: "Onion", category: "Vegetable" },
        { id: 9, name: "Garlic", category: "Vegetable" },
        { id: 10, name: "Carrot", category: "Vegetable" },
        // ... add more ingredients as needed
      ]
    },
    {
      id: 2,
      question: "What cooking style would you prefer?",
      type: "singleSelect",
      options: [
        { 
          id: 1, 
          name: "Stir Fry", 
          description: "Quick cooking in a wok or pan with high heat",
          instructions: "Heat oil in a wok or large skillet.",
          method: "Add ingredients to the skillet in order of cooking time, starting with those that take longest to cook."
        },
        { 
          id: 2, 
          name: "Bake", 
          description: "Cooking in an oven, often for longer periods",
          instructions: "Preheat your oven to 375°F (190°C).",
          method: "Combine ingredients in a baking dish."
        },
        { 
          id: 3, 
          name: "Pan Fry", 
          description: "Cooking in a pan with a small amount of oil",
          instructions: "Heat oil in a frying pan over medium heat.",
          method: "Add ingredients to the pan, cooking each side until golden brown."
        },
        // ... add more cooking styles as needed
      ]
    },
    {
      id: 3,
      question: "Any dietary restrictions?",
      type: "multiSelect",
      options: [
        { id: 1, name: "Vegetarian" },
        { id: 2, name: "Vegan" },
        { id: 3, name: "Gluten-free" },
        { id: 4, name: "Dairy-free" },
        { id: 5, name: "Low-carb" },
        // ... add more dietary restrictions as needed
      ]
    }
  ],
  responseTemplate: {
    intro: "Great choice! I can suggest a delicious {dietaryRestrictions} recipe for {ingredients} using the {cookingStyle} method. Here's a simple recipe idea:",
    steps: [
      "Prepare your {ingredients}.",
      "{cookingInstructions}",
      "{cookingMethod}",
      "Cook until all ingredients are properly prepared.",
      "Season with salt, pepper, and any additional spices to taste.",
      "Serve and enjoy your {cookingStyle} dish!"
    ],
    outro: "Would you like to try this recipe?"
  }
};

const OptionSelector = React.memo(({ option, isSelected, onChange, type }) => (
  <label className="inline-flex items-center m-2">
    <input
      type={type === "multiSelect" ? "checkbox" : "radio"}
      className={type === "multiSelect" ? "form-checkbox h-5 w-5 text-blue-600" : "form-radio h-5 w-5 text-blue-600"}
      checked={isSelected}
      onChange={() => onChange(option.id)}
    />
    <span className="ml-2 text-gray-700">{option.name}</span>
  </label>
));

const ProgressBar = ({ currentStep, totalSteps }) => (
  <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
    <div 
      className="bg-blue-600 h-2.5 rounded-full" 
      style={{ width: `${(currentStep / totalSteps) * 100}%` }}
    ></div>
  </div>
);

const IntakeContent = () => {
  const [selections, setSelections] = useState({});
  const [result, setResult] = useState('');
  const [isVoiceMode, setIsVoiceMode] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [recognizedText, setRecognizedText] = useState('');

  const handleSelectionChange = useCallback((stepId, optionId) => {
    setSelections(prevSelections => {
      const step = formData.steps.find(s => s.id === stepId);
      if (step.type === "multiSelect") {
        const current = prevSelections[stepId] || [];
        return {
          ...prevSelections,
          [stepId]: current.includes(optionId)
            ? current.filter(id => id !== optionId)
            : [...current, optionId]
        };
      } else {
        return { ...prevSelections, [stepId]: [optionId] };
      }
    });
  }, []);

  const generateResponse = useCallback(() => {
    const getSelectedOptions = (stepId) => {
      const step = formData.steps.find(s => s.id === stepId);
      return (selections[stepId] || [])
        .map(id => step.options.find(opt => opt.id === id))
        .filter(Boolean);
    };

    const selectedIngredients = getSelectedOptions(1).map(opt => opt.name).join(', ');
    const selectedCookingStyle = getSelectedOptions(2)[0];
    const dietaryRestrictions = getSelectedOptions(3).map(opt => opt.name).join(', ');

    const replacePlaceholders = (text) => {
      return text
        .replace('{ingredients}', selectedIngredients)
        .replace('{cookingStyle}', selectedCookingStyle?.name || '')
        .replace('{dietaryRestrictions}', dietaryRestrictions ? `(${dietaryRestrictions})` : '')
        .replace('{cookingInstructions}', selectedCookingStyle?.instructions || '')
        .replace('{cookingMethod}', selectedCookingStyle?.method || '');
    };

    const response = [
      replacePlaceholders(formData.responseTemplate.intro),
      ...formData.responseTemplate.steps.map(replacePlaceholders),
      replacePlaceholders(formData.responseTemplate.outro)
    ].join('\n\n');

    setResult(response);
    setCurrentStep(formData.steps.length + 1); // Move to results step
  }, [selections]);

  const toggleVoiceMode = useCallback(() => {
    setIsVoiceMode(prev => !prev);
    setCurrentStep(1);
    setResult('');
    setRecognizedText('');
    setSelections({});
  }, []);

  const toggleListening = useCallback(() => {
    if (isListening) {
      setIsListening(false);
      // Simulate voice recognition when stopping
      const currentStepData = formData.steps[currentStep - 1];
      if (currentStepData.type === "multiSelect") {
        const recognizedOptions = currentStepData.options
          .filter(() => Math.random() > 0.7)
          .map(option => option.id);
        setSelections(prev => ({ ...prev, [currentStepData.id]: recognizedOptions }));
        const recognizedNames = recognizedOptions.map(id => 
          currentStepData.options.find(option => option.id === id).name
        );
        setRecognizedText(`Recognized: ${recognizedNames.join(', ')}`);
      } else {
        const recognizedOption = currentStepData.options[Math.floor(Math.random() * currentStepData.options.length)];
        setSelections(prev => ({ ...prev, [currentStepData.id]: [recognizedOption.id] }));
        setRecognizedText(`Recognized: ${recognizedOption.name}`);
      }
      if (currentStep < formData.steps.length) {
        setCurrentStep(prev => prev + 1);
      } else {
        generateResponse();
      }
    } else {
      setIsListening(true);
      setRecognizedText('');
    }
  }, [isListening, currentStep, generateResponse]);

  const handleNext = () => {
    if (currentStep < formData.steps.length) {
      setCurrentStep(prev => prev + 1);
    } else {
      generateResponse();
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(prev => prev - 1);
    }
  };

  const currentStepData = formData.steps[currentStep - 1];

  return (
    <div className="max-w-2xl mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">{formData.title}</h2>
      
      <div className="mb-4 flex justify-between items-center">
        <span className="text-gray-700">Voice Mode:</span>
        <button
          onClick={toggleVoiceMode}
          className={`p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${
            isVoiceMode ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-600'
          }`}
        >
          {isVoiceMode ? <AudioLines className="h-6 w-6" /> : <MicrophoneSlashIcon className="h-6 w-6" />}
        </button>
      </div>

      <ProgressBar currentStep={currentStep} totalSteps={formData.steps.length + 1} />

      {currentStep <= formData.steps.length && (
        <h3 className="text-lg font-semibold mb-2">{currentStepData.question}</h3>
      )}

      {isVoiceMode ? (
        <div className="mb-4 text-center">
          <button
            onClick={toggleListening}
            className={`p-4 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${
              isListening ? 'bg-red-500' : 'bg-blue-500 hover:bg-blue-600'
            } text-white`}
          >
            <Mic className="h-6 w-6" />
          </button>
          <p className="mt-2 text-sm text-gray-600">
            {isListening ? "Press to stop" : "Press mic to say and stop when finished"}
          </p>
          {recognizedText && (
            <p className="mt-4 p-2 bg-gray-100 rounded">{recognizedText}</p>
          )}
        </div>
      ) : (
        <div>
          {currentStep <= formData.steps.length && (
            <div className="mb-4">
              <div className="max-h-60 overflow-y-auto border border-gray-300 rounded p-2">
                {currentStepData.options.map((option) => (
                  <OptionSelector
                    key={option.id}
                    option={option}
                    isSelected={(selections[currentStepData.id] || []).includes(option.id)}
                    onChange={(optionId) => handleSelectionChange(currentStepData.id, optionId)}
                    type={currentStepData.type}
                  />
                ))}
              </div>
            </div>
          )}
          
          <div className="flex justify-between">
            {currentStep > 1 && (
              <button
                onClick={handleBack}
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Back
              </button>
            )}
            {currentStep <= formData.steps.length && (
              <button
                onClick={handleNext}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {currentStep === formData.steps.length ? 'Get Suggestion' : 'Next'}
              </button>
            )}
          </div>
        </div>
      )}

      {result && (
        <div className="mt-4 p-4 border rounded bg-gray-100">
          <h3 className="font-bold mb-2">Suggestion:</h3>
          <p className="whitespace-pre-line">{result}</p>
        </div>
      )}
    </div>
  );
};

export default IntakeContent;