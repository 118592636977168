import React from 'react';
import { MicrophoneIcon } from '@heroicons/react/24/solid';

const MicrophoneSlashIcon = ({ className, ...props }) => {
  return (
    <span className="relative inline-block">
      <MicrophoneIcon className={className} {...props} />
      <span
        className="absolute inset-0 flex items-center justify-center"
        style={{ transform: 'rotate(-45deg)' }}
      >
        <div className="w-[2px] h-full bg-current rounded-full" />
      </span>
    </span>
  );
};

export default MicrophoneSlashIcon;