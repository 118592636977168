import { createTheme, ThemeProvider } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({

  typography: {
    h1: {
      fontSize: '25px', // Set the font size for h1
      fontWeight: 'bold', // Set the font weight for h1
    },
  },
});

export default theme;
