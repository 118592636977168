
// Analytics "enum" objects
import mixpanel from "mixpanel-browser";
//https://docs.google.com/document/d/17B_JooDwEimHsCu4HZ0Ye3joMS4HedoBeZCnWx1DgRg/edit


export const initGoogleAnalytics = (callback) => {
  var script1 = document.createElement("script");
  script1.src = "https://www.googletagmanager.com/gtag/js?id=AW-16455863626";
  script1.async = true;

  // Add an event listener for when the first script loads
  script1.onload = function () {
    console.log("Google Analytics script has been loaded.");
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "AW-16455863626");
    if (callback) callback();
  };

  document.head.appendChild(script1);
};
function detectHN() {
  return navigator.userAgent.toLowerCase().includes('hnMobile');
}

export const Analytics = {
  Platform: {
    BACKGROUND: "Background", // Background service
    WEB: "Web",
    NATIVE: "Native", // IOS, Android
    CHROME_EXT: "Chrome Ext",
  },
  Category: {
    USER: "User",
    ENCOUNTER: "Encounter",
    ENCOUNTER_LIST: "Encounter List",
    PATIENT: "Patient",
    RECORDING: "Recording",
    NOTE: "Note",
    PROMPT: "Prompt",
    TEMPLATE: "Template",
    TRANSCRIPT: "Transcript",
    PROFILE: "Profile",
    ERROR: "Error",
    LANDING_PAGE: "Landing Page",
    MICROPHONE: "Microphone",
    TRIAL: "Trial",
    TEMPLATE_WIZARD: "Template Wizard",
    TEMPLATE_WIZARD_JOSH: "Template Builder",
    PERMISSIONS: "Permissions",
  },

  Action: {
    GRANTED: "Granted",
    DENIED: "Denied",
    VISITED: "Visited",
    RETRY: "Retry",
    VIEWED: "Viewed",
    CANCELLED: "Cancelled",
    DELETED: "Deleted",
    STARTED: "Started",
    CREATED: "Created",
    SAVED: "Saved",
    UPLOADED: "Uploaded",
    PAUSED: "Paused",
    STOPPED: "Stopped",
    RESUMED: "Resumed",
    SIGNED_UP: "Signed Up",
    SIGNED_IN: "Signed In",
    SIGNED_OUT: "Signed Out",
    PREP: "Prep",
    EXECUTED: "Executed",
    REFERRED: "Referred",
    SELECTED: "Selected",
    REGENERATED: "Regenerated",
    COPIED: "Copied",
    CHANGED: "Changed",
    RETRIEVED: "Retrieved",
    PICKED: "Picked",
    USED: "Used",
    LAUNCHED: "Launched",
    EXECUTED: "Executed",
    FINISHED: "Finished",
  },

  ScreenName: {
    MAIN: "Main",
    MAIN_FOOTER: "Main Footer",
    TRANSCRIPT: "Transcript",
    CUSTOM_PROMPT: "Custom Prompt",
    NOTE: "Note",
    ENCOUNTER_LIST: "Encounter List",
    ENCOUNTER_PREP: "Encounter Prep",
    TEMPLATE: "Template",
    TEMPLATE_PROMPT: "Template Prompt",
    TEMPLATE_PROMPT_NOTIFICATION: "Template Prompt Notification",
    TEMPLATE_PROMPT_SHARE: "Template Prompt Share",
    PROFILE: "Profile",
    PATIENT: "Patient Profile",
    TEMPLATE_WIZARD: "Template Wizard",
    TEMPLATE_WIZARD_STEP: "Template Wizard Step",
    TEMPLATE_WIZARD_JOSH: "Template Builder"
  },
};

// Freeze the objects to make them immutable (similar to enums)
Object.freeze(Analytics.Category);
Object.freeze(Analytics.Action);
Object.freeze(Analytics.ScreenName);
Object.freeze(Analytics.Platform);

// AnalyticsService interface
const AnalyticsService = {
  setup: function (options) {},
  startWithPlatforms: function (platform) {},
  trackScreen: function (name) {},
  trackEvent: function (
    category,
    action,
    label,
    value,
    screenName,
    nonInteractive
  ) {},
  assignUniqueId: function (id) {},
};

// StandardAnalyticsService class
export class StandardAnalyticsService {
  static shared = new StandardAnalyticsService(detectHN() ? Analytics.Platform.NATIVE : Analytics.Platform.WEB); // Singleton pattern

  constructor(platform) {
    this.platform = platform;
  }

  //id, email, name, specialty
  setup(options,isSignin) {
    // Mixpanel
    mixpanel.init("4fa008415adeacf9dd5c759cd9e82e93", {
      debug: false,
      track_pageview: true,
      persistence: "localStorage",
    });
    

    mixpanel.identify(options.email || options.id);

    mixpanel.people.set({
      $name: options.name,
      $email: options.email || "",
      organization_name: options.specialty ? options.specialty.organization : "",
      ehr: options.specialty ? options.specialty.ehr : "",
    });

    if(options.id && isSignin){
      mixpanel.track(Analytics.Category.USER + ' '+ Analytics.Action.SIGNED_IN, {
        source: options.source,
      });      
    }
    else{
      mixpanel.track(Analytics.Category.LANDING_PAGE + ' '+ Analytics.Action.VISITED, {
        source: options.source,
      });
  
    }


    // Pendo
    if (!("localhost" == window.location.hostname)) {
      (function (apiKey) {
        (function (p, e, n, d, o) {
          var v, w, x, y, z;
          o = p[d] = p[d] || {};
          o._q = o._q || [];
          v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
          for (w = 0, x = v.length; w < x; ++w)
            (function (m) {
              o[m] =
                o[m] ||
                function () {
                  o._q[m === v[0] ? "unshift" : "push"](
                    [m].concat([].slice.call(arguments, 0))
                  );
                };
            })(v[w]);
          y = e.createElement(n);
          y.async = !0;
          y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
          y.onload = function () {
            console.log("Pendo script has been loaded.", options.email);
            // Add any additional code here that you want to run after the script is loaded

            pendo.initialize({
              visitor: {
                id: options.email || options.id, // Required if user is logged in
                email: options.email,
                full_name: options.name,
                specialty: options.specialty ? options.specialty.specialty : "",
              },
              account: {
                id: options.email ? options.email.split("@")[1] : "unknown",
                name: options.specialty ? options.specialty.organization : "",
                ehr: options.specialty ? options.specialty.ehr : "",
              },
            });
          };

          z = e.getElementsByTagName(n)[0];
          z.parentNode.insertBefore(y, z);
        })(window, document, "script", "pendo");
      })("561fc603-ebcb-436c-6fdd-474d95501787");
    }
  }

  trackScreen(category, screenName) {
    // Mixpanel
    try {
      console.log("[Analytics] Track Screen: "+category + " " + Analytics.Action.VIEWED+ " ", screenName);
      if (!("localhost" == window.location.hostname)) {
        mixpanel.track(category + " " + Analytics.Action.VIEWED, {
          category: category,
          action: Analytics.Action.VIEWED,
          platform: Analytics.Platform.WEB,
          screenName: screenName,
          nonInteractive: false,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  trackEvent(category, action, label, value, screenName, additionalParams) {
    try {
        let params = {
          category: category,
          action: action,
          platform: Analytics.Platform.WEB,
          label: label,
          value: value,
          screenName: screenName,
          nonInteractive: false,
        };


        if (category === Analytics.Category.ERROR) {
          params.object = label;
          params.error = value;
        }

        if(category === Analytics.Category.NOTE && action === Analytics.Action.CREATED){
          params.template_name = value;

        }

        if(category === Analytics.Category.ENCOUNTER && action === Analytics.Action.STARTED){
          params.language = value;
          if(label){
            params.dictation = true
          }
        }
        if(additionalParams){
          for (const [key, value] of Object.entries(additionalParams)) {
            params[key] = value;
          }
        }


        console.log("[Analytics] Track Action: "+category + " " + action+ " ", params);
        if (!("localhost" == window.location.hostname)) {
          mixpanel.track(category + " " + action, params);
        }
      } catch (e) {
        console.log(e);
      }
  }
}
