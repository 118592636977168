import React, { useState, useCallback, forwardRef, useImperativeHandle, useEffect } from 'react';
import {
  Analytics,
  StandardAnalyticsService,
} from "./analytics"; // Adjust this import path as necessary

const AsyncPatientNameModal = forwardRef((props, ref) => {
  const analyticsService = StandardAnalyticsService.shared;
  const [isOpen, setIsOpen] = useState(false);
  const [newName, setNewName] = useState("");
  const [modalConfig, setModalConfig] = useState({
    title: "Who is the patient for this visit?",
    subtitle: "You can resume the session at any time."
  });
  const [resolveRef, setResolveRef] = useState(null);

  useImperativeHandle(ref, () => ({
    openModal: (config = {}) => {
      setModalConfig({ ...modalConfig, ...config });
      setIsOpen(true);
      return new Promise((resolve) => {
        setResolveRef(() => resolve);
      });
    }
  }));

  const handleClose = useCallback((value) => {
    setIsOpen(false);

    if(value === null) {
      analyticsService.trackEvent(
        Analytics.Category.PATIENT,
        Analytics.Action.CANCELLED,
        "",
        "",
        Analytics.ScreenName.MAIN);
    }
    else
    {
      analyticsService.trackEvent(
        Analytics.Category.PATIENT,
        Analytics.Action.SAVED,
        "",
        "",
        Analytics.ScreenName.MAIN);
    }

    if (resolveRef) {
      resolveRef(value);
    }
    setNewName(""); // Reset the input field
  }, [resolveRef]);

  useEffect(() => {
    if (isOpen) {
      analyticsService.trackScreen(Analytics.Category.ENCOUNTER,Analytics.ScreenName.PATIENT);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={`modal modal-open ${isOpen ? '' : 'hidden'}`}>
      <div className="modal-box relative z-10">
        <h3 className="font-bold text-lg">{modalConfig.title}</h3>
        {modalConfig.subtitle && <p className="py-2">{modalConfig.subtitle}</p>}
        <div className="form-control mt-4">
          <input
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            className="input input-bordered w-full"
            placeholder="Patient Name"
          />
        </div>
        <div className="modal-action">
          <button className="btn btn-accent" onClick={() => handleClose(newName)}>Confirm Name</button>
          <button className="btn" onClick={() => handleClose(null)}>Skip</button>
        </div>
      </div>
      <label className="modal-backdrop bg-gray-800 bg-opacity-50 backdrop-blur-sm" onClick={() => handleClose(null)}></label>
    </div>
  );
});

export default AsyncPatientNameModal;