export const promptAutomation = function (inputHTMLPrompt,matchedPrompts) {
    //match prompts with sections 
    if(matchedPrompts.length === 0){
        return inputHTMLPrompt
    }

    let additionalInstructions = '<instructions>'
    matchedPrompts.forEach((prompt) => {
        additionalInstructions += `In "${prompt.title}" section, include ${prompt.prompt} and update from relevant transcript. If section doesn't exist add the section\n`

    });
    additionalInstructions += '</instructions>'


    return inputHTMLPrompt + additionalInstructions
}