import React, { useState, useEffect, useRef } from 'react';
import db from './db';
import {
    Analytics,
    StandardAnalyticsService,
  } from "./analytics"; // Adjust this import path as necessary

const AUTH_URL = import.meta.env.VITE_AUTH_URL;
const LOGIN_URL = import.meta.env.VITE_LOGIN_URL;

const handleLogout = async () => {
    // Add your logout logic here
    console.log('User logged out');
    document.cookie = 'authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'info=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    await db.delete();
    window.location.href = LOGIN_URL;
};

const ProfileMenu = ({ onItemClick }) => {
    const analyticsService = StandardAnalyticsService.shared;

    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    const menuItems = [
        { label: 'My Profile', href: '#' },
    ];

    menuItems.push({ label: 'Note Settings', href: '#' },);
    menuItems.push({ label: 'Automation', href: '#' });

    menuItems.push({ label: 'Log Out', href: '#' });

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleOnClickItem = (item) => {
        console.log(item);
        setIsOpen(false);
        if (onItemClick) {
            onItemClick(item);
        }
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };


    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <div className="relative inline-block text-left" ref={menuRef}>
            <div>
                <button
                    className="btn btn-sm btn-circle btn-accent absolute -top-6 right-1 btn-profile bg-transparent"
                    onClick={toggleMenu}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                    </svg>
                </button>
            </div>

            {isOpen && (
                <div
                    className="origin-top-right absolute right-0 mt-4 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 cursor-pointer"
                >
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {menuItems.map((item, index) =>
                            item.isButton ? (
                                <form method="POST" action="#" key={index}>
                                    <button
                                        type="submit"
                                        className={`block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 btn-copy-note-item-${item.label.toLowerCase()}`}
                                        role="menuitem"
                                        onClick={() => handleOnClickItem(item)}
                                    >
                                        {item.label}
                                    </button>
                                </form>
                            ) : (
                                <div
                                    href={item.href}
                                    className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 btn-menu-item-${item.label.toLowerCase().replace(' ', '-')}}`}
                                    role="menuitem"
                                    key={index}
                                    onClick={() => handleOnClickItem(item)}
                                >
                                    {item.label}
                                </div>
                            )
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProfileMenu;
