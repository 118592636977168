import React, { useState, useEffect, useRef } from 'react';
import { getKeys, logError } from './db';
import {
  Analytics,
  StandardAnalyticsService,
} from "./analytics"; // Adjust this import path as necessary
import { connectToDeepgram, uploadAudio } from "./deepgramws";


function isIphoneOrIpad() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Check for iPhone or iPad in the user agent string
  return /iPad|iPhone|Android|iPod/.test(userAgent) && !window.MSStream;
}


let isListening = false;
let deepgram = undefined;
let connection = undefined;
let globalStream = null; // A place to store the stream globally
let globalMicrophone = null; // A place to store the MediaRecorder globally
let API_DEEPGRAM_KEY = undefined;




const EditableNoteContent = ({ isMobile, noteSessionId, totalNumberOfPatients, data, onChange }) => {
  const analyticsService = StandardAnalyticsService.shared;
  const [content, setContent] = useState(data);
  const [copyStatus, setCopyStatus] = useState({});
  const [ratingStatus, setRatingStatus] = useState({});
  const [isDismissDesktop, setIsDismissDesktop] = useState(false);
  const activeMicSection = useRef(null);
  const [hoveredSection, setHoveredSection] = useState(null);
  const isIOS = isIphoneOrIpad(); // iphone doesn't support realtime deepgram

  const [audioStream, setAudioStream] = useState(null);
  const contentRefs = useRef({});

  // Add a new CSS class for the pulsating effect
  const pulsatingStyles = `
    @keyframes pulsate {
      0% {
        transform: scale(1);
        opacity: 1;
      }
      50% {
        transform: scale(1.1);
        opacity: 0.7;
      }
      100% {
        transform: scale(1);
        opacity: 1;
      }
    }
    .pulsating {
      animation: pulsate 1.5s ease-out infinite;
    }
  `;


  useEffect(() => {
    const initializeKeys = async () => {
      let existingKeyData = await getKeys();

      if (!existingKeyData) {
        existingKeyData = await getAuth();
      }

      API_DEEPGRAM_KEY = existingKeyData.audio;


    };

    if (!API_DEEPGRAM_KEY) {
      initializeKeys();
    } else {

    }
  }, []);

  useEffect(() => {
    setContent(data);
  }, [data]);

  const handleInput = (header, event) => {
    const newContent = {
      ...content,
      [header]: event.target.innerHTML,
    };

    if (onChange) {
      onChange(newContent);
    }
  };

  const handleCopy = (header) => {
    const text = contentRefs.current[header].innerText;

    analyticsService.trackEvent(
      Analytics.Category.NOTE,
      Analytics.Action.COPIED,
      header,
      "",
      Analytics.ScreenName.NOTE);

    navigator.clipboard.writeText(text).then(() => {
      setCopyStatus((prevState) => ({
        ...prevState,
        [header]: 'Copied!',
      }));
      setTimeout(() => {
        setCopyStatus((prevState) => ({
          ...prevState,
          [header]: '',
        }));
      }, 1000);
    });
  };


  const toggleMic = (header) => {
    if (activeMicSection.current === header) {
      activeMicSection.current = null;
      console.log(`Microphone turned off for ${header}`);
      return;
    }

    if (activeMicSection.current) {
      console.log(`Microphone turned off for ${activeMicSection.current}`);
    }

    activeMicSection.current = header;
    console.log(`Microphone turned on for ${header}`);

    setTimeout(() => {
      const element = contentRefs.current[header];
      if (element) {
        // Ensure there's content to append to
        if (!element.innerHTML.trim()) {
          element.innerHTML = '<br>';
        } else if (!element.innerHTML.endsWith('<br>')) {
          element.innerHTML += '<br><br>';
        }

        // Set focus to the element
        element.focus();

        // Move cursor to the end
        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(element);
        range.collapse(false);  // false means collapse to end
        selection.removeAllRanges();
        selection.addRange(range);

        // Trigger onChange to keep React state in sync
        if (onChange) {
          const updatedContent = {
            ...content,
            [header]: element.innerHTML
          };
          onChange(updatedContent);
        }
      }
    },200)


    if (!isListening) {
      openMic(function () {
        console.log('Error accessing');
      });
    }
  };
  const insertText = (text) => {
    /* Trigger Words
      period	.
      comma	,
      colon	:
      question mark	?
      exclamation mark	!
      new line	<\n>
      new paragraph	<\n\n>
      dash  -
    
    */
   console.log('insertText', text);
      if (activeMicSection.current && text.trim() !== '') {
        const element = contentRefs.current[activeMicSection.current];
        const selection = window.getSelection();
      
        if (selection.rangeCount > 0 && element) {
          const range = selection.getRangeAt(0);
          const preCaretRange = range.cloneRange();
          preCaretRange.selectNodeContents(element);
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          const preCaretText = preCaretRange.toString();
      
          // Check if there's already a space before the cursor
          const needsSpace = preCaretText.length > 0 && !preCaretText.endsWith(' ') && !text.startsWith(' ');
      
          // Split the text by "<\n\n>" and "<\n>" to handle different types of newlines
          let textParts = text.split(/(<\\n\\n>|<\\n>)/);
      
          // Insert each part of the text
          textParts.forEach((part, index) => {
            if (part === '<\\n\\n>') {
              range.insertNode(document.createElement('br'));
              range.insertNode(document.createElement('br'));
            } else if (part === '<\\n>') {
              range.insertNode(document.createElement('br'));
            } else {
              // Prepare the text to insert
              let textToInsert = index === 0 && needsSpace ? ' ' + part : part;
      
              // Replace the word "dash" (case-insensitive) with a hyphen
              textToInsert = textToInsert.replace(/\bdash\b/gi, '-');
      
              // Insert the text while preserving punctuation
              const textNodes = textToInsert.split(/([.,!?;:])/).filter(Boolean);
              textNodes.forEach(node => {
                range.insertNode(document.createTextNode(node));
              });
            }
      
            range.collapse(false);
          });
      
          selection.removeAllRanges();
          selection.addRange(range);
      
          // Trigger onChange callback
          if (onChange) {
            onChange({
              ...content,
              [activeMicSection.current]: element.innerHTML
            });
          }
        }
      }
  };

  function openMic(errorAccess) {
    if (isListening) {
      return;
    }

    let handleStream = () => {
      // Call open microphone
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {

          setAudioStream(stream);
          const options = { mimeType: "audio/webm; codecs=opus" };
          if (!MediaRecorder.isTypeSupported(options.mimeType)) {
            const types = [
              "audio/webm;codecs=opus",
              "audio/webm;codecs=vorbis",
              "audio/webm",
              "audio/ogg",
              "audio/ogg;codecs=opus",
              "audio/ogg;codecs=vorbis",
              "audio/wav",
              "audio/mp4;codecs=mp4a",
              "audio/mp4;codecs=avc1",
              "audio/mp4",
            ];
            for (let i = 0; i < types.length; i++) {
              if (MediaRecorder.isTypeSupported(types[i])) {
                options.mimeType = types[i];
                mimeType = types[i];
                break;
              }
            }
          }

          globalMicrophone = new MediaRecorder(stream, options);
          globalStream = stream; // Store the stream globally
          globalMicrophone.start(500);
          globalMicrophone.onstart = () => {

            isListening = true;

          };
          globalMicrophone.onstop = () => {
            isListening = false;
          };
          globalMicrophone.ondataavailable = (e) => {

            if (connection && connection.readyState === WebSocket.OPEN) {
              if (connection) {
                connection.send(e.data);
              }
            }
          };
        })
        .catch((error) => {
          logError("Error accessing the microphone:", error);

          isListening = false;
        });
    };

    if (!isListening) {
      connectToDeepgram(
        API_DEEPGRAM_KEY,
        'en',
        true, // dictation
        (ws) => {
          connection = ws;
          isListening = true;
          handleStream();
        },
        () => {
          isListening = false;
          connection = undefined;

        },
        (text) => {
          //text is in progress
        },
        (text) => {
          //text is final
          insertText(text);

        }
      );
    }
  }

  return (
    <div className="p-1 pb-28 -mt-4 txt-input-soap-note editable overflow-auto max-h-[calc(100vh-115px)]">
      <style>{pulsatingStyles}</style>
      {totalNumberOfPatients <= 2 && (
        <div className="block mt-2 mb-2 relative rounded-md bg-amber-100 border-solid border-2 drop-shadow-md border-black p-4">
          If you don't like this note output you can click on the <b>Improve Note</b> above to pick a different template.
          {isMobile && (<div className='block absolute right-1 top-1 text-2xl'>👆</div>)}
        </div>
      )}

      {Object.keys(content).map((header) => (
        <div
          key={header}
          className="mb-4 group relative"
          onMouseEnter={() => setHoveredSection(header)}
          onMouseLeave={() => setHoveredSection(null)}
        >
          <div className="flex items-center h-10">
            <h3 className="">{header}</h3>
            <button
              className={`btn btn-copy-${header} btn-outline border-transparent btn-circle btn-sm ml-1 opacity-45`}
              onClick={() => handleCopy(header)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 1 20 22"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-4 mr-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184"
                />
              </svg>
            </button>
            {((hoveredSection === header || activeMicSection.current === header) && !isMobile && !isIOS) && (
              <button
              className={`btn btn-mic-${header} btn-outline border-transparent btn-circle btn-sm ml-1 ${
                activeMicSection.current === header 
                  ? 'bg-red-500 text-white pulsating' 
                  : 'opacity-45'
              }`}
              onClick={() => toggleMic(header)}
            >
              {activeMicSection.current === header ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path>
                  <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                  <line x1="12" y1="19" x2="12" y2="23"></line>
                  <line x1="8" y1="23" x2="16" y2="23"></line>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path>
                  <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                  <line x1="12" y1="19" x2="12" y2="23"></line>
                  <line x1="8" y1="23" x2="16" y2="23"></line>
                </svg>
              )}
            </button>
            )}

            {copyStatus[header] && (
              <span className='text-sm ml-2 text-gray-400'>Copied!</span>
            )}
            {ratingStatus[header] && (
              <span className='text-sm ml-2 text-gray-400'>{ratingStatus[header]}</span>
            )}
          </div>
          <div
            className="w-full p-1 -mt-1 whitespace-pre-line	block"
            contentEditable="true"
            ref={(el) => (contentRefs.current[header] = el)}
            onInput={(e) => handleInput(header, e)}
            dangerouslySetInnerHTML={{ __html: content[header] }}
            suppressContentEditableWarning={true}
          ></div>
        </div>
      ))}

      {isMobile && !isDismissDesktop && (
        <div className="block mt-2 mb-2 rounded-md bg-amber-100 border-solid border-2 drop-shadow-md border-black p-4">
          <b className='text-xl'>Head to your computer to edit or copy this note to EHR.</b> <br />At <b className='text-cyan-600'>listen.healthnote.com</b> you can view additional transcript, context, make changes, and copy or send to your EHR.
        </div>
      )}
    </div>
  );
};

export default EditableNoteContent;