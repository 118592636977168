import { hide } from "@intercom/messenger-js-sdk";
import {
    promiseRunTextLLM,
    promptTemplates,
    htmlToText,
} from "./db";

const titleDelimiter = '::'; //simple text delimiter

export async function generatePromptFromText(text) {
  let prompt = await promiseRunTextLLM(
    "Give me a prompt template in a text block for:'" +
      text +
      "' here is an example example of a prompt for generating a SOAP template:\n<instruction>Output HTML code block. Do not use pronouns but use Pt instead. </instruction>. Use H3 tags for titles.Summarize it in a clinical SOAP NOTE from conversation translate to english. Use medical abbreviation and medical terminology. Include every comprehensive detail from conversation. Be as detailed as possible. Include all mentions of medications, symptoms, and other relevant information.If <content/> is NOT in conversation, use N/A. Output HTML code block. Replace <content/> with conversation related to that section. Output:<h3>Subjective</h3><p><content>full sentences Subjective paragraph</content></p><h3>Objective</h3><p><content>full sentences Objective paragraph</content></p><h3>Assessment</h3><p><content>full sentences Assessment paragraph</content></p><h3>Plan</h3><p><content>full sentences Plan paragraph</content></p><h3>Recommended ICD-10 codes</h3><p><content><ul><li>- • item 1</li></ul></content></p>\nConversation\n "
  );

  console.log("LLM output", prompt);
  return prompt;
}

export const convertHiddenSectionsFromStructureToPrompt = function (structuredFormatObject) {
    let prompt = "";
    structuredFormatObject.sections.forEach((section) => {
      if (section.hideByDefault) {
        prompt += ` ${section.title},`;
      }
    });
    if(prompt.length > 0){
        prompt = StructureToLLMPromptCommands.HIDE + prompt.substring(0, prompt.length - 1);
    }
    return prompt;
}
export const convertHiddenSectionFromPromptToStructure = function (promptSuffixText) {

    let hiddenSectionText = "";
    promptSuffixText.split('\n').forEach((line) => {
        if (line.startsWith(StructureToLLMPromptCommands.HIDE)) {
            hiddenSectionText = line;
        }
    });

    let hiddenSections = hiddenSectionText.replace(StructureToLLMPromptCommands.HIDE, "").split(",");
    let structuredFormatObject = {
      sections: [],
    };
    hiddenSections.forEach((section) => {
      structuredFormatObject.sections.push({
        title: section.trim(),
        hideByDefault: true,
      });
    });
    return structuredFormatObject;
}

export const isSimpleTextCompatibleWithStructuredFormat = function (input,previousStructuredFormat) {

    let htmlTemplateObject = convertSimpleTextToHTMLTemplateObject(input);
    let structuredFormatObject = {}
    try{
      structuredFormatObject = convertHTMLToStructuredFormat(htmlTemplateObject);
  
    }
    catch(e){
      return false;
    }
  
  
    
  }
  
  export const convertHTMLTextToSimpleTextObject = function (inputHTML) {

    function removeHTMLTags(text) {
      return text.replace(/<[^>]*>/g, '');
    }
  
    let prefix = removeHTMLTags(inputHTML.split('<h3>')[0]);
    let content = "";
    if(inputHTML.indexOf('</h3>\n') == -1){ //add a new line if there is no new line after </h3>
      content = removeHTMLTags(inputHTML.substring(inputHTML.indexOf('<h3>'), inputHTML.lastIndexOf("</p>")+4).replace(/<\/h3>/g, `${titleDelimiter}\n`).replace(/<\/p>/g, '\n\n'));
    }
    else{
      content = removeHTMLTags(inputHTML.substring(inputHTML.indexOf('<h3>'), inputHTML.lastIndexOf("</p>")+4).replace(/<\/h3>/g, `${titleDelimiter}`).replace(/<\/p>/g, '\n'));
    }
    let suffix = removeHTMLTags(inputHTML.substring(inputHTML.lastIndexOf("</p>")+4)); 
    if(!suffix.startsWith('\n\n')){
      suffix = '\n\n' + suffix;
    }
  
    if(!content.startsWith('\n\n')) {
      content = '\n\n' + content;
    }
  
  
  
    let outputSimpleTextObject = {
      prefix: prefix,
      content: content,
      suffix: suffix
    };
    
  
    return outputSimpleTextObject;
  }
  
  export const  convertSimpleTextToHTMLTemplateObject = function (originalInput) {
    let input = originalInput.replace("transcript:", "").trim().replace("Transcript:", "").trim();

    let prefix = '';
    let suffix = '';
    let suffixStartIndex = -1;
    let content = [];
    let currentSection = null;
    let currentContent = [];
    let emptyLineCount = 0;
    let foundFirstTitle = false;
  
    // Split the input into lines and process each line
    const lines = input.split('\n');
  
    lines.forEach((line, index) => {
        const trimmedLine = line.trim();
  
        if (trimmedLine === '') {
            emptyLineCount++;
            if (foundFirstTitle) {
                currentContent.push(line.trim());  // Preserve empty lines within sections
            }
  
        } else {
            if (trimmedLine.endsWith(titleDelimiter)) {
                // This is a title line
                if (!foundFirstTitle) {
                    foundFirstTitle = true;
                } else if (currentSection) {
                    // Save the previous section
                    content.push({
                        title: currentSection.slice(0, -1*titleDelimiter.length).trim(),
                        content: currentContent.join('\n')
                    });
                }
                currentSection = trimmedLine;
                currentContent = [];
                emptyLineCount = 0;
            } else if (!foundFirstTitle) {
                // This is part of the prefix
                prefix += (prefix ? '\n' : '') + line.trim();
  
            } else {
                // This is content for the current section
                currentContent.push(line.trim());
                emptyLineCount = 0;
            }
        }
    });
  
    // Add the last section if there is one
    if (currentSection && !suffix) {
        content.push({
            title: currentSection.slice(0, -1*titleDelimiter.length).trim(),
            content: currentContent.join('\n')
        });
    }
  
  
    let lastEmptyLineIndex = -1;
    let lastEmptyLineCount = 0;
    let hasSuffix = false;
    let possibleSuffixContent = [];
  
  
    //find 2 empty lines next to each other starting from the bottom to determine the suffix
    for (let index = lines.length - 1; index >= 0; index--) {
  
        const trimmedLine = lines[index].trim();
  
        if (trimmedLine === '' && lastEmptyLineIndex == index + 1) {
            lastEmptyLineCount++;
            lastEmptyLineIndex = index;
            hasSuffix = true;
            break;
        }
  
        if(trimmedLine === ''){
            lastEmptyLineIndex = index;
            lastEmptyLineCount++;
        }
  
        if (trimmedLine.endsWith(titleDelimiter)) {
            break;
        }
        
        possibleSuffixContent.push(trimmedLine);
    }
    if(hasSuffix && lastEmptyLineCount >= 2 && possibleSuffixContent && content.length > 0){
        suffix = possibleSuffixContent.reverse().join('\n');
        //remove suffix from content from the last section
        content[content.length - 1].content = content[content.length - 1].content.replace(suffix, '');
    }
  
    // Convert sections to HTML
    const html = content.map(section =>
        `<h3>${section.title}</h3>\n<p><content>${section.content.replace('Use - • item 1','Use <ul><li>- • item 1</li></ul>').replace('- • item 1','<ul><li>- • item 1</li></ul>')}</content></p>`
    ).join('\n');
  
    return {
        prefix: prefix.trim(),
        content: html,
        suffix: suffix.trim()
    };
  };
  
  
  export const convertStructuredFormatToHTMLTemplateObject = function (structuredInputObject) {
  
  
    let prompt = {
      prefix: ""+structuredInputObject.prefix,
      suffix: ""+structuredInputObject.suffix,
      content: ""
    } 
  
    let hasDetailedInformation = undefined;
    //AR: Turn on detail for all sections or none, LLM doesn't yet support different detail levels for different sections
    for (let i = 0; i < structuredInputObject.sections.length; i++) {
      let section = structuredInputObject.sections[i];
      if (section.detailLevel) {
        hasDetailedInformation = section.detailLevel.toLowerCase();
        break;
      }
    }
  
    if(hasDetailedInformation){

        if(hasDetailedInformation == "high"){
            if(prompt.prefix.indexOf(StructureToLLMPromptCommands.DETAIL_NORMAL) > -1){
                prompt.prefix = prompt.prefix.replace(StructureToLLMPromptCommands.DETAIL_NORMAL, StructureToLLMPromptCommands.DETAIL_HIGH);
            }
            else{
                prompt.prefix += StructureToLLMPromptCommands.DETAIL_HIGH;
            }
        }
        else if(hasDetailedInformation == "normal"){

            if(prompt.prefix.indexOf(StructureToLLMPromptCommands.DETAIL_HIGH) > -1){
                prompt.prefix = prompt.prefix.replace(StructureToLLMPromptCommands.DETAIL_HIGH, StructureToLLMPromptCommands.DETAIL_NORMAL);
            }
            else{
                prompt.prefix += StructureToLLMPromptCommands.DETAIL_NORMAL;
            }
        }
        else{
          prompt.prefix += '\n'+StructureToLLMPromptCommands.DETAIL_HIGH;
        }


    }
  
  
    structuredInputObject.sections.forEach((section, index) => {
  
      if(!section.systemInstruction){
        section.systemInstruction = "";
      }
      if(!section.userInstruction){
        section.userInstruction = "";
      }

      if(hasDetailedInformation){
        if(section.detailLevel && section.detailLevel.toLowerCase() === "high"){
          //section.detailLevel = section.detailLevel
        }
        else if(section.detailLevel && section.detailLevel.toLowerCase() === "normal"){
          //section.detailLevel = StructureToLLMPromptCommands.DETAIL_NORMAL;
        }
        else if(section.title.toLowerCase() == "subjective" || section.title.toLowerCase() == "hpi" || section.title.toLowerCase() == "history of present illness" ){
          section.detailLevel = 'High';
        }
      }
  
      if (section.sectionStyle) {

        section.systemInstruction = buildSystemInstructionFromSection(section);
  
      }
  
      prompt.content += ((index == 0? '':'\n')+`<h3>${section.title}</h3>\n<p><content>${section.systemInstruction.trim()}${(section.systemInstruction.length > 0 && section.userInstruction.length > 0?'\n':'')}${section.userInstruction.trim()}</content></p>`);
  
      if (!section.hideByDefault) {
  
      }
    });
    
    //remove hidden sections in suffix & clean up suffix
    let suffixLines = prompt.suffix.split('\n');
    let finalizedSuffix = [];
    let hasHiddenSections = false;
    for(let i = 0; i < suffixLines.length; i++){
      if(suffixLines[i].startsWith(StructureToLLMPromptCommands.HIDE)){
        suffixLines.splice(i,1);
        hasHiddenSections = true;
        i--;
      }
    }
    
    for(let i = 0; i < suffixLines.length; i++){
        if(suffixLines[i].trim() == ""){
        }
        else{
            finalizedSuffix.push(suffixLines[i].trim());
        }
    }
    
    prompt.suffix = finalizedSuffix.join('\n');

    let hiddenSections = convertHiddenSectionsFromStructureToPrompt(structuredInputObject);
  
    if(prompt.suffix.length > 0 || hiddenSections.length > 0){
        prompt.suffix = '\n'+hiddenSections + (hiddenSections.length > 0?'\n':'') + prompt.suffix;
    }


  
  
    return prompt;
  
  }
  
  
  export const convertHTMLToLLMFormat = function (htmlInputObject, context) {
    //remove sections that's hidden
    //replace [] with context values
  }
  
  
  
  export const isHTMLorSimpleText = function (input) {
    if(input.includes("<h3>")){
      return "html";
    }
    else{
      return "simple";
    }
  }
  
  export const convertLegacySystemPromptToStructuredFormat = function (inputHTML, templateName, description) {
    let prefix = inputHTML.split('<h3>')[0];
    let content = inputHTML.substring(inputHTML.indexOf('<h3>'), inputHTML.lastIndexOf("</p>")+4);
    let suffix = inputHTML.substring(inputHTML.lastIndexOf("</p>")+4);  
  
    return convertHTMLToStructuredFormat({content, prefix:prefix, suffix: suffix}, templateName, description);
  }
  
  
  export const extractSystemAndUserInstruction = (content,section) => {
  
    let systemInstruction = "";
    let userInstruction = content;
    for(let key in StructureToLLMPromptCommands){
      let promptText = GetLLMPromptCommands(StructureToLLMPromptCommands[key],section);
      if(content.indexOf(promptText) > -1){
        systemInstruction += promptText + " ";
      }
  
      userInstruction = userInstruction.replace(promptText, "").trim();
    }
  
    return {
      systemInstruction, userInstruction
    };
  }

  export const buildSystemInstructionFromSection = (section) => {
    let systemInstruction = "";
    
    if (section.sectionStyle) {
  
        switch (section.sectionStyle.toLowerCase()) {
          case "paragraph": //default paragraph style
            if(section.systemInstruction.indexOf(StructureToLLMPromptCommands.BULLET) > -1){
                systemInstruction = section.systemInstruction.replace(StructureToLLMPromptCommands.BULLET, GetLLMPromptCommands(StructureToLLMPromptCommands.SENTENCES, section));
            }
            else if(section.systemInstruction.indexOf(StructureToLLMPromptCommands.BULLET_USE) > -1){
                systemInstruction = section.systemInstruction.replace(StructureToLLMPromptCommands.BULLET_USE, GetLLMPromptCommands(StructureToLLMPromptCommands.SENTENCES_USE, section));
            }
            else if(section.systemInstruction.indexOf(GetLLMPromptCommands(StructureToLLMPromptCommands.SENTENCES, section)) == -1
            && section.systemInstruction.indexOf(GetLLMPromptCommands(StructureToLLMPromptCommands.SENTENCES_USE, section)) == -1){
                systemInstruction = GetLLMPromptCommands(StructureToLLMPromptCommands.SENTENCES, section);
            }          
            break;
          case "bullet points":
            // Handle bullet point style
            if(section.systemInstruction.indexOf(GetLLMPromptCommands(StructureToLLMPromptCommands.SENTENCES, section)) > -1){
                systemInstruction = section.systemInstruction.replace(GetLLMPromptCommands(StructureToLLMPromptCommands.SENTENCES, section), StructureToLLMPromptCommands.BULLET);
            }
            else if(section.systemInstruction.indexOf(GetLLMPromptCommands(StructureToLLMPromptCommands.SENTENCES_USE, section)) > -1){
                systemInstruction = section.systemInstruction.replace(GetLLMPromptCommands(StructureToLLMPromptCommands.SENTENCES_USE, section), StructureToLLMPromptCommands.BULLET_USE);
            }
            else{
                systemInstruction = StructureToLLMPromptCommands.BULLET;
            }
            break;
        }
      }
    
      return systemInstruction;
  }
  
  export const convertHTMLToStructuredFormat = function (htmlInputObject, templateName, description) {
  // Helper function to extract content from HTML
  let htmlInput = htmlInputObject.content;
  
  let titles = extractH3Titles(htmlInput);
  let sectionDefinitions = titles.map(title => ({id:title, title, instruction: "",sectionStyle:"Paragraph",hideByDefault: false, }));
  
  let suffix = htmlInputObject.suffix;
  let hiddenSections = convertHiddenSectionFromPromptToStructure(suffix);
  
  // Extract sections
  // Extract sections
  const sectionRegex = /<h3>(.*?)<\/h3>\s*((?:<p><content>[\s\S]*?<\/content><\/p>)?[\s\S]*?)(?=<h3>|$)/g;
  const extractedSections = [];
  let match;
  
  while ((match = sectionRegex.exec(htmlInput)) !== null) {
    const title = match[1].trim();
    let content = match[2].trim();
  
    // Extract content from <content> tags if present
    const contentMatch = /<content>([\s\S]*?)<\/content>/.exec(content);
    if (contentMatch) {
      content = contentMatch[1].trim();
    }
  
    // If there's content after </content></p>, append it
    const additionalContent = content.split('</content></p>')[1];
    if (additionalContent) {
      content += '\n' + additionalContent.trim();
    }
  
  
  
  
    extractedSections.push({ title, instruction: content });
  }
  
  extractedSections.forEach((section, index) => {
  
    let instructionTypes = extractSystemAndUserInstruction(section.instruction,section);
    extractedSections[index].systemInstruction = instructionTypes.systemInstruction;
    extractedSections[index].userInstruction = instructionTypes.userInstruction;
    extractedSections[index].sectionStyle = 'Paragraph';
    if((section.systemInstruction).indexOf(StructureToLLMPromptCommands.BULLET) > -1){
      extractedSections[index].sectionStyle = 'Bullet points';
    }
    if((section.systemInstruction).indexOf(StructureToLLMPromptCommands.BULLET_USE) > -1){
      extractedSections[index].sectionStyle = 'Bullet points';
    }
  })
  
  
  
  // Create structured format object
  const structuredSection = {
    name: templateName,
    prefix: htmlInputObject.prefix,
    suffix: htmlInputObject.suffix,
    description: description,
    sections: sectionDefinitions.map(def => ({
      ...def,
      hideByDefault: hiddenSections.sections.find(s => s.title.toLowerCase() === def.title.toLowerCase())?.hideByDefault || false,
      sectionStyle: extractedSections.find(s => s.title.toLowerCase() === def.title.toLowerCase())?.sectionStyle || "",
      // instruction: extractedSections.find(s => s.title.toLowerCase() === def.title.toLowerCase())?.instruction || "",
      systemInstruction: extractedSections.find(s => s.title.toLowerCase() === def.title.toLowerCase())?.systemInstruction || "",
      userInstruction: extractedSections.find(s => s.title.toLowerCase() === def.title.toLowerCase())?.userInstruction || ""
  
    }))
  };

    let hasDetailedInformation = undefined;
    if(htmlInputObject.prefix.indexOf(StructureToLLMPromptCommands.DETAIL_HIGH) > -1){
        hasDetailedInformation = "High";
    }
    else if(htmlInputObject.prefix.indexOf(StructureToLLMPromptCommands.DETAIL_NORMAL) > -1){
        hasDetailedInformation = "Normal";
    }

    if(hasDetailedInformation){
        //if has detailed information, set the detail level to high or normal for subjective section, hpi or history of present illness
        for(let i = 0; i < structuredSection.sections.length; i++){
            if(structuredSection.sections[i].title.toLowerCase() == "subjective" || structuredSection.sections[i].title.toLowerCase() == "hpi" || structuredSection.sections[i].title.toLowerCase() == "history of present illness"){
                structuredSection.sections[i].detailLevel = (hasDetailedInformation || 'high');
                break;
            }
        }
    }





  
    return structuredSection;
  }
  
  export const StructureToLLMPromptCommands = {
    DETAIL_HIGH:"Include every comprehensive detail from conversation.",
    DETAIL_NORMAL: "Only highlight the import information. Only include items from the conversation.",
    BULLET:"<ul><li>- • item 1</li></ul>",
    BULLET_USE:"Use <ul><li>- • item 1</li></ul>",
    SENTENCES:"full sentences {section.title} paragraph",
    SENTENCES_USE:"Use full sentences {section.title} paragraph",
    TRANSCRIPTION:"Transcription:[transcript]",
    HIDE:"Hide the following sections:"
  }
  
  
  export const GetLLMPromptCommands = function (structureToLLMPromptCommandsValue, context) {
    if(!structureToLLMPromptCommandsValue){
      return "-";
    }
  
    return structureToLLMPromptCommandsValue.replace("{section.title}", context.title);
  }
  
  // items in [something] that maps to patient context
  export const ContextToLLMPromptPropertyMapping = {
    TRANSCRIPTION:"transcript",
    TRANSCRIPT:"transcript",
  }
  
  //** =========================== Legacy formats below =============================================== **/
  
  


  


  export const mapCustomPromptObjToTemplates = function (promptObj) {
    if (promptObj.title in promptTemplates) {
  
      if(promptObj.isAdvanced){
        promptTemplates[promptObj.title].isAdvanced = promptObj.isAdvanced
      }
  
      if(promptObj.prompt){
        promptTemplates[promptObj.title].prompt = promptObj.prompt
      }
      promptTemplates[promptObj.title].ds_id = promptObj.ds_id
  
    } 
    else if(!promptObj.ds_id && !(promptObj.title in promptTemplates)){
      promptTemplates[promptObj.title] = {
        name: promptObj.title,
        description: "",
        sections: [],
        isAdvanced: true,
        prompt: promptObj.prompt,
      }
    }
  
    if(promptObj.ds_id && !promptTemplates[promptObj.ds_id]){
      promptTemplates[promptObj.ds_id] = {
        ds_id:promptObj.ds_id,
        name: promptObj.title,
        description: "",
        sections: [],
        isAdvanced: true,
        prompt: promptObj.prompt,
      };
    }
  
  };
  

export function createTemplateFromPrompt(originalTemplate, promptObj) {
    const prompt = promptObj.prompt;
    const sections = [];
    const regex = /<h3>(.*?)<\/h3><p>(.*?)<\/p>/g;
  
    let isDetailed = false;
    if (prompt.includes("every comprehensive detail")) {
      isDetailed = true;
    }
  
    if(!originalTemplate.name)
      originalTemplate.name = promptObj.title
  
    let match;
    while ((match = regex.exec(prompt))) {
      const title = match[1];
      const content = match[2];
      let style = "";
      if (content.includes("<li>")) {
        style = "Bullet points";
      } else if (content.includes("sentences")) {
        style = "Paragraph";
      }
  
      sections.push({
        title,
        hideByDefault: false,
        content,
        sectionStyle: style,
      });
    }
    
    for (const originalSection of originalTemplate.sections) {
      let isFoundSection = false
      for (const section of sections) {
        if (section.title.toLowerCase() === originalSection.title.toLowerCase()) {
          isFoundSection = true;
          originalSection.hideByDefault = section.hideByDefault;
          originalSection.sectionStyle = section.sectionStyle;
          originalSection.content = section.content;
  
          if (originalSection.detailLevel) {
            if (isDetailed) {
              originalSection.detailLevel = "High";
            } else {
              originalSection.detailLevel = "Normal";
            }
          }
          break;
        }
      }
      if(!isFoundSection){
        originalSection.hideByDefault = true
      }
    }
  
    return originalTemplate;
  }
  
  export function createPromptFromTemplate(selectedTemplateKey) {
    let promptBlocks = promptTemplates[selectedTemplateKey];
  
    let prompt = "";
    prompt +=
      "Summarize it in a clinical SOAP NOTE from conversation translate to english. Use medical abbreviation and medical terminology.";
  
    let isDetailed = false;
    promptBlocks.sections.forEach((section) => {
      if (section.id == "subjective" && section.detailLevel == "High") {
        prompt +=
          " Include every comprehensive detail from conversation. Be as detailed as possible. Include all mentions of medications, symptoms, and other relevant information.";
        isDetailed = true;
      }
    });
  
    if (!isDetailed) {
      prompt +=
        " Only highlight the import information. Only include items from the conversation.";
    }
  
    prompt += "If <content/> is NOT in conversation, use N/A. ";
    if (isDetailed)
      prompt +=
        "Output HTML code block. Replace <content/> with conversation related to that section. Output:";
    else
      prompt +=
        "Output HTML code block. Replace <content/> with conversation summary related to that section. Output:";
  
    console.log(promptBlocks);
  
    promptBlocks.sections.forEach((section) => {
      let content = "<content/>";
  
      if (!section.hideByDefault) {
        if (section.sectionStyle) {
          switch (section.sectionStyle.toLowerCase()) {
            case "paragraph":
              // Handle paragraph style
              content = `<content>full sentences ${section.title} paragraph</content>`;
              break;
            case "bullet points":
              // Handle bullet point style
              content = `<content><ul><li>- • item 1</li></ul></content>`;
              break;
          }
        }
  
        prompt += `<h3>${section.title}</h3><p>${content}</p>`;
      }
    });
  
    prompt += "\nConversation:\n[transcription]";
  
    return prompt;
  }



const actions = {
    APPEND_NOTES: "Append to all my notes",
    DEFAULT_NOTE: "Use as my default note",
    ADD_TAB: "Add as another tab",
    PREVISIT_SUMMARY: "Use as my pre-visit summary",
    ADD_BUTTON: "Add as a button below my note",
    SAVE_LATER: "Save for later",
  };
  
export  const getLLMPromptWithContext = (
    prompt,
    patient,
    activeNote,
    transcriptText
  ) => {
    let llmPrompt = prompt;
    let hasDataReference = false;
    if (llmPrompt.includes("[note]")) {
      llmPrompt = llmPrompt.replace(
        "[note]",
        'use content from note: "' + htmlToText(activeNote) + '"'
      );
      hasDataReference = true;
    }
    if (llmPrompt.includes("[transcript]")) {
      llmPrompt = llmPrompt.replace(
        "[transcript]",
        'use content from transcript: "' + htmlToText(transcriptText) + '"'
      );
      hasDataReference = true;
    }
  
    if (llmPrompt.includes("[transcription]")) {
      llmPrompt = llmPrompt.replace(
        "[transcription]",
        '"' + htmlToText(transcriptText) + '"'
      );
      hasDataReference = true;
    }
    if (llmPrompt.includes("[history]")) {
      llmPrompt = llmPrompt.replace(
        "[history]",
        'use content from history: "' + htmlToText(patient.history || "") + '"'
      );
      hasDataReference = true;
    }
  
    if (hasDataReference) {
      llmPrompt =
        llmPrompt +
        " Output HTML code block contain only include the body content without the body tag.";
    } else {
      llmPrompt =
        prompt +
        " Output HTML code block contain only include the body content without the body tag. Base it only from note:" +
        htmlToText(activeNote);
    }
  
    return llmPrompt;
  };


export const extractH3Titles = function(text) {
    const regex = /<h3>(.*?)<\/h3>/g;
    const matches = text.match(regex);
    
    if (!matches) {
      return [];
    }
    
    return matches.map(match => match.replace(/<\/?h3>/g, '').trim());
  }
  

export function extractSimpleTextTitles(text) {
    const escapedDelimiter = titleDelimiter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  
    // Regular expression to match titles ending with the delimiter
    const titleRegex = new RegExp(`(.+?)${escapedDelimiter}(?=\\s|$)`, 'g');
    
    // Use match() to find all occurrences and map to extract just the titles
    const titles = text.match(titleRegex)?.map(match => match.slice(0, -titleDelimiter.length).trim()) || [];
    
    return titles;
}

export function getCurrentDefaultPrompt(tmpPrompts){
  let systemPrompts = []

  //systemPrompts
  systemPrompts.push(
    { "value": "soapAPSection", "label": "SOAP Section - Assessment & Plan" }
  )
  systemPrompts.push(
    { "value": "DAP", "label": "DAP" }
  )
  systemPrompts.push(
    { "value": "H&P", "label": "H&P" }
  )
  systemPrompts.push(
    { "value": "multiSection", "label": "Multi Section" }
  )
  //myPrompts
  let myDefaultPrompt = null;
  for (let i = 0; i < tmpPrompts.length; i++) {
    let title = tmpPrompts[i].title
    if (title in promptTemplates) {
      tmpPrompts[i].title = promptTemplates[title].name
    }

    if (tmpPrompts[i].type === "DEFAULT_NOTE" && !myDefaultPrompt) {
      myDefaultPrompt = tmpPrompts[i];
    }

  }

  if (!myDefaultPrompt) {
    myDefaultPrompt = systemPrompts[0]
  }

  return myDefaultPrompt
  
}

export function checkNewPromptFormat(promptObject){

  if(promptObject.version && promptObject.version == "0917"){
    return true;
  }

  return false

}