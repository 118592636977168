import React, { useState, useCallback, forwardRef, useImperativeHandle, useEffect } from 'react';
import {
  Analytics,
  StandardAnalyticsService,
} from "./analytics"; // Adjust this import path as necessary

const AsyncMicrophonePermissionModal = forwardRef((props, ref) => {
  const analyticsService = StandardAnalyticsService.shared;
  const [isOpen, setIsOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState({
    title: "Microphone Access Required",
    subtitle: "Health Note captures a conversation to generate a note. To do so, microphone access is required. Please grant microphone access."
  });
  const [resolveRef, setResolveRef] = useState(null);
  const [screenName, setScreenName] = useState(null);

  useImperativeHandle(ref, () => ({
    openModal: (config = {}) => {
      if(config.screenName) {
        setScreenName(config.screenName);
      }
      setModalConfig({ ...modalConfig, ...config });
      setIsOpen(true);
      return new Promise((resolve) => {
        setResolveRef(() => resolve);
      });
    }
  }));

  const handleClose = useCallback((granted) => {
    setIsOpen(false);

    if (granted) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(() => {
          analyticsService.trackEvent(
            Analytics.Category.MICROPHONE,
            Analytics.Action.GRANTED,
            "Microphone",
            "",
            setScreenName
          );
          if (resolveRef) resolveRef(true);
        })
        .catch((error) => {
          console.error("Error accessing microphone:", error);
          analyticsService.trackEvent(
            Analytics.Category.MICROPHONE,
            Analytics.Action.DENIED,
            "Microphone",
            "",
            setScreenName
          );
          if (resolveRef) resolveRef("You may need to enable microphone access in your browser settings to continue.");
        });
    } else {
      analyticsService.trackEvent(
        Analytics.Category.PERMISSIONS,
        Analytics.Action.DENIED,
        "Microphone",
        "",
        Analytics.ScreenName.MAIN
      );
      if (resolveRef) resolveRef(false);
    }
  }, [resolveRef]);

  useEffect(() => {
    if (isOpen) {
      analyticsService.trackScreen(Analytics.Category.PERMISSIONS, Analytics.ScreenName.MICROPHONE);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={`modal ${isOpen ? 'modal-open' : ''}`}>
      <div className="modal-box">
        <h3 className="font-bold text-lg">{modalConfig.title}</h3>
        {modalConfig.subtitle && <p className="py-4">{modalConfig.subtitle}</p>}
        <div className="modal-action">
          <button className="btn btn-accent" onClick={() => handleClose(true)}>OK</button>
          <button className="btn btn-ghost" onClick={() => handleClose(false)}>Cancel</button>
        </div>
      </div>
      <label className="modal-backdrop" onClick={() => handleClose(false)}></label>
    </div>
  );
});

export default AsyncMicrophonePermissionModal;