import React, { useEffect, useState } from 'react';
import { sharePrompt, getUserProfile } from './db.js';
import {
  Analytics,
  StandardAnalyticsService,
} from "./analytics"; // Adjust this import path as necessary

const PromptShareModal = ({ isOpen, onClose, onShare, promptObj }) => {
  const analyticsService = StandardAnalyticsService.shared;

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [author, setAuthor] = useState('');
  const [templateText, setTemplateText] = useState('');
  const [isSharing, setIsSharing] = useState(false);
  const [errors, setErrors] = useState({});
  const [specialty, setSpecialty] = useState(false);
  const [dsId, setDsId] = useState(null);

  const validateForm = () => {
    const newErrors = {};
    if (!title.trim()) newErrors.title = 'Title is required';
    if (!description.trim()) newErrors.description = 'Description is required';
    if (!author.trim()) newErrors.author = 'Author is required';
    if (!templateText.trim()) newErrors.templateText = 'Template Text is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleShare = async () => {
    if (!validateForm()) return;

    setIsSharing(true);
    try {
      let objectToShare = {
        "prompt": templateText,
        "isAdvanced": true,
        "type": "NOTE_TEMPLATE",
        "title": specialty ? `[${specialty}] ${title}` :`${title}`,
        "description": description,
        "createdBy": author,
        "version":promptObj.version || ""
      };

      if (dsId) {
        objectToShare.ds_id = dsId;
      }
    
      let results = await sharePrompt(objectToShare)

      onShare(results);
      onClose();
    } catch (error) {
      console.error('Error sharing prompt:', error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsSharing(false);
    }
  };

  useEffect(() => {
    const init = async () => {
      if (isOpen && promptObj) {
        analyticsService.trackScreen(Analytics.Category.TEMPLATE, Analytics.ScreenName.TEMPLATE_PROMPT_SHARE);
      }
      if (promptObj) {
        setTitle(promptObj.title ? promptObj.title.replace(/^\[.*?\]\s*/, '') : '');
        setDescription(promptObj.description || '');
        setAuthor(promptObj.author || '');
        setTemplateText(promptObj.prompt || '');
        setDsId(promptObj.ds_id || null);
        
        // Extract specialty from title if present
        const specialtyMatch = promptObj.title?.match(/^\[(.*?)\]/);
        setSpecialty(specialtyMatch ? specialtyMatch[1] : false);
      } else {
        setTitle('');
        setDescription('');
        setAuthor('');
        setTemplateText('');
        setDsId(null);
        setSpecialty(false);
      }

      if (!specialty) {
        let myProfile = await getUserProfile();
        if (myProfile && myProfile.specialty && myProfile.specialty.specialty && myProfile.specialty.specialty.indexOf('Other') === -1) {
          setSpecialty(myProfile.specialty.specialty);
        }
      }
    };

    init();
  }, [isOpen, promptObj]);

  if (!isOpen) return null;

  return (
    <div className="modal modal-open">
      <div className="modal-box">
        <h3 className="font-bold text-lg mb-4">
          {dsId ? 'Share' : 'New'} Template {specialty ? '- ' + specialty : ""}
        </h3>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">Title *</span>
          </label>
          <input
            type="text"
            placeholder="Enter title"
            className={`input input-bordered w-full ${errors.title ? 'input-error' : ''}`}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          {errors.title && <span className="text-error text-sm mt-1">{errors.title}</span>}
        </div>
        <div className="form-control w-full mt-4">
          <label className="label">
            <span className="label-text">Description *</span>
          </label>
          <textarea
            placeholder="Enter description"
            className={`textarea textarea-bordered w-full ${errors.description ? 'textarea-error' : ''}`}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
          {errors.description && <span className="text-error text-sm mt-1">{errors.description}</span>}
        </div>
        <div className="form-control w-full mt-4">
          <label className="label">
            <span className="label-text">Author *</span>
          </label>
          <input
            type="text"
            placeholder="Enter author name"
            className={`input input-bordered w-full ${errors.author ? 'input-error' : ''}`}
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
          />
          {errors.author && <span className="text-error text-sm mt-1">{errors.author}</span>}
        </div>
        <div className="form-control w-full mt-4">
          <label className="label">
            <span className="label-text">Template Instruction *</span>
          </label>
          <textarea
            placeholder="Enter template Instruction"
            className={`textarea textarea-bordered w-full ${errors.templateText ? 'textarea-error' : ''}`}
            value={templateText}
            onChange={(e) => setTemplateText(e.target.value)}
          ></textarea>
          {errors.templateText && <span className="text-error text-sm mt-1">{errors.templateText}</span>}
        </div>
        <div className="modal-action">
          <button 
            className={`btn btn-accent ${isSharing ? 'loading' : ''}`} 
            onClick={handleShare}
            disabled={isSharing}
          >
            {isSharing ? 'Sharing...' : 'Share'}
          </button>
          <button className="btn" onClick={onClose} disabled={isSharing}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default PromptShareModal;