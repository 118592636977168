import React, { useState, useEffect, useRef, forwardRef } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Container,
  useTheme,
  useMediaQuery,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";

import {
  getAuth,
  savePrompt,
  updatePatient,
  getKeys,
  setAlertClosed,
  getAlertClosed,
  getPatient,
  parseNote,
  isNoteValid,
  htmlToText,
  getLLMPromptWithContext,
  runClinicalLLM,
  logError,
  saveKeys,
  joinNoteSections,
  isAlphaFlag,
  getTextFromSections,
  getSavedNoteURL,
  getPromptDetails,
  getHistory,
  saveHistory,
} from "./db"; // Ensure this import points to your db handling file where updatePatient is defined
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import Fab from "@mui/material/Fab";
import Alert from "@mui/material/Alert";
import MicOffIcon from "@mui/icons-material/MicOff";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AudioAnalyser from "./AudioAnalyser";
import PropTypes from "prop-types";
import CopyDropdownMenu from "./CopyDropdownMenu"; // Adjust the import path as needed
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import PromptManagement from "./PromptManagement";
import AsyncPatientNameModal from "./AsyncPatientNameModal";
import PromptTopBar from "./PromptTopBar";

import {
  promptAutomation
} from "./prompt.automation"; // Adjust this import path as necessary

import {
  Analytics,
  StandardAnalyticsService,
} from "./analytics"; // Adjust this import path as necessary

const AUTH_URL =
  import.meta.env.VITE_VERCEL_ENV == "preview"
    ? import.meta.env.VITE_PREVIEW_AUTH_URL
    : import.meta.env.VITE_AUTH_URL;
const LOGIN_URL =
  import.meta.env.VITE_VERCEL_ENV == "preview"
    ? import.meta.env.VITE_PREVIEW_LOGIN_URL
    : import.meta.env.VITE_LOGIN_URL;

import { LiveTranscriptionEvents, createClient } from "@deepgram/sdk";

import { OpenAIClient, AzureKeyCredential } from "@azure/openai";
import { connectToDeepgram, uploadAudio } from "./deepgramws";
import EditableNoteContent from "./EditableNoteContent";

const dictation_prompt = "Converting a physician's dictated transcription into a note that is suitable for clinical documentation. Using the dictated note translate to english. Output section with title:<h3>title</h3><p><content/></p>. Dictated text here:"

const summary_prompts_options = {
  short_sentence:
    "<instruction>Output HTML code block. Do not use pronouns but use Pt instead. </instruction>. Use H3 tags for titles.Summarize it in a clinical SOAP NOTE from conversation translate to english. Use medical abbreviation and medical terminology. Include every comprehensive detail from conversation. Be as detailed as possible. Include all mentions of medications, symptoms, and other relevant information.If <content/> is NOT in conversation, use N/A. Output HTML code block. Replace <content/> with conversation related to that section. Output:<h3>Subjective</h3><p><content>full sentences Subjective paragraph</content></p><h3>Objective</h3><p><content>full sentences Objective paragraph</content></p><h3>Assessment</h3><p><content>full sentences Assessment paragraph</content></p><h3>Plan</h3><p><content>full sentences Plan paragraph</content></p><h3>Recommended ICD-10 codes</h3><p><content><ul><li>- • item 1</li></ul></content></p>\nConversation\n",
  long_sentence:
    "Summarize it in a clinical SOAP NOTE from conversation translate to english, exclude greetings and goodbye. Only include items from the conversation. Do not use pronouns but use Pt instead. Output HTML code block contain only include the body content without the body tag. Replace <content/> with conversation summary related to that section. If <content/> is NOT in conversation, use N/A. Example: <h3>Subjective</h3><p><content/> </p><h3>objective</h3><p><content/> </p><h3>assessment</h3><p><content/> </p><h3>plan</h3><p><content/> </p>.\nConversation:\n",

  long_bullet:
    "Summarize it in a clinical SOAP NOTE from conversation translate to english in bullet format,  only highlight the import information, keep it brief and use medical abbreviations, exclude greetings and goodbye. Do not use pronouns but use Pt instead. Only include items from the conversation. Output HTML code block contain only include the body content without the body tag. Replace <content/>with conversation summary related to that section. Example: <h3>Subjective</h3><p><content/></p><h3>objective</h3><p><content/></p><h3>assessment</h3><p><content/></p><h3>plan</h3><p><content/></p>.\nConversation:\n",
  short_bullet:
    "Summarize it in a clinical SOAP NOTE from conversation translate to english in bullet point list \n-item 1\n-item 2 format, exclude greetings and goodbye. Do not use pronouns but use Pt instead. If <content/> is NOT in conversation, use N/A. Output HTML code block contain only include the body content without the body tag. Replace <content/> with conversation related to that section. Example: <h3>Subjective</h3><p><content/></p><h3>objective</h3><p><content/></p><h3>assessment</h3><p><content/></p><h3>plan</h3><p><content/></p>.\nConversation:\n", //need output of shart bullets first
};

const MAX_CHARACTERS_TRANSCRIPTION = 100;
const MAX_TIMER_COUNT = 1;
const summary_prompts_results = {
  long_bullet: false,
  short_bullet: false,
  short_sentence: false,
  long_sentence: false,
};

const summary_prompts_custom_results = {
  custom_01: false,
};

const note_format_text = {
  long_bullet: "Long Bullet",
  short_bullet: "Short Bullet",
  short_sentence: "Short Sentence",
  long_sentence: "Long Sentence",
};

let PROMPT_MAIN =
  "Summarize the patient complaint in bulleted format in a way the patient can understand. Do not use pronouns but use P/t instead.";
let PROMPT_DETAILS =
  'Summarize conversation, list key points, use 1st person, title "Reason for Visit". Do not use pronouns but use P/t instead. Next "Additional Information Needed" ask 2 important brief questions bullet to ask, keep it simple and give examples. Output HTML code block contain only include the body content without the body tag.  <div><h3>Subjective<h3><h3>Objective<h3><h3>Assessment<h3><h3>Plan<h3></div> Conversation:';
let PROMPT_DETAILS_FINAL =
  'Summarize the key points of the conversation, using 1st person patient perspective, Do not use pronouns but use P/t instead. title this "Reason for Visit".  Output HTML code block contain only include the body content without the body tag.  <div><h3>Subjective<h3><h3>Objective<h3><h3>Assessment<h3><h3>Plan<h3></div>Conversation:';
let PROMPT_CLINICAL =
  "Convert to HPI, title it HPI & Output HTML code block contain only include the body content without the body tag.  <div><h3>Subjective<h3><h3>Objective<h3><h3>Assessment<h3><h3>Plan<h3></div>";

let globalStream = null; // A place to store the stream globally
let globalMicrophone = null; // A place to store the MediaRecorder globally
let pulsatingTimer = false;
let API_OPENAI_KEY = undefined;
let API_OPENAI_ENDPOINT = undefined;
let API_DEEPGRAM_KEY = undefined;
let API_OPENAI_DEPLOYMENT_ID = undefined;
let JWT_TOKEN = undefined;
let JWT_PATIENT_UID = undefined;

let deepgram = undefined;
let connection = undefined;

let isListening = false;

function isIphoneOrIpad() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Check for iPhone or iPad in the user agent string
  return /iPad|iPhone|Android|iPod/.test(userAgent) && !window.MSStream;
}

function mapNoteFormatToPrompt(noteFormat) {
  return summary_prompts_options[noteFormat];
}
function mapNoteFormatToResult(noteFormat) {
  return summary_prompts_results[noteFormat];
}
function mapNoteFormatToText(noteFormat) {
  return note_format_text[noteFormat];
}
export function getPromptTitle(customPrompts,
  format) {


  let dictationPrompt = false;
  let hasCustomPrompt = false;
  if (customPrompts && customPrompts.length > 0) {
    for (let i = 0; i < customPrompts.length; i++) {
      let customPrompt = customPrompts[i];
      if (customPrompt.type == "DEFAULT_NOTE" && !hasCustomPrompt) {

        return customPrompt["title"];
        hasCustomPrompt = true;
      }
    }
  }


  return format;
}

async function runClinicalSummary(
  customPrompts,
  prompt,
  patient,
  transcriptText,
  onProgress,
  onComplete,
  onError
) {
  let clinicalSummary = "";

  const client = new OpenAIClient(
    API_OPENAI_ENDPOINT,
    new AzureKeyCredential(API_OPENAI_KEY)
  );

  // if(transcriptText.length <= 25){
  //   return onError({
  //     message: "Your conversation is too short to generate a useful medical note. Health note works best in a real conversation with real patients.",
  //     action:"retry"
  //   });
  // }

  let defaultPrompt = prompt + transcriptText;
  let dictationPrompt = false;
  let hasCustomPrompt = false;
  let newPromptFormat = false;
  let lowerCaseTranscript = transcriptText.toLowerCase();
  let keywordsTriggerPrompts = [];

  if (customPrompts && customPrompts.length > 0) {
    for (let i = 0; i < customPrompts.length; i++) {
      let customPrompt = customPrompts[i];
      //check description if it's in the transcript
      if (customPrompt["description"] && customPrompt.type == "AUTOMATION") {
      if (lowerCaseTranscript.indexOf(customPrompt["description"].toLowerCase()) > -1) {
        let promptDetails = await getPromptDetails(customPrompt["ds_id"]);

        keywordsTriggerPrompts.push(promptDetails);
      }
    }
    }

    for (let i = 0; i < customPrompts.length; i++) {
      let customPrompt = customPrompts[i];
      if (customPrompt.type == "DEFAULT_NOTE" && !hasCustomPrompt) {

        if (customPrompt["version"]) {
          newPromptFormat = true;
        }

        try {
          let promptDetails = await getPromptDetails(customPrompt["ds_id"]);
          if (promptDetails) {


            if (promptDetails["prompt"].indexOf('[transcription]') == -1 && promptDetails["prompt"].indexOf('[transcript]')) {
              promptDetails["prompt"] += " Do this for the following transcription:" + transcriptText
              defaultPrompt = promptDetails["prompt"]
            }
            else {
              defaultPrompt = promptDetails["prompt"].replace(
                "[transcription]",
                transcriptText
              );
            }
          }
        } catch (e) {
          logError("Error getting custom prompt:", e);
          analyticsService.trackEvent(
            Analytics.Category.ERROR,
            Analytics.Action.CREATED,
            Analytics.Category.TEMPLATE,
            "",
            Analytics.ScreenName.CUSTOM_PROMPT);
        }
        hasCustomPrompt = true;
      }

      if (!dictationPrompt) {
        if (customPrompt["title"].toLowerCase().indexOf('dictation') > -1) {
          let promptDetails = await getPromptDetails(customPrompt["ds_id"]);
          if (promptDetails["prompt"].indexOf('[transcription]') == -1 && promptDetails["prompt"].indexOf('[transcript]')) {
            promptDetails["prompt"] += " Do this for the following transcription:" + transcriptText
            dictationPrompt = promptDetails["prompt"]
          }
          else {
            dictationPrompt = promptDetails["prompt"].replace(
              "[transcription]",
              transcriptText
            );
          }
        }

      }
    }
  }

  if (patient.isDictation) {
    defaultPrompt = dictation_prompt + transcriptText
  }
  if (patient.isDictation && dictationPrompt) {
    defaultPrompt = dictationPrompt
  }


  const messagesStep1 = [
    {
      role: "system",
      content: "You are a medical doctor reviewing clinical note",
    },
    {
      role: "user",
      content: promptAutomation('<instruction>Output HTML code block. Do not use pronouns but use Pt instead. </instruction>. Use H3 tags for titles.' + defaultPrompt.replace("[history]", '<context>'+patient.history+'</context>'),keywordsTriggerPrompts),
    },
  ];

  console.log(`Messages: ${messagesStep1.map((m) => m.content).join("\n")}`);

  const events1 = await client.streamChatCompletions(
    API_OPENAI_DEPLOYMENT_ID,
    messagesStep1,
    {
      maxTokens: 8192,
      temperature: newPromptFormat ? 0.0 : 0.0,
    }
  );

  let lastTokenWord = "";
  let hasStarted = false;

  for await (const event of events1) {
    for (const choice of event.choices) {
      const delta = choice.delta?.content;
      if (delta !== undefined) {
        lastTokenWord = delta;
        if (delta.indexOf("``") > -1) {
          hasStarted = !hasStarted;
          continue;
        }

        if (delta.indexOf("html") > -1) {
          continue;
        }

        if (hasStarted) {
          clinicalSummary += delta
            .replaceAll("</h3>\n<p>", "</h3><p>")
            .replaceAll("\n\n", "\n")
            .replace("\n<h3>Sub", "<h3>Sub")
            .replaceAll("<p>\n", "<p>")
            .replaceAll("<br>\n", "\n")
            .replaceAll("<br/>\n", "\n");
        }

        if (delta.indexOf(" ") > -1 && hasStarted) {
          onProgress(
            clinicalSummary
              .replaceAll("</h3>\n<p>", "</h3><p>")
              .replaceAll("\n\n", "\n")
              .replace("\n<h3>Sub", "<h3>Sub")
              .replaceAll("<p>\n", "<p>")
              .replaceAll("<br>\n", "\n")
              .replaceAll("<br/>\n", "\n")
          );
          lastTokenWord = "";
        }
      }
    }
  }

  setTimeout(() => {
    onComplete(
      clinicalSummary
        .replaceAll("</h3>\n<p>", "</h3><p>")
        .replaceAll("\n\n", "\n")
        .replace("\n<h3>Sub", "<h3>Sub")
        .replaceAll("<p>\n", "<p>")
        .replaceAll("<br>\n", "\n")
        .replaceAll("<br/>\n", "\n")
    );
  }, 1000);
}

function resetLLMResults() {

  summary_prompts_results["long_bullet"] = false;
  summary_prompts_results["short_bullet"] = false;
  summary_prompts_results["short_sentence"] = false;
  summary_prompts_results["long_sentence"] = false;

  summary_prompts_custom_results["custom_01"] = false;
}

function debounce(func, wait) {
  let timeout;

  return {
    call: function (...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    },
    clear: function () {
      clearTimeout(timeout);
    },
  };
}

const audioChunks = [];
let mimeType = "audio/webm; codecs=opus";
const dialogue = [];
const PatientNote = forwardRef(({
  prompts,
  patient,
  onUpdate,
  onChange,
  onNoteSettings,
  handlePromptChange,
  totalNumberOfPatients,
}, ref) => {
  const analyticsService = StandardAnalyticsService.shared;

  const theme = useTheme();
  const [copied, setCopied] = useState(false);
  const patientModalRef = React.useRef(null);



  const timerRef = useRef(null);
  const [currentTimer, setCurrentTimer] = useState(0);


  const scrollTranscriptRef = useRef(null);
  const scrollNoteRef = useRef(null);
  const scrollCustomNoteRef = useRef(null);
  const [hasMicrophoneAccess, setHasMicrophoneAccess] = useState(true);

  const scrollFollowupRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIOS = isIphoneOrIpad(); // iphone doesn't support realtime deepgram

  const [name, setName] = useState("");
  const [clinicalNote, setClinicalNote] = useState("");
  const [clinicalNoteSections, setClinicalNoteSections] = useState({});

  const [isClinicalNoteGenerating, setIsClinicalNoteGenerating] =
    useState(true);
  const [
    isClinicalNoteGeneratingCompleted,
    setIsClinicalNoteGeneratingCompleted,
  ] = useState(false);

  const [patientSessionId, setPatientSessionId] = useState("");

  const [isInsertTranscript, setIsInsertTranscript] = useState(false);

  const [showInPreview, setShowInPreview] = useState(false);

  const [micOpen, setMicOpen] = useState(false);
  const [micOpening, setMicOpening] = useState(false);
  const [audioStream, setAudioStream] = useState(null);
  const [transcriptText, setTranscriptText] = useState("");
  const [transcribingText, setTranscribingText] = useState("");

  const [isAlertClosed, setIsAlertClosed] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [uploadingAudio, setUploadingAudio] = useState(false);

  const historyRef = useRef(null);
  const [historyText, setHistoryText] = useState(patient.history || "");
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);




  const [connectionStatus, setConnectionStatus] = useState("");

  const [noteFormat, setNoteFormat] = useState("short_sentence");

  const [isValidNote, setIsValidNote] = useState(true);

  const [isExpanded, setIsExpanded] = useState(true);
  const handleToggleExpand = () => {
    setIsExpanded(prevState => !prevState);
  };

  const tryAgain = () => {

    analyticsService.trackEvent(
      Analytics.Category.ENCOUNTER,
      Analytics.Action.RETRY,
      "",
      "",
      Analytics.ScreenName.NOTE);

    resetLLMResults();
    setTranscriptText("");
    setTranscribingText("");
    setClinicalNote("");
    setClinicalNoteSections({});
    setIsClinicalNoteGenerating(false);
    setIsClinicalNoteGeneratingCompleted(false);

    setNoteFormat("short_sentence");
    setSelectedSection("transcript");
    setIsValidNote(false);
    dialogue.length = 0;
    audioChunks.length = 0;
    setTimeout(() => {
      toggleMic();
    }, 500);
  }

  // Debounced save function
  const debouncedSave = useRef(
    debounce((currentPatientValue) => {
      updatePatient(patient, currentPatientValue, (savedData) => {
        onUpdate(patient, savedData);
      });
    }, 2000) // 1000 milliseconds = 1 second
  );

  const handleCopySectionClick = (copySection) => {
    console.log(`${copySection.text} clicked`);
  };

  const clearValues = () => {
    debouncedSave.current.clear(); // Clear the debounce timeout
    setName("");
    setTranscriptText("");
    setTranscribingText("");
    setClinicalNote("");
    setHistoryText("");
    setClinicalNoteSections({});
    setIsClinicalNoteGenerating(false);
    setIsLoading(true);
    setIsClinicalNoteGeneratingCompleted(false);
    setMicOpen(false);
    setNoteFormat("short_sentence");
    setSelectedSection("transcript");
    dialogue.length = 0;
    audioChunks.length = 0;
    setCurrentTimer(0);

    resetLLMResults();
    closeMic();

  };
  const handleNameSave = async (newName) => {
    if (newName.trim()) {
      setName(newName);
      patient.name = newName;
      await updatePatient(patient, { ...getCurrentPatientValues(), name: newName });
      onUpdate(patient, getCurrentPatientValues());
    }
  };
  const getCurrentPatientValues = () => {
    let transcript = (dialogue || []).join("\n");
    if (transcriptText && transcriptText.length > 0) {
      transcript = transcriptText;
    }


    let currentValue = {
      note: JSON.parse(JSON.stringify(summary_prompts_results)),
      transcript: transcript,
      noteFormat: noteFormat,
    };

    return currentValue;
  };
  const handleTranscriptSave = (event) => {

    updatePatient(patient, {
      ...getCurrentPatientValues(),
      transcript: transcriptText,
    }).then(() => {
      setIsInsertTranscript(false);
    });
  };
  const handleTranscriptCancel = (event) => {
    setIsInsertTranscript(false);
  };

  const saveHistoryText = async () => {

    if (historyText.length == 0) {
      return
    }
    if (historyText == patient.history) {
      return
    }

    setIsHistoryLoading(true);
    await saveHistory({
      "ds_id": patient.ds_id || patient.id,
      "medical_history": historyText,
    })

    setIsHistoryLoading(false);

  }


  const handleContentBlur = (event) => {
    // debouncedSave.current.clear(); // Clear the debounce timeout
    // updatePatient(patient, getCurrentPatientValues(),
    //   (savedData) => {
    //     onUpdate(savedData)
    //   }
    // ); //AR: this causes notes to disappear
    // if (onChange)
    //   onChange(getCurrentPatientValues())
  };

  const handleContentChange = (event) => {
    const nextContent = event.target.innerHTML;
    summary_prompts_results[noteFormat] = nextContent;

    debouncedSave.current.call(getCurrentPatientValues()); // Update to use the call method

    if (onChange && nextContent.length > 10)
      onChange(getCurrentPatientValues());
  };

  const handleSectionInput = (section, event) => {
    setClinicalNoteSections({
      ...clinicalNoteSections,
      [section]: event.target.innerText,
    });
  };

  const onClickGenerateNote = async () => {
    setIsValidNote(true);

    if (openMic && isIOS) {
      await awaitCloseMic();
    }


    if (name === "Encounter" || name === "") {
      if (openMic) {
        analyticsService.trackEvent(
          Analytics.Category.RECORDING,
          Analytics.Action.STOPPED,
          "",
          currentTimer,
          Analytics.ScreenName.TRANSCRIPT);
      }

      const patientName = await patientModalRef.current.openModal({
      });
      if (patientName) {
        await handleNameSave(patientName);
      }
    }
    closeMicWithoutSaving();

    setSelectedSection("note");
    analyticsService.trackEvent(
      Analytics.Category.TRANSCRIPT,
      Analytics.Action.CREATED,
      patient.isDictation ? "Dictation" : "Ambient",
      getSavedNoteURL(patient),
      Analytics.ScreenName.TRANSCRIPT);

    handleNoteFormatChange({ target: { value: noteFormat } });

  };



  const handleRegenerateNote = (e, customPrompts) => {
    setIsClinicalNoteGenerating(true);
    setClinicalNote("");
    if (prompts && !customPrompts) {
      customPrompts = prompts
    }

    analyticsService.trackEvent(
      Analytics.Category.NOTE,
      Analytics.Action.REGENERATED,
      "",
      "",
      Analytics.ScreenName.NOTE);

    if (customPrompts) {

      runClinicalSummary(
        customPrompts,
        mapNoteFormatToPrompt(noteFormat),
        {...patient,history:historyText},
        transcriptText,
        (text) => {
          setClinicalNote(text);
        },
        async (text) => {

          summary_prompts_results[noteFormat] = text;
          if (text.length == 0) {
            text = "Unable to generate note from your conversation.";
          }
          setClinicalNote(text);
          setClinicalNoteSections(parseNote(text));

          updatePatient(patient, getCurrentPatientValues()).then(() => {
            setIsClinicalNoteGeneratingCompleted(true);
            setIsClinicalNoteGenerating(false);
            setIsValidNote(isNoteValid(parseNote(text)));
            analyticsService.trackEvent(
              Analytics.Category.NOTE,
              Analytics.Action.SAVED,
              patient.isDictation ? "Dictation" : "Ambient",
              getPromptTitle(customPrompts, noteFormat),
              Analytics.ScreenName.TRANSCRIPT, {
              url_saved: getSavedNoteURL(patient),
              valid_note: isNoteValid(parseNote(text))
            });
          });

          // await runClinicalLLM("Output in HTML block, I'm going to give you a clinical note, topics / sentences might be repeated in different sections. Remove the duplicate topics least relevant to that section. Note:" + text, 
          //   (text) => {
          //     console.log("text", text);
          //     setClinicalNote(text);
          //   },
          //   (text) => {
          //     summary_prompts_results[noteFormat] = text;
          //     if (text.length == 0) {
          //       text = "Unable to generate note from your conversation.";
          //     }
          //     setClinicalNote(text);
          //     setClinicalNoteSections(parseNote(text));

          //     updatePatient(patient, getCurrentPatientValues()).then(() => {
          //       setIsClinicalNoteGeneratingCompleted(true);
          //       setIsClinicalNoteGenerating(false);
          //     });
          //   }
          // );
        }
      );
    }
    else {
      runClinicalSummary(
        prompts,
        mapNoteFormatToPrompt(noteFormat),
        {...patient,history:historyText},
        transcriptText,
        (text) => {
          // setClinicalNote(text);
        },
        async (text) => {
          summary_prompts_results[noteFormat] = text;
          if (text.length == 0) {
            text = "Unable to generate note from your conversation.";
          }
          setClinicalNote(text);
          setClinicalNoteSections(parseNote(text));

          updatePatient(patient, getCurrentPatientValues()).then(() => {
            setIsClinicalNoteGeneratingCompleted(true);
            setIsClinicalNoteGenerating(false);
            setIsValidNote(isNoteValid(parseNote(text)));
            analyticsService.trackEvent(
              Analytics.Category.NOTE,
              Analytics.Action.SAVED,
              patient.isDictation ? "Dictation" : "Ambient",
              getPromptTitle(prompts, noteFormat),
              Analytics.ScreenName.TRANSCRIPT, {
              url_saved: getSavedNoteURL(patient),
              valid_note: isNoteValid(parseNote(text))
            });
          });
          // await runClinicalLLM("Output in HTML block, I'm going to give you a clinical note, topics / sentences might be repeated in different sections. Remove the duplicate topics least relevant to that section. Note:" + text, 
          //   (text) => {
          //     setClinicalNote(text);
          //   },
          //   (text) => {
          //     summary_prompts_results[noteFormat] = text;
          //     if (text.length == 0) {
          //       text = "Unable to generate note from your conversation.";
          //     }
          //     setClinicalNote(text);
          //     setClinicalNoteSections(parseNote(text));

          //     updatePatient(patient, getCurrentPatientValues()).then(() => {
          //       setIsClinicalNoteGeneratingCompleted(true);
          //       setIsClinicalNoteGenerating(false);
          //     });
          //   }
          // );
        }
      );
    }


  };
  React.useImperativeHandle(ref, () => ({
    regenerateNote: handleRegenerateNote,
    toggleMic: toggleMic
  }));

  const handleNoteFormatChange = (event) => {
    setNoteFormat(event.target.value);

    if (event.target.value === "c") {
      return;
    }

    let transcript = transcriptText;
    if (transcriptText.length == 0 && dialogue.length != 0) {
      transcript = (dialogue || []).join("\n");
    }
    else if (transcriptText.length < (dialogue || []).join("\n").length) {
      transcript = (dialogue || []).join("\n");
    }

    if (!summary_prompts_results[event.target.value]) {
      setIsClinicalNoteGenerating(true);
      setClinicalNote("");



      runClinicalSummary(
        prompts,
        mapNoteFormatToPrompt(event.target.value),
        {...patient,history:historyText},
        transcript,
        (text) => {
          setClinicalNote(text);
        },
        (text) => {
          summary_prompts_results[event.target.value] = text;
          if (text.length == 0) {
            text = "Unable to generate note from your conversation.";
          }


          setClinicalNote(text);
          setClinicalNoteSections(parseNote(text));

          updatePatient(patient, getCurrentPatientValues()).then(() => {
            setIsClinicalNoteGeneratingCompleted(true);
            setIsClinicalNoteGenerating(false);
            setIsValidNote(isNoteValid(parseNote(text)));
            analyticsService.trackEvent(
              Analytics.Category.NOTE,
              Analytics.Action.SAVED,
              patient.isDictation ? "Dictation" : "Ambient",
              getPromptTitle(prompts, noteFormat),
              Analytics.ScreenName.TRANSCRIPT, {
              url_saved: getSavedNoteURL(patient),
              valid_note: isNoteValid(parseNote(text))
            });
          });
        }
      );
    } else {
      setClinicalNote(summary_prompts_results[event.target.value]);
      setIsClinicalNoteGeneratingCompleted(true);
      setClinicalNoteSections(
        parseNote(summary_prompts_results[event.target.value])
      );

      updatePatient(patient, {
        ...getCurrentPatientValues(),
        noteFormat: event.target.value,
      }).then(() => {
        setIsValidNote(isNoteValid(parseNote(summary_prompts_results[event.target.value])));

        analyticsService.trackEvent(
          Analytics.Category.NOTE,
          Analytics.Action.SAVED,
          patient.isDictation ? "Dictation" : "Ambient",
          getPromptTitle(prompts, noteFormat),
          Analytics.ScreenName.TRANSCRIPT, {
          url_saved: getSavedNoteURL(patient),
          valid_note: isNoteValid(parseNote(summary_prompts_results[event.target.value]))
        });

      });
    }
  };
  useEffect(() => {
    const currentURL = window.location.href;
    if (currentURL.includes("localhost") || currentURL.includes("preview")) {
      setShowInPreview(true);
    }
  }, []);
  useEffect(() => {
    const currentURL = window.location.href;
    if (currentURL.includes("localhost") || currentURL.includes("preview")) {
      setShowInPreview(true);
    }
  }, []);


  useEffect(() => {
    const initializeKeys = async () => {
      let existingKeyData = await getKeys();

      if (!existingKeyData) {
        let existingKeyData = await getAuth();
        if (existingKeyData && existingKeyData.gpt) {
          await saveKeys(existingKeyData);
          // openMic();

          setIsAlertClosed(true);
          setIsAuthorized(true);
        }
      }

      API_DEEPGRAM_KEY = existingKeyData.audio;
      API_OPENAI_DEPLOYMENT_ID = existingKeyData.model; // 'gpt-35'
      API_OPENAI_KEY = existingKeyData.gpt;
      API_OPENAI_ENDPOINT = existingKeyData.url;
      setIsAlertClosed(true);
      setIsAuthorized(true);
    };

    if (!API_DEEPGRAM_KEY) {
      initializeKeys();
    } else {
      setIsAlertClosed(true);
      setIsAuthorized(true);
    }
  }, []);

  useEffect(() => {
    if (scrollTranscriptRef.current) {
      scrollTranscriptRef.current.scrollTop =
        scrollTranscriptRef.current.scrollHeight;
    }
  }, [transcriptText, transcribingText, micOpen, isClinicalNoteGenerating]);

  // Effect to update local state when the patient prop changes
  useEffect(() => {
    setIsLoading(true);
    clearValues();

    if (patient) {
      getPatient(patient).then(async (patientDetails) => {
        if (patientDetails) {
          setNoteFormat(patientDetails.noteFormat || "short_sentence");
          setName(patientDetails.name);
          setPatientSessionId(patientDetails.ds_id);
          if (patientDetails.transcript) {
            let tmpDialogues = patientDetails.transcript.split("\n");
            tmpDialogues.forEach((d) => {
              dialogue.push(d);
            });
            let hasNote = false;
            setTranscriptText(patientDetails.transcript);
            for (let key in summary_prompts_results) {
              if (patientDetails.note && patientDetails.note[key]) {
                summary_prompts_results[key] = patientDetails.note[key];
                if (patientDetails.note[key].length > 0) {
                  hasNote = true;
                }
              }
            }
            if (hasNote) {
              setClinicalNote(
                summary_prompts_results[
                patientDetails.noteFormat || noteFormat
                ] || ""
              );
              setClinicalNoteSections(
                parseNote(
                  summary_prompts_results[
                  patientDetails.noteFormat || noteFormat
                  ] || ""
                )
              );
              setIsClinicalNoteGeneratingCompleted(true);
              setIsValidNote(isNoteValid(parseNote(
                summary_prompts_results[
                patientDetails.noteFormat || noteFormat
                ] || ""
              )));
              setSelectedSection("note");

              await loadHistory();

              
              //analyticsService.trackScreen(Analytics.Category.ENCOUNTER, Analytics.ScreenName.NOTE);

            }
            else {
              //analyticsService.trackScreen(Analytics.Category.ENCOUNTER, Analytics.ScreenName.TRANSCRIPT);
            }
          }
          else {
            //analyticsService.trackScreen(Analytics.Category.ENCOUNTER, Analytics.ScreenName.TRANSCRIPT);
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
          //analyticsService.trackScreen(Analytics.Category.ENCOUNTER, Analytics.ScreenName.TRANSCRIPT);
        }
      });
    } else {
      setIsLoading(false);
      //analyticsService.trackScreen(Analytics.Category.ENCOUNTER, Analytics.ScreenName.TRANSCRIPT);
    }

    return () => {
      clearValues();
    };
  }, [patient]); // Dependency on patient object

  const [selectedSection, setSelectedSection] = React.useState("transcript");

  const loadHistory = async () => {
    setIsHistoryLoading(true);
    let longHistory = await getHistory(patient.ds_id || patient.id);
    let longHistoryText = longHistory.medical_history || patient.history || "";
    setHistoryText(longHistoryText);

    setIsHistoryLoading(false);
  };
  const handleSectionChange = async (event, newSelection) => {
    // scrollNoteRef.current.innerHTML
    if (newSelection === "note") {
      analyticsService.trackScreen(Analytics.Category.NOTE, Analytics.ScreenName.NOTE);
    }
    else if (newSelection === "transcript") {
      analyticsService.trackScreen(Analytics.Category.TRANSCRIPT, Analytics.ScreenName.TRANSCRIPT);
    }
    else if (newSelection === "custom") {
      analyticsService.trackScreen(Analytics.Category.PROMPT, Analytics.ScreenName.CUSTOM_PROMPT);
    }
    if (newSelection === "customize") {
      return;
    }

    if (newSelection === "history") {

      await loadHistory();



      setTimeout(() => {
        historyRef.current.focus();
      }, 300)
    }

    if (newSelection === "note" && !micOpen) {
      if (
        !isClinicalNoteGenerating &&
        !summary_prompts_results[noteFormat] &&
        (transcriptText || "").length > 0
      ) {

        setIsClinicalNoteGenerating(true);

        analyticsService.trackEvent(
          Analytics.Category.TRANSCRIPT,
          Analytics.Action.CREATED,
          patient.isDictation ? "Dictation" : "Ambient",
          getSavedNoteURL(patient),
          Analytics.ScreenName.TRANSCRIPT);
        runClinicalSummary(
          prompts,
          mapNoteFormatToPrompt(noteFormat),
          {...patient,history:historyText},
          transcriptText,
          (text) => {
            setClinicalNote(text);
          },
          (text) => {
            if (text.length == 0) {
              text = "Unable to generate note from your conversation.";
            }
            summary_prompts_results[noteFormat] = text;
            setClinicalNote(text.replace("<!--", "").replace("-->", ""));
            setClinicalNoteSections(
              parseNote(text.replace("<!--", "").replace("-->", ""))
            );
            setIsClinicalNoteGeneratingCompleted(true);

            updatePatient(patient, getCurrentPatientValues()).then(() => {
              setIsClinicalNoteGeneratingCompleted(true);
              setIsClinicalNoteGenerating(false);
              setIsValidNote(isNoteValid(parseNote(text)));
              analyticsService.trackEvent(
                Analytics.Category.NOTE,
                Analytics.Action.SAVED,
                patient.isDictation ? "Dictation" : "Ambient",
                getPromptTitle(prompts, noteFormat),
                Analytics.ScreenName.TRANSCRIPT, {
                url_saved: getSavedNoteURL(patient),
                valid_note: isNoteValid(parseNote(text))
              });

            });
          }
        );

        //
      } else {
        //
        setClinicalNote(summary_prompts_results[noteFormat]);
        setClinicalNoteSections(parseNote(summary_prompts_results[noteFormat]));
        setIsClinicalNoteGeneratingCompleted(true);
      }
    }

    setSelectedSection(newSelection);
  };

  async function checkMicrophoneAccess() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop());
      setHasMicrophoneAccess(true);

      navigator.permissions
        .query({ name: "microphone" })
        .then(function (permissionStatus) {
          console.log(permissionStatus.state); // granted, denied, prompt

          permissionStatus.onchange = function () {
            console.log("Permission changed to " + this.state);

            if (this.state === "denied") {
              analyticsService.trackEvent(
                Analytics.Category.ERROR,
                Analytics.Action.STARTED,
                Analytics.Category.RECORDING,
                "Error accessing the microphone denied",
                Analytics.ScreenName.TRANSCRIPT);
            }
          };
        });
    } catch (error) {
      logError("Error accessing the microphone:", error);
      analyticsService.trackEvent(
        Analytics.Category.ERROR,
        Analytics.Action.STARTED,
        Analytics.Category.RECORDING,
        "Error accessing the microphone",
        Analytics.ScreenName.TRANSCRIPT);
      setHasMicrophoneAccess(false);
    }
  }

  function onClickCopyAll() {
    navigator.clipboard
      .writeText(getTextFromSections(parseNote(summary_prompts_results[noteFormat])))
      .then(
        function () {
          setCopied(true);
          analyticsService.trackEvent(
            Analytics.Category.NOTE,
            Analytics.Action.COPIED,
            "all",
            "",
            Analytics.ScreenName.NOTE);

          setTimeout(() => {
            setCopied(false);
          }, 500);
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
  }

  function openMic(errorAccess) {
    if (micOpen) {
      return;
    }

    clearInterval(timerRef.current);
    setCurrentTimer(0);
    timerRef.current = setInterval(() => {
      setCurrentTimer((prev) => prev + 1);
    }, 1000);


    setMicOpening(true);

    let handleStream = () => {
      // Call open microphone
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {

          setAudioStream(stream);
          const options = { mimeType: "audio/webm; codecs=opus" };
          if (!MediaRecorder.isTypeSupported(options.mimeType)) {
            const types = [
              "audio/webm;codecs=opus",
              "audio/webm;codecs=vorbis",
              "audio/webm",
              "audio/ogg",
              "audio/ogg;codecs=opus",
              "audio/ogg;codecs=vorbis",
              "audio/wav",
              "audio/mp4;codecs=mp4a",
              "audio/mp4;codecs=avc1",
              "audio/mp4",
            ];
            for (let i = 0; i < types.length; i++) {
              if (MediaRecorder.isTypeSupported(types[i])) {
                options.mimeType = types[i];
                mimeType = types[i];
                break;
              }
            }
          }

          globalMicrophone = new MediaRecorder(stream, options);
          globalStream = stream; // Store the stream globally
          globalMicrophone.start(500);
          globalMicrophone.onstart = () => {
            clearTimeout(pulsatingTimer);

            if (isIOS) {
              setMicOpen(true);
              setMicOpening(false);
              isListening = true;
            } else {
              setMicOpening(false);
            }
          };
          globalMicrophone.onstop = () => {
            setMicOpen(false);
          };
          globalMicrophone.ondataavailable = (e) => {
            audioChunks.push(e.data);
            if (connection && connection.readyState === WebSocket.OPEN) {
              if (connection) {
                connection.send(e.data);
              }
            }
          };

          //console.log("Microphone access granted");
        })
        .catch((error) => {
          logError("Error accessing the microphone:", error);

          analyticsService.trackEvent(
            Analytics.Category.ERROR,
            Analytics.Action.STARTED,
            Analytics.Category.RECORDING,
            "Error accessing the microphone denied",
            Analytics.ScreenName.TRANSCRIPT);

          setMicOpen(false);
          setMicOpening(false);
          isListening = false;

          if (errorAccess) {
            errorAccess();
          }
        });
    };

    if (!isListening && !isIOS) {
      connectToDeepgram(
        API_DEEPGRAM_KEY,
        patient["language"],
        false,
        (ws) => {
          connection = ws;
          setMicOpen(true);
          setMicOpening(false);
          isListening = true;
          setConnectionStatus("Deepgram connection started");
          handleStream();
        },
        () => {
          isListening = false;
          setMicOpen(false);
          setMicOpening(false);
          connection = undefined;
          setConnectionStatus("Deepgram connection closed");
          if (patient.name == "Encounter" || patient.name == "") {
            closeMicWithoutSaving();
          }
          else {
            closeMic();
          }
        },
        (text) => {
          setConnectionStatus("Deepgram Transcript In Progress");
          if (text.length > 1) {
            setTranscribingText(text);
          }
        },
        (text) => {
          setConnectionStatus("Deepgram Transcript Complete");
          if (text.length > 0) {
            dialogue.push(text);
          }
          setTranscribingText("");
          setTranscriptText(dialogue.join("\n"));
        }
      );
    } else if (isIOS) {
      setTimeout(handleStream, 100);
    }
  }

  function closeMicWithoutSaving() {
    //console.log("Closing mic");

    if (connection) connection.finish();

    deepgram = undefined;
    setMicOpen(false);
    setAudioStream(undefined);

    if (connection) connection.finish();

    if (globalMicrophone && globalMicrophone.state !== "inactive") {
      globalMicrophone.stop(); // Stop the media recorder
    }

    if (globalStream) {
      globalStream.getTracks().forEach((track) => track.stop()); // Stop each track on the stream
      //console.log("Recording stopped");
      globalStream = null; // Clear the stored stream
    } else {
      //console.log("No recording to stop");
    }
  }

  async function awaitCloseMic() {
    closeMicWithoutSaving();

    if (audioChunks.length > 0) {
      if (!isIOS) {
        audioChunks.length = 0;
        await updatePatient(patient, {
          ...getCurrentPatientValues(),
          transcript: dialogue.join("\n"),
        });

        analyticsService.trackEvent(
          Analytics.Category.TRANSCRIPT,
          Analytics.Action.UPLOADED,
          "desktop",
          getSavedNoteURL(patient),
          Analytics.ScreenName.TRANSCRIPT
        );

      } else {
        const audioBlob = new Blob(audioChunks, { type: mimeType });
        setUploadingAudio(true);
        try {
          const data = await uploadAudio(mimeType, API_DEEPGRAM_KEY, audioBlob);
          audioChunks.length = 0;
          console.log("Audio uploaded");
          dialogue.push(data.results.channels[0].alternatives[0].transcript);

          setTranscribingText("");
          setTranscriptText(dialogue.join("\n"));
          setUploadingAudio(false);
          await updatePatient(patient, {
            ...getCurrentPatientValues(),
            transcript: dialogue.join("\n"),
          });

          analyticsService.trackEvent(
            Analytics.Category.TRANSCRIPT,
            Analytics.Action.UPLOADED,
            "Mobile",
            getSavedNoteURL(patient),
            Analytics.ScreenName.TRANSCRIPT
          );


          // analyticsService.trackEvent(
          //   Analytics.Category.NOTE,
          //   Analytics.Action.SAVED,
          //   patient.isDictation ? "Dictation" : "Ambient",
          //   getPromptTitle(prompts, noteFormat),
          //   Analytics.ScreenName.TRANSCRIPT, {
          //   url_saved: getSavedNoteURL(patient),
          //   valid_note: isNoteValid(parseNote(text))
          // });

        } catch (error) {
          logError("Error during upload:", error);

          analyticsService.trackEvent(
            Analytics.Category.ERROR,
            Analytics.Action.STARTED,
            Analytics.Category.RECORDING,
            "Error Audio upload failed",
            Analytics.ScreenName.TRANSCRIPT
          );
        }
      }
    } else {
      if (dialogue.length > 0) {
        await updatePatient(patient, {
          ...getCurrentPatientValues(),
          transcript: dialogue.join("\n"),
        });

        analyticsService.trackEvent(
          Analytics.Category.TRANSCRIPT,
          Analytics.Action.UPLOADED,
          "desktop",
          getSavedNoteURL(patient),
          Analytics.ScreenName.TRANSCRIPT
        );


      }
    }
  }
  function closeMic() {
    closeMicWithoutSaving()

    if (audioChunks.length > 0) {
      if (!isIOS) {
        audioChunks.length = 0;
        updatePatient(patient, {
          ...getCurrentPatientValues(),
          transcript: dialogue.join("\n"),
        }).then(() => { });
      } else {
        const audioBlob = new Blob(audioChunks, { type: mimeType });
        setUploadingAudio(true);
        uploadAudio(mimeType, API_DEEPGRAM_KEY, audioBlob)
          .then((data) => {
            audioChunks.length = 0; // Assuming audioChunks is accessible in this scope
            console.log("Audio uploaded");
            dialogue.push(data.results.channels[0].alternatives[0].transcript);

            setTranscribingText("");
            setTranscriptText(dialogue.join("\n"));
            setUploadingAudio(false);
            updatePatient(patient, {
              ...getCurrentPatientValues(),
              transcript: dialogue.join("\n"),
            }).then(() => { });
          })
          .catch((error) => {
            logError("Error during upload:", error);

            analyticsService.trackEvent(
              Analytics.Category.ERROR,
              Analytics.Action.STARTED,
              Analytics.Category.RECORDING,
              "Error Audio upload failed",
              Analytics.ScreenName.TRANSCRIPT);
          });
      }
    } else {
      if (dialogue.length > 0) {
        updatePatient(patient, {
          ...getCurrentPatientValues(),
          transcript: dialogue.join("\n"),
        }).then(() => { });
      }
    }
  }

  async function toggleMic(source) {
    if (micOpen) {
      clearInterval(timerRef.current);
      analyticsService.trackEvent(
        Analytics.Category.RECORDING,
        Analytics.Action.PAUSED,
        "",
        currentTimer,
        source);

      if (name === "Encounter" || name === "") {
        closeMicWithoutSaving();
        const patientName = await patientModalRef.current.openModal({
        });
        if (patientName) {
          await handleNameSave(patientName);
        }
      }


      onDone();
    } else {
      if (dialogue.length > 0 || audioChunks.length > 0) {
        analyticsService.trackEvent(
          Analytics.Category.RECORDING,
          Analytics.Action.RESUMED,
          "",
          currentTimer,
          source);
      }
      else {
        analyticsService.trackEvent(
          Analytics.Category.RECORDING,
          Analytics.Action.STARTED,
          "",
          currentTimer,
          source);
      }


      openMic(function () {

        setHasMicrophoneAccess(false);
      });
    }
  }

  function onDone() {
    closeMic();
  }

  if (!isAuthorized || isLoading) {
    return (
      <>
        <center>Loading</center>
        <br />
        <br />
        <div className="hn-ext-transcript-block-summary-progress">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </>
    ); // or a loading spinner, or some placeholder content
  }
  return (
    <>

      <div
        data-theme="light"
        className={`border-l block relative border-gray-100 container mx-auto ${isMobile ? "max-w-full" : "max-w-full"
          } pt-14 pb-2 text-lg h-screen`}
      >
        {/* {!isAlertClosed && (
          <div className="alert alert-warning">
            <div className="flex-1">
              <strong>Warning:</strong> This information is only stored locally for this browser. It will be lost if you clear your browser data.
            </div>
            <button className="btn btn-xs btn-circle fixed top-3 right-5" onClick={(e) => { setAlertClosed(true); setIsAlertClosed(true); }}>X</button>

          </div>
        )} */}
        <div className={`${!isMobile && selectedSection == "transcript" ? "" : "hidden"} absolute z-10 block top-28 -left-1`} onClick={(e) => { setIsInsertTranscript(!isInsertTranscript); }}>
          {(<span
            className="btn btn-xs btn-circle bg-white opacity-15 btn-insert-transcript"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15M9 12l3 3m0 0 3-3m-3 3V2.25"
              />
            </svg>
          </span>)}
        </div>
        <div className={`tabs-lg tabs-bordered ${isMobile ? "ml-6" : ""}`}>
          {(!(isMobile)) && (
            <a
              className={`tab ${selectedSection === "history" ? "tab-active" : ""
                }`}
              onClick={(e) => handleSectionChange(e, "history")}
            >

              Context
            </a>
          )}
          {(!(isMobile && isIOS)) && (
            <a
              className={`tab ${selectedSection === "transcript" ? "tab-active" : ""
                }`}
              onClick={(e) => handleSectionChange(e, "transcript")}
            >

              Transcript<span className="text-sm pl-2 text-gray-500">{(patient["language"] || "")}</span>
            </a>
          )}
          {!micOpen && Object.keys(clinicalNoteSections || {}).length > 0 && (
            <a
              className={`tab ${selectedSection === "note" ? "tab-active" : ""
                }`}
              onClick={(e) => handleSectionChange(e, "note")}
            >
              Note
            </a>
          )}
          {true && !isMobile && isAlphaFlag() && (
            <a
              className={`tab ${selectedSection === "custom" ? "tab-active" : ""
                }`}
              onClick={(e) => handleSectionChange(e, "custom")}
            >
              Advanced
            </a>
          )}
        </div>
        {patient.isDictation && (<span className="m-4 p-1 text-xs bold border-spacing-1 bg-yellow-400">Dictation Mode</span>)}
        {/* {selectedSection !== 'custom' && (
                  <div className={`flex m-4 justify-start p-${isMobile ? "0 0 0 10" : "0"}`}>
                  <h3>{patient?.date} {name}</h3>
                </div>
          )} */}

        {selectedSection === "history" && (
          <div className="card bg-base-100">
            <div className="card-body p-0 overflow-auto h-[calc(100vh-110px)]">

              {isHistoryLoading && (
                <>
                  <center>Loading</center>
                  <br />
                  <br />
                  <div className="hn-ext-transcript-block-summary-progress">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </>
              )}
              {!isHistoryLoading && (
                <textarea
                  ref={historyRef}
                  type="text"
                  onBlur={(e) => {
                    saveHistoryText();
                  }}
                  value={historyText}
                  onChange={(e) => setHistoryText(e.target.value)}
                  className="input border-none p-2 h-[calc(100vh-110px)] w-full"
                />)}

            </div>
          </div>)}

        {selectedSection === "transcript" && (
          <div className="card ml-4 bg-base-100">
            <div className="card-body p-0 overflow-auto max-h-[calc(100vh-115px)]">
              <h3 className="card-title">

              </h3>

              {(((transcribingText || "").length <= MAX_CHARACTERS_TRANSCRIPTION &&
                (transcriptText || "").length <= MAX_CHARACTERS_TRANSCRIPTION &&
                micOpen && isIOS &&
                totalNumberOfPatients <= 2) || (!isValidNote && micOpen)) && (
                  <div className="text-md border-solid border-2 bg-gray-100 p-2">
                    <div className="text-xl p-1 font-bold">👇 <span className="bg-amber-200 p-1">Read below out loud:</span>👇

                    </div>
                    <p><b>You:</b> Hi, what brings you in today?</p>
                    <p><b>Patient:</b> <br /> I've had a runny nose for 3 days.</p>
                    <p><b>You:</b> Are you taking medications?</p>
                    <p><b>Patient:</b><br /> Asprin daily for high blood pressure.</p>
                    <p><b>You:</b> Ok. Let's take a quick look at you. You have a fever but your chest is clear. Seems like the flu. Get some rest, keep hydrated.</p>


                  </div>
                )}

              {isIOS && !micOpen && transcriptText.length < MAX_CHARACTERS_TRANSCRIPTION && transcriptText.length > 0 && !isClinicalNoteGeneratingCompleted && (
                <div className="bg-yellow-100 m-2 p-4 border-2 border-solid rounded-sm">
                  Your conversation is too short to generate a useful medical note. Health Note works best if you mimic a real conversation with a patient. <br /><br />Please tap <b>Resume</b> or <b>Continue</b> and add more to the conversation.
                </div>
              )}

              {isIOS && !micOpen && transcriptText.length < MAX_CHARACTERS_TRANSCRIPTION && transcriptText.length > 0 && isClinicalNoteGeneratingCompleted && (
                <div className="bg-yellow-100 m-2 p-4 border-2 border-solid rounded-sm">
                  Your conversation is too short to generate a useful medical note. Health Note works best if you mimic a real conversation with a patient.
                </div>
              )}

              {(transcribingText || "").length == 0 &&
                (transcriptText || "").length == 0 &&
                !micOpen &&
                !uploadingAudio &&
                !micOpening && <p className="text-2xl self-center">Click <a className="font-bold" onClick={(e) => { toggleMic(Analytics.ScreenName.TRANSCRIPT) }}>"Continue"</a> below to continue</p>}
              {(transcribingText || "").length == 0 &&
                (transcriptText || "").length == 0 &&
                micOpen &&
                isIOS && <p></p>}
              {!isIOS && <p className="p-2">{transcriptText}</p>}
              {!isIOS && transcribingText && <p>{transcribingText}</p>}
              <div className="flex justify-end pb-[350px]"></div>
            </div>
          </div>
        )}

        {selectedSection === "note" && (
          <div className="card m-4 bg-base-100">
            <div className="card-body p-0">

              <button
                className={`btn btn-sm p-1 pr-3 btn-outline btn-note-settings absolute bg-white  ${isMobile ? ' -top-14 right-[0px]' : '-top-10 right-[160px]'}`}
                onClick={(e) => {
                  if (onNoteSettings) {
                    analyticsService.trackEvent(
                      Analytics.Category.TEMPLATE,
                      Analytics.Action.VIEWED,
                      "",
                      "",
                      Analytics.ScreenName.NOTE, {
                      "template_key": getPromptTitle(prompts, noteFormat)
                    });

                    onNoteSettings();
                  }

                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 13.5V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 9.75V10.5"
                  />
                </svg>
                Improve Note
              </button>


              {(transcriptText || "").length > 12 && isValidNote && isClinicalNoteGeneratingCompleted && (
                <button
                  className={`btn btn-sm btn-accent p-1 w-36 absolute ${isMobile ? 'bottom-1 right-1' : '-top-10 right-1'} `}
                  onClick={onClickCopyAll}
                >
                  {copied ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4 mr-1"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                      Copied
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4 mr-1"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184"
                        />
                      </svg>
                      Copy Note
                    </>
                  )}
                </button>
              )}

              {!isValidNote && (
                <div className="bg-yellow-100 m-2 p-2 border-2 border-solid rounded-sm">
                  <strong>Your conversation is too short to generate a useful medical note. Health Note works best if you mimic a real conversation with a patient. Here's a sample transcript to read:</strong>

                  <div className="p-1">
                    <p><b>You:</b> Hi, what brings you in today?</p>
                    <p><b>Patient:</b> <br /> I've had a runny nose for 3 days.</p>
                    <p><b>You:</b> Are you taking medications?</p>
                    <p><b>Patient:</b><br /> Asprin daily for high blood pressure.</p>
                    <p><b>You:</b> Ok. Let's take a quick look at you. You have a fever but your chest is clear. Seems like the flu. Get some rest, keep hydrated.</p>



                    <button className="btn mt-5 btn-warning" onClick={(e) => { tryAgain() }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z"
                        />
                      </svg>
                      Try Again</button>
                  </div>

                </div>
              )}
              {!isClinicalNoteGenerating && isValidNote && (
                <EditableNoteContent
                  isMobile={isMobile}
                  totalNumberOfPatients={totalNumberOfPatients}
                  key={`note-session-${patient["ds_id"] || "unknown"}`}
                  noteSessionId={`note-session-${patient["ds_id"] || "unknown"}`}
                  data={clinicalNoteSections}
                  onChange={(sectionText) => {
                    setClinicalNote(joinNoteSections(sectionText));
                    handleContentChange({
                      target: { innerHTML: joinNoteSections(sectionText) },
                    });
                  }}
                />
              )}
              {isClinicalNoteGenerating && (
                <div
                  onInput={handleContentChange}
                  onBlur={handleContentBlur}
                  ref={scrollNoteRef}
                  className="whitespace-normal block txt-input-soap-note editable overflow-auto p-2 -mt-4 max-h-[calc(100vh-115px)]"
                  style={{ minHeight: "150px" }}
                  dangerouslySetInnerHTML={{ __html: clinicalNote }}
                />
              )}

              {isClinicalNoteGenerating ? (
                <div className="hn-ext-transcript-block-summary-progress">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                ""
              )}

              {/* 
              {Object.keys(clinicalNoteSections).map((section) => (
                <div key={section}>
                  <h3>{section}</h3>
                  <div
                    contentEditable
                    onInput={(event) => handleSectionInput(section, event)}
                    dangerouslySetInnerHTML={{ __html: clinicalNoteSections[section] }}
                    className="whitespace-pre-wrap border border-gray-300 p-2 min-h-[100px] mb-5"
                  ></div>
                </div>
              ))} */}
              {!isClinicalNoteGenerating && !isMobile && (
                <div
                  onClick={handleRegenerateNote}
                  className="fixed bottom-3 opacity-60 left-1/2 btn btn-xs btn-regenerate "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                  Regenerate
                </div>
              )}
            </div>
          </div>
        )}

        {clinicalNote.length == 0 &&
          !isClinicalNoteGenerating &&
          selectedSection !== "custom" && selectedSection !== "history" ? (
          <div className="block">
            {micOpening || uploadingAudio ? (
              <div
                className={`flex gap-4 justify-center fixed items-center  ${isMobile ? "w-full bottom-[100px]" : "w-less-200 bottom-[250px]"
                  }`}
              >
                <div className="hn-ext-transcript-block-summary-progress">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : (
              <>
                <div
                  className={`flex gap-4 justify-center fixed items-center ${isMobile ? "w-full  bottom-[170px]" : "w-less-200  bottom-[300px]"
                    }`}
                >
                  {audioStream ? (
                    <AudioAnalyser audio={audioStream} />
                  ) : (
                    <div style={{ height: "80px" }}>-</div>
                  )}
                </div>
                <div
                  className={`${isMobile
                    ? "flex gap-4 justify-center fixed items-center text-center bottom-[100px] w-full"
                    : "flex gap-4 justify-center fixed items-center text-center bottom-[210px] w-less-200"
                    }`}
                >
                  {
                    micOpen
                      ? <div>
                        <div className={"font-extrabold text-2xl pb-2  bg-white "}>Listening</div>
                        <div className={" bg-white "}>Speak for at least 15 seconds.</div>
                        {currentTimer < 15 && (<div>{currentTimer}s.</div>)}
                        {!isIOS && (
                          <div className="">{(currentTimer >= 15) ? "Tap End & Generate Note to see your note" : ""} </div>
                        )}
                        {isIOS && (
                          <div className="">{(currentTimer >= 15) ? "Tap End & Generate Note to see your note" : ""} </div>
                        )}
                      </div>
                      : (transcriptText || "").length > 0 && transcriptText.length > 12
                        ? <div>
                          <div className={"font-extrabold text-2xl pb-2 bg-white "}>Paused</div>
                          <div className={" bg-white"}>Click <b>"End & Generate Note"</b> to finish and see your note.</div>
                        </div>
                        : ""
                  }
                </div>
                <div
                  className={`${isMobile
                    ? "flex gap-4 justify-center fixed items-center bottom-[30px] w-full"
                    : "flex gap-4 justify-center fixed items-center bottom-[150px] w-less-200"
                    }`}
                >

                  <button
                    className={`btn ${micOpen
                      ? "btn-error btn-pause-recording"
                      : "btn-warning btn-start-recording"
                      } btn`}
                    onClick={(e) => { toggleMic(Analytics.ScreenName.TRANSCRIPT) }}
                  >
                    {!micOpen ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z"
                        />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
                      </svg>

                    )}

                    {micOpen
                      ? "Pause"
                      : (transcriptText || "").length > 0
                        ? "Resume"
                        : "Continue"}
                  </button>
                  {((transcriptText || "").length > MAX_CHARACTERS_TRANSCRIPTION || currentTimer > MAX_TIMER_COUNT) && !isIOS && (
                    <button
                      className={`btn btn-accent btn-generate-encounter-note`}
                      onClick={onClickGenerateNote}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                        />
                      </svg>
                      End & Generate Note
                    </button>
                  )}

                  {(isIOS && ((currentTimer > MAX_TIMER_COUNT) || ((transcriptText || "").length > 0 && transcriptText.length > MAX_CHARACTERS_TRANSCRIPTION))) && (
                    <button
                      className={`btn btn-accent btn-generate-encounter-note`}
                      onClick={onClickGenerateNote}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                        />
                      </svg>
                      End & Generate Note
                    </button>
                  )}

                </div>
              </>
            )}
          </div>
        ) : (
          <div></div>
        )}

        {selectedSection === "custom" && (
          <div className="card bg-base-100">
            <PromptManagement
              transcriptText={transcriptText}
              patient={patient}
              patientSessionId={patientSessionId}
              activeNote={clinicalNote}
              prompts={prompts}
              handlePromptChange={(prompt) => {
                handlePromptChange(prompt);
              }}
            />
          </div>
        )}
      </div>
      {!hasMicrophoneAccess && (
        <>
          <input
            type="checkbox"
            id="help-modal"
            className="modal-toggle"
            checked
            readOnly
          />
          <div className="modal">
            <div className="modal-box">
              <h2 className="text-xl font-bold">
                Microphone Access Unavailable
              </h2>
              <p>
                Please enabling microphone access in your browser setting to continue.
                {/* <br/><br/>
                The following domains may need to be white-listed (both on the network and any local firewalls)
                <br/>
                api.deepgram.com/* <br/>
                *.openai.azure.com/* */}
              </p>
              <div className="modal-action">
                <label
                  htmlFor="help-modal"
                  onClick={(e) => {
                    setHasMicrophoneAccess(true);
                  }}
                  className="btn"
                >
                  OK
                </label>
              </div>
            </div>
          </div>
        </>
      )}

      {isInsertTranscript && (
        <div className="modal modal-open">
          <div className="modal-box ">
            <h3 className="font-bold text-lg">Enter Transcript</h3>
            <div className="form-control mt-4">
              <textarea
                type="text"
                value={transcriptText}
                onChange={(e) => setTranscriptText(e.target.value)}
                className="input input-bordered  h-[400px]"
              />
            </div>
            <div className="modal-action mt-4 flex space-x-2">
              <button className="btn btn-accent" onClick={handleTranscriptSave}>
                Save
              </button>
              <button className="btn" onClick={handleTranscriptCancel}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <AsyncPatientNameModal ref={patientModalRef} />
    </>
  );
});

export default PatientNote;
