import React, { useState, useEffect, useRef } from 'react';
import {
  useTheme,
  useMediaQuery,
} from "@mui/material";

import {
  Analytics,
  StandardAnalyticsService,
} from "./analytics"; // Adjust this import path as necessary

import {
  promiseRunTextLLM,
  createPromptFromTemplate,
  saveGlobalPrompt,
  generatePromptFromText,
  extractH3Titles,
  isHealthNoteUser,
} from "./db";

const templateSections = {
  SOAP: ["Subjective", "Objective", "Assessment", "Plan"],
  "DAP": ["Data", "Assessment", "Plan"],  
  "H&P": [
    "Chief Complaint", "History of Present Illness", "Past Medical History",
    "Family History", "Social History", "Review of Systems", "Physical Examination",
    "Assessment", "Plan"
  ]
};

const isSystemPrompts = function(title){

  if(Object.keys(templateSections).includes(title)){
    return true;
  }
  return false;

}

const toggleableSections = ["Subjective", "Objective"];

const DocumentTemplateComponent = ({isOpen, onUse, onCancel }) => {
  const analyticsService = StandardAnalyticsService.shared;

  const [documentName, setDocumentName] = useState('');
  const [sections, setSections] = useState([]);
  const [selectedSections, setSelectedSections] = useState([]);
  const [sectionFormats, setSectionFormats] = useState({});
  const [isPersonalizing, setIsPersonalizing] = useState(false);
  const [showSections, setShowSections] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const promptTemplatesRef = useRef({});
  const [validationPrompt, setValidationPrompt] = useState("Is '[text]' a clinical document. Return just YES or NO.");

  useEffect(() => {
    if (isOpen) {

    }
  }, [isOpen]);

  useEffect(() => {
    //handleGenerateSections('SOAP');
  }, []);



  const handleGenerateSections = async (template = null) => {
    setIsLoading(true);

    let newSections;

    if (documentName && documentName.trim().length > 1 && !( isSystemPrompts(template))) {

      analyticsService.trackEvent(Analytics.Category.TEMPLATE_WIZARD_JOSH, Analytics.Action.EXECUTED, "", template || documentName, Analytics.ScreenName.TEMPLATE_WIZARD_JOSH);



      // let llmCheck = await promiseRunTextLLM(validationPrompt.replace("[text]",documentName));
      // console.log("LLM Check:",llmCheck);

      let results = "YES"
      if(results.indexOf("YES") == -1){


        setSections([]);
        setSelectedSections([]);
        setSectionFormats({});
        setIsPersonalizing(false);
        setShowSections(true);
        setIsLoading(false);
        return;
      }

      let prompt = await generatePromptFromText(documentName.toUpperCase());
      if (prompt) {

        promptTemplatesRef.current["prompt"] = prompt;
        newSections = extractH3Titles(prompt);
      }

    }
    else if (isSystemPrompts(template)) {
      analyticsService.trackEvent(Analytics.Category.TEMPLATE_WIZARD_JOSH, Analytics.Action.PICKED, "", template, Analytics.ScreenName.TEMPLATE_WIZARD_JOSH);

      let prompt = createPromptFromTemplate(template);
      if(prompt){
        promptTemplatesRef.current["prompt"] = prompt;
        newSections = extractH3Titles(prompt);
      }
    }
    else if (isSystemPrompts(documentName)) {
      analyticsService.trackEvent(Analytics.Category.TEMPLATE_WIZARD_JOSH, Analytics.Action.PICKED, "", template, Analytics.ScreenName.TEMPLATE_WIZARD_JOSH);

      let prompt = createPromptFromTemplate(documentName);
      if(prompt){
        promptTemplatesRef.current["prompt"] = prompt;
        newSections = extractH3Titles(prompt);
      }
    }

    if (template && templateSections[template]) {
      newSections = templateSections[template];
      setDocumentName('');
    } else {
      // const allSections = Object.values(templateSections).flat();
      // newSections = allSections.sort(() => 0.5 - Math.random()).slice(0, 8);
    }
    setSections(newSections);
    setSelectedSections(newSections);
    const newSectionFormats = newSections.reduce((acc, section) => {
      if (toggleableSections.includes(section)) {
        acc[section] = 'bullet'; // Default to bullet for toggleable sections
      }
      return acc;
    }, {});
    setSectionFormats(newSectionFormats);
    setIsPersonalizing(false);
    setShowSections(true);
    setIsLoading(false);

  };

  const handleDocumentNameChange = (e) => {
    const newName = e.target.value;
    setDocumentName(newName);
    setShowSections(newName === '');
  };

  const handleChangePrompt = (e) => {
    setValidationPrompt(e.target.value);
  }

  const handleFormatToggle = (section) => {
    setSectionFormats(prev => ({
      ...prev,
      [section]: prev[section] === 'bullet' ? 'sentence' : 'bullet'
    }));
  };

  const handleSectionToggle = (section) => {
    setSelectedSections(prev =>
      prev.includes(section)
        ? prev.filter(s => s !== section)
        : [...prev, section]
    );
  };

  const handleUse = async () => {
    console.log("Using sections:", selectedSections);
    console.log("Section formats:", sectionFormats);
    // Call the parent callback with the selected sections and formats
    let savedPromptObject = {}
    let templateName = documentName;
    if(documentName.length === 0){ 
      templateName = "SOAP";
    }
    analyticsService.trackEvent(Analytics.Category.TEMPLATE_WIZARD_JOSH, Analytics.Action.FINISHED, "", templateName, Analytics.ScreenName.TEMPLATE_WIZARD_JOSH);


    if(isSystemPrompts(templateName)){
      let promptObj = {...promptTemplatesRef.current,"type":"DEFAULT_NOTE", "isAdvanced": false, "version":"0917"  };
      promptObj.title = templateName;
      await saveGlobalPrompt(templateName,promptObj);

    }
    else{
      let description = sections.join(", ");
      await saveGlobalPrompt(templateName, {...promptTemplatesRef.current,"type":"DEFAULT_NOTE", "isAdvanced": true,"version":"0917", "description": description});
    }



    onUse({
      savedPromptObject
    });
  };

  const handleCancel = () => {
    setSections([]);
    setSelectedSections([]);
    setDocumentName('');
    setSectionFormats({});
    setIsPersonalizing(false);
    setShowSections(true);
    // Call the parent callback
    onCancel();
    analyticsService.trackEvent(Analytics.Category.TEMPLATE_WIZARD_JOSH, Analytics.Action.CANCELLED, "", "", Analytics.ScreenName.TEMPLATE_WIZARD_JOSH);

  };

  const togglePersonalize = () => {
    setIsPersonalizing(!isPersonalizing);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleGenerateSections();
    }
  }

  return (
    <div className="modal modal-open">
      <div className={`modal-box p-2  flex flex-col ${!isMobile ? "ml-52 max-w-[600px]" : "w-full max-h-full"}`}>
        {/* {isHealthNoteUser() &&(
          <textarea className="w-full h-20 p-2" placeholder="Enter a document / template name" value={validationPrompt} onChange={handleChangePrompt}></textarea>
        )} */}
        <div className={`${isMobile ? "p-2 scroll-pt-10" : "p-4"} overflow-y-auto flex-grow1`}>
          <div className="space-y-4 mb-6">
          <button onClick={handleCancel} className="btn btn-sm btn-circle absolute right-2 top-2">✕</button>
            <h3 className="">What type of document/note would you like?</h3>

            <div className="form-control">

              <input
                type="text"
                placeholder="Enter a document / template name"
                className="input input-bordered w-full"
                value={documentName}
                onChange={handleDocumentNameChange}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="text-lg text-base-content/70">
              For example: {' '}
              <div className='pl-2'>
              {Object.keys(templateSections).map((template, index) => (
                <React.Fragment key={template}>

                  <a
                    href="#"
                    className="link link-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      handleGenerateSections(template);
                      setDocumentName(template);
                    }}
                  >
                    {template}
                  </a>


                  {index < Object.keys(templateSections).length - 1 && ', '}
                </React.Fragment>
              ))}
              <div>SOAP with Assessment and Plan together </div>
              </div>
            </div>
            <button
              className="btn btn-accent"
              onClick={() => handleGenerateSections()}
              disabled={isLoading || documentName.length == 0}
            >
              {isLoading ? (
                <>
                  <span className="loading loading-spinner"></span>
                  Generating...
                </>
              ) : (
                documentName.length > 0 ? "Retrieve Template" : "Retrieve Template"
              )}
            </button>
          </div>

          {showSections && sections.length > 0 && (
            <div className="mt-6 border rounded-lg p-4 bg-base-100">
              <h3 className="text-lg font-semibold mb-4">{documentName || "Document Preview"}</h3>
              <div className="space-y-6">
                {sections.map((section) => (
                  (isPersonalizing || selectedSections.includes(section)) && (
                    <div key={section} className="space-y-2">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          {isPersonalizing && (
                            <input
                              type="checkbox"
                              className="checkbox checkbox-sm"
                              checked={selectedSections.includes(section)}
                              onChange={() => handleSectionToggle(section)}
                            />
                          )}
                          <h4 className="text-md font-medium">{section}</h4>
                        </div>
                        {isPersonalizing && toggleableSections.includes(section) && (
                          <div className="flex items-center space-x-2">
                            <span className="text-xs mr-2">
                              {sectionFormats[section] === 'bullet' ? 'Bullet' : 'Sentence'}
                            </span>
                            <input
                              type="checkbox"
                              className="toggle toggle-primary toggle-sm"
                              checked={sectionFormats[section] === 'sentence'}
                              onChange={() => handleFormatToggle(section)}
                            />
                          </div>
                        )}
                      </div>
                      <div className="space-y-1">
                        <div className="h-0.5 bg-base-content/20 w-full"></div>
                        <div className="h-0.5 bg-base-content/20 w-full"></div>
                        <div className="h-0.5 bg-base-content/20 w-full"></div>
                      </div>
                    </div>
                  )
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="p-6 border-t">
          <div className="flex justify-end space-x-2">
            {!isPersonalizing && !isLoading && Object.keys(sections).length > 0 &&  (
              <button onClick={handleUse} className="btn btn-accent btn-sm">Use Template</button>
            )}
            {/* <button onClick={togglePersonalize} className="btn btn-outline btn-sm">
              {isPersonalizing ? "Finish Personalizing" : "Personalize"}
            </button> */}
            <button onClick={handleCancel} className="btn btn-outline btn-sm">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentTemplateComponent;